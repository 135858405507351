export const HEADING_CONTENT_TYPE = {
  auto_summary: {
    heading: 'Techcard_Autosummary_DetailView_Title',
    discription: 'Techcard_Autosummary_DetailView_Desc',
    buttonText: 'Techcard_Autosummary_DetailView_ButtonText',
  },
  text_to_speech: {
    heading: 'Techcard_TexttoSpeech_DetailView_Title',
    discription: 'Techcard_TexttoSpeech_DetailView_Desc',
    buttonText: 'Techcard_TexttoSpeech_DetailView_ButtonText',
  },
  voice_authentication: {
    heading: 'Techcard_VoiceAuthentication_DetailView_Title',
    discription: 'Techcard_VoiceAuthentication_DetailView_Desc',
    buttonText: 'Techcard_VoiceAuthentication_DetailView_ButtonText',
  },
  sound_event_detection: {
    heading: 'Techcard_SoundEventDetection_DetailView_Title',
    discription: 'Techcard_SoundEventDetection_DetailView_Desc',
    buttonText: 'Techcard_SoundEventDetection_DetailView_ButtonText',
  },
  text_QA: {
    heading: 'Techcard_Text_QnA_DetailView_Title',
    discription: 'Techcard_Text_QnA_DetailView_Desc',
    buttonText: 'Techcard_Text_QnA_DetailView_ButtonText',
  },
  video_content_search: {
    heading: 'Techcard_VideoContentsSearch_DetailView_Title',
    discription: 'Techcard_VideoContentsSearch_DetailView_Desc',
    buttonText: 'Techcard_VideoContentsSearch_DetailView_ButtonText',
  },
  realtime_speech_to_text: {
    heading: 'Techcard_RealtimeSpeechtoText_DetailView_Title',
    discription: 'Techcard_RealtimeSpeechtoText_DetailView_Desc',
    buttonText: 'Techcard_RealtimeSpeechtoText_DetailView_ButtonText',
  },
  speech_to_chat: {
    heading: 'Techcard_SpeechtoChat_DetailView_Title',
    discription: 'Techcard_SpeechtoChat_DetailView_Desc',
    buttonText: 'Techcard_SpeechtoChat_DetailView_ButtonText',
  },
  real_time_meeting: {
    heading: 'Techcard_RealtimeMeetingMinutes_DetailView_Title',
    discription: 'Techcard_RealtimeMeetingMinutes_DetailView_Desc',
    buttonText: 'Techcard_RealtimeMeetingMinutes_DetailView_ButtonText',
  },
};
