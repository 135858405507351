import {
  RtsttLanguageExampleApi, RtsttAudioExampleApi, SendAudioApi, SendAudioApiRTMM,
} from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { ExampleAudioLanguageStatusType, ExampleAudioStatusType, SendAudioPostType } from '../../Type/Rtstt';
import { AUDIOEXAMPLE, AUDIOSEND, LANGUAGEMODAL } from './actionType';
import { hideLoader, showLoader } from './loader';

export const RtsttLanguageExampleApp = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await RtsttLanguageExampleApi() as ExampleAudioLanguageStatusType;
    dispatch({
      type: LANGUAGEMODAL,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: LANGUAGEMODAL,
      payload: error,
    });
  }
};

export const RtsttAudioExampleApp = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await RtsttAudioExampleApi() as ExampleAudioStatusType;
    dispatch({
      type: AUDIOEXAMPLE,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: AUDIOEXAMPLE,
      payload: error,
    });
  }
};
/* action to handle sendAudio post api */
export const sendAudioApp = (formData: FormData, modal: string, roomId: string, userId: string) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await SendAudioApi(formData, modal, roomId, userId) as SendAudioPostType;
    dispatch({
      type: AUDIOSEND,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: AUDIOSEND,
      payload: error,
    });
  }
  dispatch(hideLoader());
};
export const sendChatAudioApp = (formData: FormData, modal: string, roomId: string, userId: string) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await SendAudioApi(formData, modal, roomId, userId) as SendAudioPostType;
    dispatch({
      type: AUDIOSEND,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: AUDIOSEND,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

/* action to handle sendAudio for RTMM card post api */
export const sendAudioAppRTMM = (formData: FormData, modal:string, roomId:string, userId:string) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await SendAudioApiRTMM(formData, modal, roomId, userId) as SendAudioPostType;
    return resp;
  } catch (error) {
    dispatch(hideLoader());
  }
  dispatch(hideLoader());
};
