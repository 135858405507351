import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import BgBannerDrop from '../../assets/images/bg-banner-drop.png';
import BgBannerDropMobile from '../../assets/images/bg-banner-drop-mobile.png';

import { fnSendEmail } from '../../Utils/SendEmail/sendEmail';

export const EmailUs: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="power--Banner">
      <div className="BannerRow">
        <div className="bannerBox">
          <Image src={BgBannerDrop} alt="Icon" className="bannerImage" />
          <Image src={BgBannerDropMobile} alt="Icon" className="bannerImage mobileView" />
          <h2 dangerouslySetInnerHTML={{ __html: t('Footer_Text') }} />
          <span className="emailUsBtn" onClick={fnSendEmail}>
          { t('Footer_Email') }
          </span>
        </div>
      </div>
    </div>
  );
};
