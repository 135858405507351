//This file contains api's urls used in the application.
const endPointUrl: string = process.env.REACT_APP_END_POINT as string;
export const API_AUTO_SUMMARY = {
  summary_example: 'demo/summary/example',
  summary_post: 'demo/summary',
};

export const SIGNIN = 'user/signin';

export const TTS = {
  tts_example: 'demo/tts/example',
  tts_post: 'demo/tts',
};

export const PERMISSION = 'demo/permission';

export const TQA = {
  example: 'demo/textqa/example',
  post: 'demo/textqa',
};

export const VCS = {
  example: 'demo/yourean/example',
  search_content: 'demo/yourean/search',
};

export const SIGNUP = 'user/signup';

export const VA = {
  enroll_example_sentence: 'demo/sv/example/sentence',
  enroll_example_audio: 'demo/sv/example/enroll',
  verification_example_audio: 'demo/sv/example/verification',
  enroll_audio: 'demo/sv/enroll',
  auth_audio: 'demo/sv/verification',
};

export const SSE = 'demo/rtstt/sse';

export const SENDAUDIO = 'demo/rtstt/message';
export const SENDAUDIOSTC = 'demo/rtstc/message';

export const SENDAUDIORTMM = 'demo/rtstt/message';

export const RTSTT = {
  RTSTT_EXAMPLE: 'demo/rtstt/model',
  RTSTT_AUDIOSEND: 'demo/rtstt/message',
  RTSTT_EXAMPLE_AUDIO: 'demo/rtstt/example',
};

export const SED = {
  SED_EXAMPLE: 'demo/sed/example',
  SED_UPLOAD: 'demo/sed/upload',
  SED_RESULT: 'demo/sed/result',
};

export const RTMM = {
  AVAILABLE_LANGUAGE: 'demo/rtstt/model',
};

export const PROFILE = {
  viewProfile: 'user/profile',
  EditProfile: 'user/profile',
  chnagePassword: 'user/account/password',
  deleteAccount: 'user/account',
};

export const eventAPI = `${endPointUrl}demo/rtstt/sse`;

export const reltimeMeetingeventAPI = `${endPointUrl}demo/rtstt/sse`;

export const FORGOTPASSWORD = {
  otpVerification: 'user/account/password/verify',
  changePassowrd: 'user/account/password',
  sendCodeEmail: 'user/account/password/reset',
};
