import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CHARLIMIT } from '../../Constants/Number';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { PROFILE } from '../../Redux/actions/actionType';
import { changePasswordApp } from '../../Redux/actions/Profile';
import { changePasswordStatusType } from '../../Type/Profile';
import { formValidator } from '../../Utils/FormValidation';
import { Input } from '../FormFields/Inputs';

export const ChangePassword: React.FC<{showChnagePassword: boolean; Forgetclose: React.Dispatch<React.SetStateAction<boolean>>}> = ({ showChnagePassword, Forgetclose }): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [passwordFields, setPasswordFields] = React.useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = React.useState(['currentPassword', 'password', 'confirmPassword']);

  const handleChange = (name: string, value: string) => {
    setPasswordFields({ ...passwordFields, [name]: value });
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const Payload = {
      password_old: passwordFields.currentPassword,
      password: passwordFields.password,
    };
    dispatch(changePasswordApp(Payload));
    event.preventDefault();
  };

  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  const chnagePasswordSuccess: changePasswordStatusType = useTypedSelector(
    (state) => state?.changePassword?.payload,
  );
  React.useEffect(() => {
    if (chnagePasswordSuccess && chnagePasswordSuccess?.status) {
      Forgetclose(false);
      setPasswordFields({ currentPassword: '', password: '', confirmPassword: '' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chnagePasswordSuccess]);

  const handleChnageClose = () => {
    setPasswordFields({ currentPassword: '', password: '', confirmPassword: '' });
    setErrors(['currentPassword', 'password', 'confirmPassword']);
    Forgetclose(false);
    dispatch({
      type: PROFILE.CHANGE_PASSWORD,
      payload: [],
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClipboardEvent = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
  <Modal
    className="SignInModal changePasswordModal"
    show={showChnagePassword}
    backdrop="static"
    onHide={() => handleChnageClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{t('ChangePassword.Title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p />
      <Form className="customFormUI" onSubmit={(e) => { handleSubmit(e); }}>
      {chnagePasswordSuccess?.status === false
        && <div className="errorMsgBox">
      <span>{t('Message.Incorrect_Old_Password')}</span>
           </div>
   }
        <Form.Group className="form-group with-icon" controlId="formBasicPassword">
        <Input
          autoFocus
          label={t('ChangePassword.OldPassword')}
          span="*"
          type="password"
          required
          value={passwordFields.currentPassword}
          onPaste={handleClipboardEvent}
          isShowPassword
          tabIndex={CHARLIMIT.TWO}
          minLimit="6"
          isOldPassword
          compareValue={passwordFields.password}
          updateValidStatus={(isValid: boolean) => validatedForm('currentPassword', isValid)}
          onChange={(e: string) => handleChange('currentPassword', e.trim())}
          />
        </Form.Group>
        <Form.Group className="form-group with-icon" controlId="formBasicPassword">
        <Input
          label={t('ChangePassword.NewPassWord')}
          span="*"
          type="password"
          value={passwordFields.password}
          onPaste={handleClipboardEvent}
          onChange={(e: string) => handleChange('password', e.trim())}
          // className={resetPassSuccess?.msg && 'error'}
          required
          isShowPassword
          isOldPasswordSame
          tabIndex={CHARLIMIT.TWO}
          minLimit="6"
          compareValue={passwordFields.currentPassword}
          compareConfirmValue={passwordFields.confirmPassword}
          updateValidStatus={(isValid: boolean) => validatedForm('password', isValid)}
          />
        </Form.Group>
        <Form.Group className="form-group with-icon" controlId="formBasicPassword">
        <Input
          label={t('ChangePassword.ConfirmPassWord')}
          span="*"
          type="password"
          value={passwordFields.confirmPassword}
          onPaste={handleClipboardEvent}
          onChange={(e: string) => handleChange('confirmPassword', e.trim())}
          // className={resetPassSuccess?.msg && 'error'}
          required
          isShowPassword
          tabIndex={CHARLIMIT.THREE}
          minLimit="6"
          isNewPasswordConfirmation
          compareValue={passwordFields.password}
          updateValidStatus={(isValid: boolean) => validatedForm('confirmPassword', isValid)}
          />
        </Form.Group>
        <div className="action-btn">
          <Button variant="primary" disabled={errors.length > 0} type="submit" tabIndex={CHARLIMIT.FOUR}>{t('ChangePassword.Button_Submit')}</Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
  );
};
