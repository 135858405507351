import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CHARLIMIT } from '../../Constants/Number';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { deleteAccountApp } from '../../Redux/actions/Profile';
import { signOut } from '../../Redux/actions/sessions';
import { deleteAccountStatusType, viewProfileStatusType } from '../../Type/Profile';
import { formValidator } from '../../Utils/FormValidation';
import { Input } from '../FormFields/Inputs';
import { DeleteSuccessModal } from '../Modal/DeleteSuccessModal';

export const DeleteAccount: React.FC<{deleteAccount: boolean; Deleteclose: React.Dispatch<React.SetStateAction<boolean>>}> = ({ deleteAccount, Deleteclose }): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const [errors, setErrors] = React.useState(['password']);

  const handleChange = (value: string) => {
    setCurrentPassword(value);
  };

  const profileData: viewProfileStatusType = useTypedSelector(
    (state) => state.profileData?.payload,
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const Payload = {
      password: currentPassword,
      id: profileData.data?.id,
    };
    dispatch(deleteAccountApp(Payload));
    setSuccess(true);
    event.preventDefault();
  };

  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };
  const sussceeObject = {
    heading: t('DeleteAccount_Successful_Screen.Title'),
    description: t('DeleteAccount_Successful_Screen.Desc'),
    button_text: t('DeleteAccount_Successful_Screen.Button_Submit'),
    redirect_text: '',
  };
  const successButtonClick = () => {
    dispatch(signOut());
    navigate('/');
    setShowSuccess(false);
  };
  const deleteAccountSuccess: deleteAccountStatusType = useTypedSelector(
    (state) => state.deleteAccount?.payload,
  );

  useEffect(() => {
    if (deleteAccountSuccess && deleteAccountSuccess?.status) {
      Deleteclose(false);
      setShowSuccess(true);
    } else setSuccess(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAccountSuccess]);

  const handleCancel = () => {
    setCurrentPassword('');
    Deleteclose(false);
    setSuccess(false);
  };

  return (
  <><Modal
    className="SignInModal DeleteAccountModal"
    show={deleteAccount}
    backdrop="static"
    onHide={() => Deleteclose(false)}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="signInModalTitle">{t('DeleteAccount.Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <span className="d-block">{t('DeleteAccount.Desc')}</span>
        </p>
        <Form className="customFormUI">
          {deleteAccountSuccess?.status === false
            && success && <div className="errorMsgBox">
              <span>{t('Message.Incorrect_Password')}</span>
                          </div>}
          <Form.Group className="form-group with-icon" controlId="formBasicPassword">
            <Input
              autoFocus
              label={t('DeleteAccount.CurrentPassword')}
              span="*"
              type="password"
              required
              value={currentPassword}
              isShowPassword
              tabIndex={CHARLIMIT.ONE}
              minLimit="6"
              updateValidStatus={(isValid: boolean) => validatedForm('password', isValid)}
              onChange={(e: string) => handleChange(e.trim())} />
          </Form.Group>
          <div className="action-btn">
            <Button variant="secondary" className="btnNo" onClick={() => handleCancel()} tabIndex={CHARLIMIT.TWO}>
              {t('DeleteAccount.Button_Cancel')}
            </Button>
            <Button variant="primary" className="btnYes" disabled={errors.length > 0} onClick={(e) => { handleSubmit(e); }} tabIndex={CHARLIMIT.THREE}>
              {t('DeleteAccount.Button_Submit')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal><DeleteSuccessModal isOpen={showSuccess} Successclose={successButtonClick} messageContent={sussceeObject} buttonAction={successButtonClick} />
  </>
  );
};
