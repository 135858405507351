import { TextQaPostType } from '../../Type/TextQa.d';
import { hideLoader, showLoader } from './loader';
import { TextQaAnswerApi, TextQaExampleApi } from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { TEXTQA } from './actionType';
import { TextQaExampleActionType, TextQaPostActionType } from '../actionType/TextQA';

export const textQaExample = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await TextQaExampleApi() as TextQaExampleActionType;
    dispatch({
      type: TEXTQA.EXAMPLE,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: TEXTQA.EXAMPLE,
      payload: error,
    });
  }
};

export const textQaPost = (data: TextQaPostType) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await TextQaAnswerApi(data) as TextQaPostActionType;
    dispatch({
      type: TEXTQA.ANSWER,
      payload: resp,
    });
    dispatch(hideLoader());
  } catch (error) {
    dispatch({
      type: TEXTQA.ANSWER,
      payload: error,
    });
    dispatch(hideLoader());
  }
};

export const clearQaAnswer = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: TEXTQA.ANSWER,
    payload: {},
  });
};
