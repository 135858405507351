import React from 'react';
import ReactDOM from 'react-dom/client';
import HttpApi from 'i18next-http-backend';
import AudioRecorder from 'audio-recorder-polyfill';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './Redux/store';

window.MediaRecorder = AudioRecorder;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ko', 'en'],
    fallbackLng: 'ko',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });

root.render(
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
