import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './dashboard.scss';
import { TechCard } from '../../Components/TechCard/TechCard';
import { TECH_CARD_CONTENT } from '../../Constants/TechCard';

const Dashboard: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Container className="dashboardContainer">
      <h1 dangerouslySetInnerHTML={{ __html: t('Landingpage_title') }} />
      <div className="cardViewRow">
        {TECH_CARD_CONTENT.map((content) => (
          <TechCard key={content.index} {...content} />
        ))}
      </div>
    </Container>
  );
};

export default Dashboard;
