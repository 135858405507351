import { Err } from '../../Type/Error';
import { SignInType, forgetPasswordType, resetPasswordStatusType } from '../../Type/Session';
import {
  SIGNIN, FORGETPASSWORD, RESETPASSWORD, VERIFIACTIOCODE,
} from '../actions/actionType';
import {
  SignInReducerType, forgetPasswordReducerType, resetPasswordReducerType, verifiactioCodeReducerType,
} from '../actionType/session';

type State = {
    payload: string[] | SignInType | forgetPasswordType | resetPasswordStatusType | Err;
    error: string | null;
  }
const initialState = {
  payload: [],
  error: null,
};
export const signinReducer = (state: State = initialState, action: SignInReducerType = {} as SignInReducerType):State => {
  if (action?.type === SIGNIN) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const forgetPasswordReducer = (state: State = initialState, action: forgetPasswordReducerType = {} as forgetPasswordReducerType):State => {
  if (action?.type === FORGETPASSWORD) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
export const verificatioCodeReducer = (state: State = initialState, action: verifiactioCodeReducerType = {} as verifiactioCodeReducerType):State => {
  if (action?.type === VERIFIACTIOCODE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const resetPasswordReducer = (state: State = initialState, action: resetPasswordReducerType = {} as resetPasswordReducerType):State => {
  if (action?.type === RESETPASSWORD) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
