/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Card,
  Image,
  Button,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { getExampleAudio } from '../../Redux/actions/VoiceAuthentication';
import { AuthAudioType, ExampleAudioType } from '../../Type/VoiceAuthenticate';
import { EnrollTryExample } from './EnrollTryExample';
import icUpload from '../../assets/images/ic-upload.svg';
import { CHARLIMIT } from '../../Constants/Number';
import { MIME_TYPE_AUDIO_ONLY } from '../../Constants/Static';

export const RegisterAuthAction: React.FC<{
  setExampleAuthAudio: React.Dispatch<React.SetStateAction<string>>; exampleAuthAudio: string; setExampleLabel: React.Dispatch<React.SetStateAction<string>>; exampleLabel: string; authExampleVoice : (val: boolean) => void;
  startVoiceRecoed : () => void; startRecoeding: boolean; readyToAuth: boolean;
  setReadyToAuth: React.Dispatch<React.SetStateAction<boolean>>; handleFileInput: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void; resetVal: (type: string) => void; authFile: File | undefined; isAutoPlay: boolean; authResultArray: AuthAudioType[]; authTimer: boolean;
}> = ({
  setExampleAuthAudio, exampleAuthAudio, setExampleLabel, exampleLabel, authExampleVoice, startVoiceRecoed, startRecoeding, readyToAuth, setReadyToAuth, handleFileInput, resetVal, authFile, isAutoPlay, authResultArray, authTimer,
}): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const enrollAudio: ExampleAudioType[] = useTypedSelector(
    (state) => state.exampleAudio?.payload,
  );
  const isLoading = useTypedSelector((state) => state.loader);

  React.useEffect(() => {
    if (enrollAudio.length === 0) {
      dispatch(getExampleAudio('auth'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const truncateString = (string: string | undefined) => (string && (string.length > CHARLIMIT.TEN ? `${string.substring(CHARLIMIT.ZERO, CHARLIMIT.EIGHTEEN)}…` : string));
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {authFile && authFile?.name}
    </Tooltip>
  );
  return (
    <Card.Footer>
      <div className="action-left">
      {((!readyToAuth && !startRecoeding && !authFile && !isLoading?.status) || exampleAuthAudio) && <EnrollTryExample
        setExampleA={setExampleAuthAudio}
        exampleAudio={exampleAuthAudio}
        setExampleLabel={setExampleLabel}
        exampleLabel={exampleLabel}
        setReadyToRegister={setReadyToAuth}
        enrollAudio={enrollAudio}
        isPlay={isAutoPlay}
        />
      }
        <span className="upload-action-btn">
          {((!readyToAuth && !startRecoeding && !isLoading?.status && !exampleAuthAudio) || authFile)
          && <div className={`${isLoading.status ? 'customUploadBtn d-none' : 'customUploadBtn'}`}>
             <label htmlFor="file-upload" className="custom-file-upload">
                <Image src={icUpload} alt="Icon" />
                {!authFile ? <span className="uploadTxt">{t('Techcard_VoiceAuthentication_DetailView_Tab_UploadFile')}</span>
                  : <OverlayTrigger
                      placement="right"
                      delay={{ show: 200, hide: 100 }}
                      overlay={renderTooltip}
                  ><span className="uploadTxt">{truncateString(authFile.name)}</span>
                    </OverlayTrigger>
                }
                <input id="file-upload" accept={MIME_TYPE_AUDIO_ONLY} type="file" onChange={(e) => handleFileInput(e, 'auth_upload')} />
             </label>
             </div>
          }
        </span>
      </div>
    {!readyToAuth && !isLoading?.status && !startRecoeding
    && <div className="action-right">
       <Button className="startRecordingBtn" disabled={isLoading?.status} onClick={() => startVoiceRecoed()}>{authResultArray.length > CHARLIMIT.ZERO ? t('Techcard_VoiceAuthentication_DetailView_Tab2_RecordAgain') : t('Techcard_VoiceAuthentication_DetailView_Tab2_StartRecording')}</Button>
       </div>
    }
    {(readyToAuth || startRecoeding)
    && <div className="action-right">
       <div>
        <Button className="clearBtn cursor" disabled={isLoading?.status} onClick={() => resetVal('auth')}>{t('Techcard_VoiceAuthentication_DetailView_Tab_Cancel')}</Button>
       </div>
       <div>
        <Button className="startRecordingBtn" disabled={isLoading?.status || (startRecoeding && !authTimer)} onClick={() => authExampleVoice(startRecoeding)}>{t('Techcard_VoiceAuthentication_DetailView_Tab2_Submit')}</Button>
       </div>
       </div>
    }
    </Card.Footer>
  );
};
