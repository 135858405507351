/* eslint-disable react/no-danger */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Card,
  Form,
  Image,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { AutoSummarizationPostType } from '../../Redux/actionType/AutoSummarization';
import { autoSummarizationExample, autoSummarizationGenerate } from '../../Redux/actions/autoSummarization';
import SummaryPlaceholder from '../../assets/images/summary-placeholder.svg';
import { AutoSummarizationExample, AutoSummarizationInput, AutoSummarizationResult } from '../../Type/AutoSumamrization';
import { Loader } from '../Loader/Loader';
import { AUTO_SUMMARIZATION } from '../../Redux/actions/actionType';
import { addBodyClass } from '../../Utils/BodyClass';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';
import { CHARLIMIT } from '../../Constants/Number';

export const AutoSummerizationInput: React.FC<AutoSummarizationInput> = ({ setConfrimationModal, confirm }): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const scrollRefs = useRef<null | HTMLDivElement>(null);
  const [paragraph, setParagraph] = useState('');
  const [finalSummary, setFinalSummary] = useState('');

  /* getting states from redux store */
  const summaryExamples: AutoSummarizationExample = useTypedSelector((state) => state.autoSummarizationExample?.payload);
  const summary: AutoSummarizationResult = useTypedSelector((state) => state.autoSummarizationGenerate?.payload);
  const isLoading = useTypedSelector((state) => state.loader);

  /* useCallback to call example api */
  const autoSummaryExample = useCallback(
    () => {
      dispatch(autoSummarizationExample());
    },
    [dispatch],
  );

  /* function to handle post api call */
  const fnPostSummarization = (value: string) => {
    scrollRefs?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    const postSummary: AutoSummarizationPostType = {
      context: value,
    };
    dispatch(autoSummarizationGenerate(postSummary));
  };

  /* useEffect to call autoSummaryExample callback */
  useEffect(() => {
    autoSummaryExample();
    dispatch({
      type: AUTO_SUMMARIZATION.GENERATE,
      payload: '',
    });
  }, [autoSummaryExample, dispatch]);

  /* useEffect to set final result of api response */
  useEffect(() => {
    const value = summary?.summary ? summary.summary : '';
    setFinalSummary(value);
  }, [summary]);

  /* useEffect to clear all states */
  useEffect(() => {
    if (confirm) {
      setParagraph('');
      setFinalSummary('');
      setConfrimationModal(false);
      dispatch({
        type: AUTO_SUMMARIZATION.GENERATE,
        payload: '',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, setConfrimationModal]);
  const removeExtraSpace = (s: string) => s.replace(/^\s+/g, '');

  return (
        <div className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6">
              <Card className="cardText">
                <Card.Header>
                  <span className="title-card">{ t('Techcard_Autosummary_DetailView_Input_Filed_Label') }</span>
                  <span className="title-action-btn autoSummeriDropdown">
                    <DropdownButton
                      align="end"
                      title={t('Techcard_Autosummary_DetailView_Dropdown_TryExample_Label')}
                      id="dropdown-menu-align-end"
                      className="DropdownWithOverLay"
                      onToggle={(value) => { addBodyClass(value); }}
                      >
                      <Dropdown.Header>{ t('Techcard_Autosummary_DetailView_Dropdown_Example_Label') }</Dropdown.Header>
                      <CustomScrollBar>
                      <div className="dropdwon-scroll">

                        {summaryExamples?.length > CHARLIMIT.ZERO && summaryExamples?.map((summaryExample) => (
                        <Dropdown.Item key={summaryExample?.text} eventKey="1" className={summaryExample.text === paragraph ? 'active' : ''} style={{ whiteSpace: 'pre' }} onClick={() => setParagraph(summaryExample?.text)}>
                          <span className="d-block" dangerouslySetInnerHTML={{ __html: summaryExample?.tag.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                        </Dropdown.Item>
                        ))}

                      </div>
                      </CustomScrollBar>
                    </DropdownButton>
                    <div className="DropdownBackdrop" />
                  </span>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group controlId="formBasictext">
                      <Form.Control as="textarea" placeholder={t('Techcard_Autosummary_DetailView_Input_Field_Placeholder')} value={paragraph} onChange={(e) => setParagraph(removeExtraSpace(e?.target?.value))} autoFocus />
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <Button onClick={() => setConfrimationModal(true)} className={(paragraph && !isLoading?.status) || finalSummary ? 'clearBtn cursor ' : 'clearBtn cursor disabled-btn '}>{t('Techcard_Autosummary_DetailView_Button_Text_Clear')}</Button>
                  <Card.Link className={paragraph && !isLoading?.status ? 'submitBtn cursor ' : 'submitBtn cursor disabled-btn '} onClick={() => paragraph && fnPostSummarization(paragraph)}>{t('Techcard_Autosummary_DetailView_Button_Text_Submit')}</Card.Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6" ref={scrollRefs}>
              <Card className="cardSummary">
                <Card.Header>
                  <span className="title-card">{t('Techcard_Autosummary_DetailView_Output_Filed_Label')}</span>
                </Card.Header>
                <Card.Body>
                  {finalSummary && !isLoading?.status && <div className="CardTextScroll"><Card.Text>{finalSummary}</Card.Text></div>}
                  {!finalSummary && !isLoading?.status
                  && <div className="summaryCardAEmptyBox">
                    <Image src={SummaryPlaceholder} alt="Icon" width="65" height="65" />
                     </div> }
                    <Loader />
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12">
              <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
              </p>
            </Col>
        </div>
  );
};
