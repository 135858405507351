import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { signInApp } from '../../Redux/actions/sessions';
import './SignInForm.scss';
import { SignInPostType, SignInType } from '../../Type/Session';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { formValidator } from '../../Utils/FormValidation';
import { Input } from '../FormFields/Inputs';
import { CHARLIMIT } from '../../Constants/Number';
import { SIGNIN } from '../../Redux/actions/actionType';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SIgnInForm: React.FC<{setShow?: React.Dispatch<React.SetStateAction<boolean>>, close: React.Dispatch<React.SetStateAction<boolean>>, setShowForgetPassword: React.Dispatch<React.SetStateAction<boolean>>, setSuccesReview: React.Dispatch<React.SetStateAction<boolean>>, setIsRoute?:React.Dispatch<React.SetStateAction<boolean>>; onSignUpClick: () => void;}> = ({
  close, setShowForgetPassword, onSignUpClick, setSuccesReview, setIsRoute, setShow,
}): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as {state:{path: string}};
  const [fields, setFields] = React.useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = React.useState(['email', 'password']);

  /*function to handle form submission */
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, _field: SignInPostType) => {
    dispatch(signInApp(_field, state?.path, navigate));
    event.preventDefault();
  };

  /*function to handle change in inputs */
  const handleChange = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
  };
  const loginDetail: SignInType = useTypedSelector((state) => state.signIn?.payload);

  /*function to handle form validation */
  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  /*function to handle finallogin using callback */
  const finalLogin = useCallback(
    () => {
      dispatch({
        type: SIGNIN,
        payload: [],
      });
      close(false);
    },
    [dispatch, close],
  );

  /*useEffect to handle user login when login details available */
  useEffect(() => {
    if (!loginDetail.msg && loginDetail.token) {
      finalLogin();
    }
  }, [loginDetail, finalLogin]);

  useEffect(() => {
    if (loginDetail.codeNumber === -CHARLIMIT.FOUR && !loginDetail.token) {
      dispatch({
        type: SIGNIN,
        payload: [],
      });
      setSuccesReview(true);
      close(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetail]);

  /*function to handle form submission */
  const onForgetPassword = () => {
    setShow && setShow(false);
    setShowForgetPassword(true);
    close(false);
    setIsRoute && setIsRoute(true);
  };

  return (
  <Form className="customFormUI">
      {loginDetail?.msg && <div className="errorMsgBox">
        <span>{loginDetail?.msg}</span>
                           </div>}
      <Form.Group className="form-group" controlId="formBasicEmail">
        <Input
          label={t('SignIn_EmailTextbox_Placeholder') as string}
          span="*"
          type="email"
          // placeholder="Enter Email"
          value={fields.email}
          onChange={(e: string) => handleChange('email', e.trim())}
          className={loginDetail?.msg && 'error'}
          required
          tabIndex={CHARLIMIT.TWO}
          updateValidStatus={(isValid: boolean) => validatedForm('email', isValid)} />
      </Form.Group>

      <Form.Group className="form-group with-icon" controlId="formBasicPassword">
        <Input
          label={t('SignIn_PasswordTextbox_Placeholder') as string}
          span="*"
          type="password"
          //   placeholder="Password"
          value={fields.password}
          onChange={(e: string) => handleChange('password', e.trim())}
          className={loginDetail?.msg && 'error'}
          required
          isShowPassword
          tabIndex={CHARLIMIT.THREE}
          updateValidStatus={(isValid: boolean) => validatedForm('password', isValid)} />
        <div className="link">
          <span onClick={() => onForgetPassword()} className="ForgotPasswordLink">{t('SignIn_Forgot_Password')}</span>
        </div>
      </Form.Group>
      <div className="action-btn">
        <Button variant="primary" type="submit" disabled={errors.length > CHARLIMIT.ZERO} onClick={(e) => { handleSubmit(e, fields); }}>
          {t('SignIn_ButtonText')}
        </Button>
      </div>
      <div className="SignUpAccount">
        <span className="txt">
          <Trans i18nKey="SignIn_DontHaveAccount" count={5}>
            Don’t have an account?<span onClick={() => onSignUpClick()} className="SignUpBtn">Sign Up</span>
          </Trans>
        </span>
      </div>
  </Form>
  );
};
