import {
  SignInPostType, resetPasswordType, forgetPasswordPostType, verifiactioCodePostType,
} from '../Type/Session';
import { SignUpPostType } from '../Type/SignUp';
import {
  TTS, API_AUTO_SUMMARY, SIGNIN, PERMISSION, TQA, VCS, SIGNUP, FORGOTPASSWORD, VA, SSE, SENDAUDIO, SENDAUDIORTMM, RTSTT, PROFILE, SED, RTMM, SENDAUDIOSTC,
} from '../Constants/Api';
import { AutoSummarizationPostType } from '../Redux/actionType/AutoSummarization';
import { http } from './interceptors';
import { TextQaPostType } from '../Type/TextQa';
import { cookieValue, getLocalItem } from '../Utils/LocalStorage';
import { AUTH_TOKEN } from '../Constants/Static';

import { changePasswordPostType, deleteAccountPostType, profileUpdatePostType } from '../Type/Profile';

export const PermissionApi = async (): Promise<unknown> => http.get<unknown>(PERMISSION);

export const SummaryExampleApi = async (): Promise<unknown> => http.get<unknown>(`${API_AUTO_SUMMARY.summary_example}`);

export const GenerateSummaryApi = async (data: AutoSummarizationPostType): Promise<unknown> => http.post<AutoSummarizationPostType>(`${API_AUTO_SUMMARY.summary_post}`, data);

export const SignInApi = async (data: SignInPostType): Promise<unknown> => http.post<unknown>(`${SIGNIN}`, data);

export const TtsExampleApi = async (): Promise<unknown> => http.get<unknown>(`${TTS.tts_example}`);

export const GenerateTtsApi = async (data: unknown): Promise<unknown> => http.post<unknown>(`${TTS.tts_post}`, data, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const TextQaExampleApi = async (): Promise<unknown> => http.wcget<unknown>(`${TQA.example}`);

export const TextQaAnswerApi = async (data: TextQaPostType): Promise<unknown> => http.post<TextQaPostType>(`${TQA.post}`, data);

export const VideoSearchExampleApi = async (): Promise<unknown> => http.wcget<unknown>(`${VCS.example}?lang=${cookieValue('i18next')}`);

export const searchVideoContentApi = async (searchVkeyword: string, pageLimit: number, pageNum: number): Promise<unknown> => http.wcget<unknown>(`${VCS.search_content}?token=${getLocalItem(AUTH_TOKEN)}&keyword=${searchVkeyword}&start=${pageNum}&num=${pageLimit}`);

export const SignUpApi = async (data: SignUpPostType): Promise<unknown> => http.post<unknown>(`${SIGNUP}`, data);

export const ForgetPasswordApi = async (data: forgetPasswordPostType): Promise<unknown> => http.post<unknown>(`${FORGOTPASSWORD.sendCodeEmail}`, data);

export const ExampleSentenceApi = async (): Promise<unknown> => http.wcget<unknown>(`${VA.enroll_example_sentence}?lang=${cookieValue('i18next')}`);

export const ResetPasswordApi = async (data: resetPasswordType): Promise<unknown> => http.post<unknown>(`${FORGOTPASSWORD.changePassowrd}`, data);
export const ExampleAudioApi = async (type: string): Promise<unknown> => http.wcget<unknown>(`${type === 'auth' ? VA.verification_example_audio : VA.enroll_example_audio}?lang=${cookieValue('i18next')}`);

export const enrollAudioApi = async (data: FormData): Promise<unknown> => http.post<unknown>(`${VA.enroll_audio}`, data);

export const openSseApi = async (roomId: string): Promise<unknown> => http.wcget<unknown>(`${SSE}/${roomId}`);

export const veirificationCodeApi = async (data: verifiactioCodePostType): Promise<unknown> => http.post<unknown>(`${FORGOTPASSWORD.otpVerification}`, data);

export const SendAudioApi = async (formData: FormData, model:string, roomId:string, userId:string): Promise<unknown> => http.post<unknown>(`${SENDAUDIO}/${model}/${roomId}/${userId}`, formData);

export const SendCHatAudioApi = async (formData: FormData, model:string, roomId:string, userId:string): Promise<unknown> => http.post<unknown>(`${SENDAUDIOSTC}/${model}/${roomId}/${userId}`, formData);

export const SendAudioApiRTMM = async (formData: FormData, model:string, roomId:string, userId:string): Promise<unknown> => http.post<unknown>(`${SENDAUDIORTMM}/${model}/${roomId}/${userId}`, formData);

export const RtsttLanguageExampleApi = async (): Promise<unknown> => http.wcget<unknown>(`${RTSTT.RTSTT_EXAMPLE}`);

export const RtsttAudioExampleApi = async (): Promise<unknown> => http.wcget<unknown>(`${RTSTT.RTSTT_EXAMPLE_AUDIO}?lang=${cookieValue('i18next')}`);
export const authAudioApi = async (data: FormData): Promise<unknown> => http.post<unknown>(`${VA.auth_audio}`, data);

export const viewProfileApi = async (): Promise<unknown> => http.wcget<unknown>(`${PROFILE.viewProfile}`);

export const profileEditApi = async (data: profileUpdatePostType): Promise<unknown> => http.patch<unknown>(`${PROFILE.EditProfile}`, data);

export const changePasswordApi = async (data: changePasswordPostType): Promise<unknown> => http.post<unknown>(`${PROFILE.chnagePassword}`, data);

export const deleteAccountApi = async (data: deleteAccountPostType): Promise<unknown> => http.delete<unknown>(`${PROFILE.deleteAccount}?password=${data.password}&id=${data.id}`);

export const sedExampleApi = async (): Promise<unknown> => http.wcget<unknown>(`${SED.SED_EXAMPLE}?lang=${cookieValue('i18next')}`);
export const sedResultApi = async (filename: string, model: string): Promise<unknown> => http.wcget<unknown>(`${SED.SED_RESULT}?filename=${filename}&model=${model}`);
export const sedUploadAudioFile = async (FormData: FormData): Promise<unknown> => http.post<unknown>(`${SED.SED_UPLOAD}`, FormData);

export const rtmmLanguageModel = async (): Promise<unknown> => http.wcget<unknown>(`${RTMM.AVAILABLE_LANGUAGE}`);
