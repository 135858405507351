import React, { useState, useRef } from 'react';

import { Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../Components/Modal/ConfirmationModal';
import { ExampleModal } from '../../Components/Modal/ExampleModal';
import './autoSummerization.scss';
import '../../assets/scss/loader.scss';

import { Heading } from '../../Components/Heading/Heading';
import { HEADING_CONTENT_TYPE } from '../../Constants/Heading';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import { AutoSummerizationInput } from '../../Components/AutoSummerization/AutoSummerizationInput';
import { scrollUp } from '../../Utils/ScrollTo';
import reviewImage from '../../assets/images/info-blue-ic.svg';
import { SuccessModal } from '../../Components/Modal/SuccessModal';
import { cookieValue } from '../../Utils/LocalStorage';

export const AutoSummerization: React.FC = (): React.ReactElement => {
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);
  const [ShowExampleModal, setExampleModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [language, setLanguage] = useState<string | undefined>('');
  const { t } = useTranslation();

  /* function to handle confiramtion modal yes button click */
  const confirmation = (value: boolean) => {
    setConfrimationModal(value);
    !value && setConfirm(value);
  };

  /* useEffect to handle scroll to top when landing on page for first time */
  React.useEffect(() => {
    scrollUp();
  }, []);
  const lang = cookieValue('i18next');
  const firstUpdate = useRef(true);

  React.useEffect(() => {
    setLanguage(lang);
    if (lang !== 'ko' && !firstUpdate.current) setSuccess(true);
    if (firstUpdate.current && language) {
      firstUpdate.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, language]);

  const [isSuccess, setSuccess] = React.useState(false);

  const sussceeReviewObject = {
    heading: t('onlyCoreanTechCard.Title'),
    description: t('onlyCoreanTechCard.Desc'),
    button_text: t('onlyCoreanTechCard.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClickNew = () => {
    setSuccess(false);
  };

  return (
    <>
        <Container className="AutoSummarizationContainer">
          <BreadcrumbBar name={t('Breadcrumb.AutoSummaryName')} url={pageUrl.home} />
          <Heading {...HEADING_CONTENT_TYPE.auto_summary} korean />
          <AutoSummerizationInput setConfrimationModal={confirmation} confirm={confirm} />
          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.auto_summary} type="autoSummary" />
        </Container>
        <CompanyLogoBanner />

      <ConfirmationModal
        close={setConfrimationModal}
        show={ShowConfirmationModal}
        confirm={setConfirm}
      />
      <ExampleModal
        close={setExampleModal}
        show={ShowExampleModal}
      />
      <SuccessModal
        showSuccess={isSuccess}
        Successclose={setSuccess}
        messageContent={sussceeReviewObject}
        buttonAction={successButtonClickNew}
        imagefile={reviewImage} />
    </>
  );
};
