import * as React from 'react';
import Header from './Header/Header';
import { EmailUs } from '../EmailUs/EmailUs';
import Footer from './Footer/Footer';

type Children = {
  children: React.ReactNode;
};

export const DefaultLayout: React.FC<Children> = (props): React.ReactElement => (
  <>
    <Header />
    {props?.children}
    <EmailUs />
    <Footer />
  </>
);
