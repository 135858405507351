import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import { Input } from '../FormFields/Inputs';
import './SignInForm.scss';
import { resetPasswordStatusType } from '../../Type/Session';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { formValidator } from '../../Utils/FormValidation';
import { resetPasswordApp } from '../../Redux/actions/sessions';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { CHARLIMIT } from '../../Constants/Number';
import { VERIFIACTIOCODE } from '../../Redux/actions/actionType';

export const ResetPasswordModal: React.FC<{
  resetOpen: boolean; setResetOpen: React.Dispatch<React.SetStateAction<boolean>>; setSuccess: React.Dispatch<React.SetStateAction<boolean>>; emailData: string; otp: string; setEmail: React.Dispatch<React.SetStateAction<string>>; setOpt: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  resetOpen, setResetOpen, setSuccess, emailData, otp, setEmail, setOpt,
}): React.ReactElement => {
  /*function to handle form submission */
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setResetOpen(false);
    e.preventDefault();
  };
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [passwordFields, setPasswordFields] = React.useState({
    passWord: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = React.useState(['passWord', 'confirmPassword']);

  /*function to handle change in inputs */
  const handleChange = (name: string, value: string) => {
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  /* form submission on button click */
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const Payload = {
      email: emailData,
      code: otp,
      password: passwordFields.passWord,
    };
    dispatch(resetPasswordApp(Payload));
    setResetOpen(false);
    setSuccess(true);
    event.preventDefault();
  };

  /*function to handle form validation */
  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  /* getting api status from redux store */
  const resetPassSuccess: resetPasswordStatusType = useTypedSelector(
    (state) => state?.resetPassword?.payload,
  );

  /* useEffect to clear states when api give success response */
  React.useEffect(() => {
    if (resetPassSuccess && resetPassSuccess?.status) {
      setPasswordFields({ passWord: '', confirmPassword: '' });
      setEmail('');
      setOpt('');
      dispatch({
        type: VERIFIACTIOCODE,
        payload: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPassSuccess]);

  const handleClose = () => {
    setResetOpen(false);
    setPasswordFields({ passWord: '', confirmPassword: '' });
    setEmail('');
    setOpt('');
    // clear verifiction data
    dispatch({
      type: VERIFIACTIOCODE,
      payload: [],
    });
  };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClipboardEvent = (e: any) => {
    e.preventDefault();
    return false;
  };

  return (
  <Modal
    className="SignInModal ResetPasswordModal"
    show={resetOpen}
    backdrop="static"
    onHide={() => handleClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="signInModalTitle">{t('ResetPassword.Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <span className="d-block">{t('ResetPassword.Desc')}</span>
        </p>
        <Form onSubmit={onFormSubmit} className="customFormUI">
          <Form.Group className="form-group with-icon" controlId="formBasicPassword">
            <Input
              label={t('ResetPassword.New_Password')}
              span="*"
              type="password"
              value={passwordFields.passWord}
              onPaste={handleClipboardEvent}
              onChange={(e: string) => handleChange('passWord', e.trim())}
              className={resetPassSuccess?.msg && 'error'}
              required
              isShowPassword
              tabIndex={CHARLIMIT.ZERO}
              minLimit="6"
              compareValue={passwordFields.confirmPassword}
              updateValidStatus={(isValid: boolean) => validatedForm('passWord', isValid)}
              />
          </Form.Group>
          <Form.Group className="form-group with-icon" controlId="formBasicPassword">
            <Input
              label={t('ResetPassword.Confirm_Password')}
              span="*"
              type="password"
              value={passwordFields.confirmPassword}
              onPaste={handleClipboardEvent}
              onChange={(e: string) => handleChange('confirmPassword', e.trim())}
              className={resetPassSuccess?.msg && 'error'}
              required
              isShowPassword
              tabIndex={CHARLIMIT.ONE}
              minLimit="6"
              isNewPasswordConfirmation
              compareValue={passwordFields.passWord}
              updateValidStatus={(isValid: boolean) => validatedForm('confirmPassword', isValid)}
              />
          </Form.Group>
          <div className="action-btn">
            <Button variant="primary" type="submit" disabled={errors.length > CHARLIMIT.ZERO} onClick={(e) => { handleSubmit(e); }}>{t('ResetPassword.Button_Submit')}</Button>
          </div>
        </Form>
      </Modal.Body>
  </Modal>
  );
};
