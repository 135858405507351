import React from 'react';
import {
  Container,
} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import './voiceChat.scss';
import '../../assets/scss/loader.scss';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import '../../assets/scss/smallLoader.scss';
import { scrollUp } from '../../Utils/ScrollTo';
import { SpeechToChat } from '../../Components/SpeechToChat/SpeechToChat';

export const VoiceChat: React.FC = (): React.ReactElement => {
  React.useEffect(() => {
    scrollUp();
  }, []);
  const { t } = useTranslation();
  return (
        <>
        <Container className="AutoSummarizationContainer VoiceChatContainer">
          <BreadcrumbBar name={t('Breadcrumb.SpeechToChatName')} url={pageUrl.home} />
          <h1>
            <span>{t('Techcard_SpeechtoChat_DetailView_Title')}</span>
            <span className="btnDemo">{t('Techcard_SpeechtoChat_DetailView_ButtonText')}</span>
          </h1>
          <p className="heading-txt">
            {t('Techcard_SpeechtoChat_DetailView_Desc')}
            <br />
            {t('Techcard_SpeechtoChat_DetailView_Desc1')}
          </p>
          <SpeechToChat />
          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.speech_to_chat} type="voiceChat" />
        </Container>
        <CompanyLogoBanner />
        </>
  );
};
