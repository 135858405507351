//This file contains language keys for messages used in the application.
export const AUTH_TOKEN = 'accessToken';
export const USER_ID = 'userId';
export const LOGIN_DATA = 'loginData';

export const VIDEO_SEARCH_MESSAGE = {
  pn_page_load: {
    heading: '',
    discription: '',
  },
};

export const SED_OPTIONS = {
  notSelected: 'null',
  example: 'Try Examples',
  uploadRecording: 'Upload Audio File',
  recordSound: 'Record sound',
  soundRecorded: 'Recoding file done',
};

export const REAL_TIME_MEETING_NOTES = {
  notSelected: 'null',
  hardWareCountScreen: 'HardWare counts options',
  memberSettingsScreen: 'Member Setting Screen',
  startYourConversation: 'Start your conversation screen',
  chatScreen: 'ChatScreen',
};

export const CHAT_COLOR_CODE = ['#FF6352', '#FF8A00', '#FFC700', '#1DDE6A', '#FF7DF2', '#908EFF', '#39B8FF', '#1DDEDE'];
export const MIME_TYPE = 'audio/x-flac,audio/x-ms-wma,audio/x-aiff,audio/x-m4a,audio/mp4,audio/aac,audio/ogg,audio/opus,audio/wav,audio/mpeg,audio/mp3,audio/caf,audio/aiff,audio/aac,audio/aacp,audio/webm,audio/x-caf,audio/flac,audio/x-wav,audio/x-pn-wav';
export const MIME_TYPE_AUDIO_ONLY = 'audio/x-flac,audio/x-ms-wma,audio/x-aiff,audio/x-m4a,audio/aac,audio/ogg,audio/opus,audio/wav,audio/mpeg,audio/mp3,audio/caf,audio/aiff,audio/aac,audio/aacp,audio/webm,audio/x-caf,audio/flac,audio/x-wav,audio/x-pn-wav';
