/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadingType } from '../../Type/Heading';
import './heading.scss';

export const Heading: React.FC<HeadingType> = (headingProp): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <h1>
        <span>{t(headingProp.heading)}</span>
        <span className="two-tag-wrap">
          <span className="btnDemo">{t(headingProp.buttonText)}</span>
          {headingProp?.korean && <span className="KoreanOnlyTag">Korean only</span>}
        </span>
      </h1>
       { headingProp?.korean && <span className="KoreanOnlyTag KoreanOnlyTagMobile">Korean only</span>
}      <p className="heading-txt" dangerouslySetInnerHTML={{ __html: t(headingProp.discription) }} />
    </>
  );
};
