import { showLoader, hideLoader } from './loader';
import { GenerateTtsApi, TtsExampleApi } from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { TtsResultActionType, TtsExampleActionType } from '../actionType/Tts';
import { TtsInput } from '../../Type/Tts';
import { TTS } from './actionType';

export const ttsExampleAction = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await TtsExampleApi() as TtsExampleActionType;
    dispatch({
      type: TTS.EXAMPLE,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: TTS.EXAMPLE,
      payload: [],
    });
  }
};

export const ttsGenerateAction = (data: TtsInput) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const params = new URLSearchParams();
    params.append('text', data.text);
    params.append('speaker', data.speaker);

    const resp = await GenerateTtsApi(params) as TtsResultActionType;
    dispatch({
      type: TTS.GENERATE,
      payload: resp,
    });
    dispatch(hideLoader());
  } catch (error) {
    dispatch({
      type: TTS.GENERATE,
      payload: [],
    });
    dispatch(hideLoader());
  }
};
