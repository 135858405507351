import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { DefaultLayout } from '../Components/layout/DefaultLayout';
import { LABEL_CARD } from '../Constants/TechCard';
import { useTypedSelector } from '../Hooks/useTypeSelector';
import { PermissionType } from '../Type/Permission';
import { AutoSummerization } from '../Views/AutoSummerization/AutoSummerization';
import Dashboard from '../Views/Dashboard/Dashboard';
import { RealTimeMeetingMinutes } from '../Views/RealTimeMeetingMinutes/RealTimeMeetingMinutes';
import { RealTimeSpeechTOText } from '../Views/RealTimeSpeechTOText/RealTimeSpeechTOText';
import { SoundEventDetection } from '../Views/SoundEventDetection/SoundEventDetection';
import { TextToQA } from '../Views/TextToQA/TextToQA';
import { TextToSpeech } from '../Views/TextToSpeech/TextToSpeech';
import { Profile } from '../Views/Profile/Profile';
import { VideoContentSearch } from '../Views/VideoContentSearch/VideoContentSearch';
import { VoiceAuthentication } from '../Views/VoiceAuthentication/VoiceAuthentication';
import { VoiceChat } from '../Views/VoiceChat/VoiceChat';
import AuthorizedRoutes from './AuthorizedRoutes';
import PublicRoutes from './PublicRoutes';

const Router: React.FC = () => {
  const permissions: PermissionType[] = useTypedSelector(
    (state) => state.permissions?.payload,
  );
  if (permissions.length) {
    return (
    <BrowserRouter>
      <DefaultLayout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/auto-summerization"
            element={
              <AuthorizedRoutes permissions={permissions} label={LABEL_CARD.as}>
                <AutoSummerization />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/text-to-speech"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.tts}
              >
                <TextToSpeech />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/text-to-qa"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.tqa}
              >
                <TextToQA />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/video-Search"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.vcs}
              >
                <VideoContentSearch />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/voice-authentication"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.sv}
              >
                <VoiceAuthentication />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/sound-event-detection"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.sed}
              >
                <SoundEventDetection />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/realtime-speech-to-text"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.rtstt}
              >
                <RealTimeSpeechTOText />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/realtime-meeting"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.rtctt}
              >
                <RealTimeMeetingMinutes />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/voice-chat"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.vc}
              >
                <VoiceChat />
              </AuthorizedRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <AuthorizedRoutes
                permissions={permissions}
                label={LABEL_CARD.profile}
              >
                <Profile />
              </AuthorizedRoutes>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </DefaultLayout>
    </BrowserRouter>
    );
  }
  return (
<PublicRoutes />
  );
};

export default Router;
