import React from 'react';
import {
  Card,
  Image,
  Button,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { getExampleAudio } from '../../Redux/actions/VoiceAuthentication';
import { ExampleAudioType } from '../../Type/VoiceAuthenticate';
import { EnrollTryExample } from './EnrollTryExample';
import icUpload from '../../assets/images/ic-upload.svg';
import { CHARLIMIT } from '../../Constants/Number';
import { MIME_TYPE_AUDIO_ONLY } from '../../Constants/Static';

export const RegisterVoiceAction: React.FC<{
setExampleAudio: React.Dispatch<React.SetStateAction<string>>; exampleAudio: string; setExampleLabel: React.Dispatch<React.SetStateAction<string>>; exampleLabel: string; isVoiceRegister: number; registerExampleVoice : (val: boolean) => void; startVoiceRecoed : () => void; startRecoeding: boolean; readyToRegister: boolean; setReadyToRegister: React.Dispatch<React.SetStateAction<boolean>>; handleFileInput: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void; resetVal: (type: string) => void; registerFile: File | undefined; isAutoPlay: boolean;
}> = ({
  setExampleAudio, exampleAudio, setExampleLabel, exampleLabel, isVoiceRegister, registerExampleVoice, startVoiceRecoed, startRecoeding, readyToRegister, setReadyToRegister, handleFileInput, resetVal, registerFile, isAutoPlay,
}): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const enrollAudio: ExampleAudioType[] = useTypedSelector(
    (state) => state.exampleAudio?.payload,
  );
  const isLoading = useTypedSelector((state) => state.loader);
  React.useEffect(() => {
    if (enrollAudio?.length === 0) {
      dispatch(getExampleAudio('register'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const truncateString = (string: string | undefined) => (string && (string.length > CHARLIMIT.TEN ? `${string.substring(CHARLIMIT.ZERO, CHARLIMIT.EIGHTEEN)}…` : string));
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {registerFile && registerFile?.name}
    </Tooltip>
  );

  return (
    <Card.Footer>
    <div className={`action-left ${isVoiceRegister === 2 ? 'd-none' : ''}`}>
      {((!readyToRegister && !startRecoeding) || exampleAudio) && !isLoading?.status && <EnrollTryExample
        setExampleA={setExampleAudio}
        exampleAudio={exampleAudio}
        setExampleLabel={setExampleLabel}
        exampleLabel={exampleLabel}
        setReadyToRegister={setReadyToRegister}
        enrollAudio={enrollAudio}
        isPlay={isAutoPlay}
        />
      }
       <span className="upload-action-btn">
       {((!readyToRegister && !startRecoeding) || registerFile)
       && <div className={`${isLoading.status ? 'customUploadBtn d-none' : 'customUploadBtn'}`}>
           <label htmlFor="file-upload" className="custom-file-upload">
           <Image src={icUpload} alt="Icon" />
           {!registerFile ? <span className="uploadTxt">{`${t('Techcard_VoiceAuthentication_DetailView_Tab_UploadFile')}`}</span>
             : <OverlayTrigger
                 placement="right"
                 delay={{ show: 200, hide: 100 }}
                 overlay={renderTooltip}
    ><span className="uploadTxt">{truncateString(registerFile.name)}</span>
               </OverlayTrigger>
}
           <input id="file-upload" type="file" accept={MIME_TYPE_AUDIO_ONLY} onChange={(e) => handleFileInput(e, 'register_upload')} />
           </label>
          </div>
       }
       </span>
    </div>
    {!readyToRegister
      ? (<div className="action-right">
          <Button className="startRecordingBtn" onClick={() => startVoiceRecoed()}>{t('Techcard_VoiceAuthentication_DetailView_Tab_StartRecoding')}</Button>
         </div>
      ) : <div className="action-right">
          <div>
            <Button className="clearBtn cursor" disabled={isLoading?.status} onClick={() => resetVal('register')}>{t('Techcard_VoiceAuthentication_DetailView_Tab_Cancel')}</Button>
          </div>
          <div>
          <Button className="startRecordingBtn" disabled={!readyToRegister || isLoading?.status} onClick={() => registerExampleVoice(startRecoeding)}>{t('Techcard_VoiceAuthentication_DetailView_Tab1_Submit')}</Button>
          </div>
          </div>
    }
    </Card.Footer>
  );
};
