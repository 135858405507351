import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalType } from '../../Type/Modal';

export const ExampleModal: React.FC<ModalType> = ({ show, close }): React.ReactElement => (
  <Modal
    size="sm"
    show={show}
    onHide={() => close(false)}
    aria-labelledby="example-modal-sizes-title-sm"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-sm">Small Modal</Modal.Title>
    </Modal.Header>
    <Modal.Body>...</Modal.Body>
  </Modal>
);
