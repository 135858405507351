import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import { DeleteSuccessModalType } from '../../Type/Modal';
import './ConfirmationModal.scss';
import deleteIcon from '../../assets/images/delete_Icon.svg';

export const DeleteSuccessModal: React.FC<DeleteSuccessModalType> = ({
  isOpen, Successclose, messageContent, buttonAction,
}): React.ReactElement => (
  <Modal
    className="SignInModal successModal"
    show={isOpen}
    onHide={() => Successclose()}
    animation={false}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <div className="outerBox">
        <Image src={deleteIcon} alt="Icon" />
      </div>
<Modal.Title id="contained-modal-title-vcenter">{messageContent?.heading}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <span className="d-block">{messageContent?.description}</span>
      </p>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={() => buttonAction()} className="btnYes">
    {messageContent?.button_text}
    </Button>
    </Modal.Footer>
  </Modal>
);
