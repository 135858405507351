import { SignUpReturnType } from '../../Type/SignUp';
import { SIGNUP } from '../actions/actionType';
import { SignUpReducerType } from '../actionType/signUp';

type State = {
    payload: string[] | SignUpReturnType;
    error: string | null;
  };
const initialState = {
  payload: [],
  error: null,
};
export const signUpReducer = (state: State = initialState, action: SignUpReducerType = {} as SignUpReducerType):State => {
  if (action?.type === SIGNUP) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
