import {
  changePasswordApi, deleteAccountApi, profileEditApi, viewProfileApi,
} from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import {
  changePasswordPostType, changePasswordStatusType, deleteAccountPostType, deleteAccountStatusType, profileUpdatePostType, profileUpdateStatusType, viewProfileStatusType,
} from '../../Type/Profile';
import { PROFILE } from './actionType';

export const viewProfileApp = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await viewProfileApi() as viewProfileStatusType;
    dispatch({
      type: PROFILE.VIEW_PROFILE,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: PROFILE.VIEW_PROFILE,
      payload: error,
    });
  }
};

export const editProfileApp = (data: profileUpdatePostType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await profileEditApi(data) as profileUpdateStatusType;
    if (resp?.status) {
      dispatch({
        type: PROFILE.EDIT_PROFILE,
        payload: resp,
      });
    }
  } catch (error) {
    dispatch({
      type: PROFILE.EDIT_PROFILE,
      payload: error,
    });
  }
};

export const changePasswordApp = (data: changePasswordPostType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await changePasswordApi(data) as changePasswordStatusType;
    if (resp?.status) {
      dispatch({
        type: PROFILE.CHANGE_PASSWORD,
        payload: resp,
      });
    }
  } catch (error) {
    dispatch({
      type: PROFILE.CHANGE_PASSWORD,
      payload: error,
    });
  }
};

export const deleteAccountApp = (data: deleteAccountPostType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await deleteAccountApi(data) as deleteAccountStatusType;
    if (resp?.status) {
      dispatch({
        type: PROFILE.DELETE_ACCOUNT,
        payload: resp,
      });
    }
  } catch (error) {
    dispatch({
      type: PROFILE.DELETE_ACCOUNT,
      payload: error,
    });
  }
};
