/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'react-bootstrap';
import '../../assets/scss/smallLoader.scss';
import { VideoModalType } from '../../Type/Modal';
import { NUMBER, CHARLIMIT } from '../../Constants/Index';

export const VideoModal: React.FC<VideoModalType> = ({
  show, close, url, videoStartTime, videoData, openVideoTime,
}): React.ReactElement => {
  const playerRef = useRef<ReactPlayer>(null);

  /* function to play video from a specific timestamp (prop for react player) */
  const onReady = React.useCallback(() => {
    const arr = videoStartTime.split(':');
    const timeToStart = Number(arr[0]) * NUMBER.THREE_SIX_ZERO_ZERO + Number(arr[1]) * CHARLIMIT.SIXTEY + (+arr[CHARLIMIT.TWO]);
    if (playerRef.current && videoStartTime) {
      playerRef.current.seekTo(timeToStart, 'seconds');
    }
    // eslint-disable-next-line
  }, [playerRef.current, videoStartTime]);

  /* Useeffect to handle multiple timestamp calls */
  useEffect(() => {
    onReady();
    // eslint-disable-next-line
  }, [videoStartTime]);

  /*function to removeLastWord last word from a string */
  const removeLastWord = (str: string) => {
    const lastIndexOfSpace = str.lastIndexOf('.');
    if (lastIndexOfSpace === -1) {
      return str;
    }
    return str.substring(0, lastIndexOfSpace);
  };
  /* function to truncate string till a specific length */
  // const truncateString = (string: string | undefined) => (string && (string.length > CHARLIMIT.TEN ? `${string.substring(CHARLIMIT.ZERO, CHARLIMIT.THIRTY)}…` : string));

  return (
    <div>
      <Modal
        className="VidoeYTModal"
        show={show}
        onHide={() => close(false)}
        centered>
          <div className="rows">
            <div className="col-mds-8">
               <ReactPlayer
                 ref={playerRef}
                 fileConfig={{ attributes: { autoPlay: true } }}
                 url={url}
                 controls
                 playing
                 onReady={onReady} />
            </div>
            <div className="col-mds-4">
              <div className="video-time-laps">
                <Modal.Header closeButton>{videoData?.title}</Modal.Header>
                <ul className="list-unstyled">
                {videoData && videoData.chain?.map((timeChain: any) => (
                  <li className="list-item loopItem">
                    <div className="left-item">
                      <span onClick={() => openVideoTime(videoData, removeLastWord(timeChain.timestamp['0'].toString()))}>{removeLastWord(timeChain.timestamp['0'].toString())}</span>
                    </div>
                    <div className="right-item">
                      {/* eslint-disable-next-line */ }
                      <span className="txt" dangerouslySetInnerHTML={{ __html: timeChain.text['0'] }} />
                    </div>
                  </li>
                ))}
                </ul>
              </div>
            </div>
          </div>
      </Modal>
    </div>
  );
};
