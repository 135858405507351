/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import {
  Card,
  Button,
  Image,
} from 'react-bootstrap';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { getExampleSentence, enrollAudio, authAudio } from '../../Redux/actions/VoiceAuthentication';
import { ExampleSentenceType, AuthAudioType } from '../../Type/VoiceAuthenticate';
import placeholderMicrophone from '../../assets/images/placeholder-microphone.svg';
import placeholderVoiceRecognition from '../../assets/images/placeholderVoiceRecognition.svg';
import icMicBlack from '../../assets/images/ic-mic-black.svg';
import stepCompleted from '../../assets/images/ic-stepCompleted.svg';
import { RegisterVoiceAction } from './RegisterVoiceAction';
import { RegisterAuthAction } from './RegisterAuthAction';
import { AuthResult } from './AuthResult';
import { AuthRecordTimer } from './AuthRecordTimer';
import { setToken, getLocalItem } from '../../Utils/LocalStorage';
import { scrollUp } from '../../Utils/ScrollTo';
import { RecordAudioBar } from '../Audio/RecordAudioBar';
import { Loader } from '../Loader/Loader';
import { CHARLIMIT, NUMBER } from '../../Constants/Number';
import { hideLoader } from '../../Redux/actions/loader';
import { getAudioVideoDuration } from '../../Utils/DevHelpers';
import placeholderWaves from '../../assets/images/wavesplaceholdervoiceauth.svg';
import { SuccessModal } from '../Modal/SuccessModal';
import reviewImage from '../../assets/images/IconMicBlocked.svg';

export const RegisterVoice: React.FC = (): React.ReactElement => {
  const routePath = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [exampleAudio, setExampleAudio] = React.useState('');
  const [exampleAuthAudio, setExampleAuthAudio] = React.useState('');
  const [exampleLabel, setExampleLabel] = React.useState('');
  const [isVoiceRegister, setVoiceRegister] = React.useState(CHARLIMIT.ZERO);
  const [startRecoeding, setStartRecording] = React.useState(false);
  const [readyToRegister, setReadyToRegister] = React.useState(false);
  const [readyToAuth, setReadyToAuth] = React.useState(false);
  const [startRegistration, setStartRegistration] = React.useState(false);
  const [startAuth, setStartAuth] = React.useState(false);
  const [authResultArray, setAuthResultArray] = React.useState<AuthAudioType[] | []>([]);
  const [registerFile, setRegisterFile] = React.useState<File>();
  const [authFile, setAuthFile] = React.useState<File>();
  const [authProgress, setAuthProgress] = React.useState(CHARLIMIT.ZERO);
  const [enableStepOne, setEnableStepOne] = React.useState(false);
  const [isAutoPlay, setAutoPlay] = React.useState(false);
  const [apiCall, setApiCall] = React.useState(false);
  const [authApiCall, setAuthApiCall] = React.useState(false);
  const [authTimer, setAuthTimer] = React.useState(false);
  const [stepTwoCompleted, setStepTwoCompleted] = React.useState(false);
  const [recordAudio, setRecordAudio] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  let resultObject: AuthAudioType;
  let interval: any = null;

  const enrollExample: ExampleSentenceType[] = useTypedSelector(
    (state) => state.exampleSentence?.payload,
  );

  const authResultData: AuthAudioType = useTypedSelector(
    (state) => state.authAudio.payload,
  );

  const isLoading = useTypedSelector((state) => state.loader);

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
  } = useAudioRecorder();

  React.useEffect(() => {
    if (authResultData && Object.keys(authResultData).length > CHARLIMIT.ZERO) {
      authResultArray[authResultArray.length - 1].status = authResultData?.status;
      authResultArray[authResultArray.length - 1].state = CHARLIMIT.TWO;
      authResultArray[authResultArray.length - 1].verification = authResultData?.verification;
      authResultArray[authResultArray.length - 1].score = authResultData?.score;
      authResultArray[authResultArray.length - 1].msg = authResultData?.msg;
      setAuthResultArray(authResultArray);
      setAuthProgress(NUMBER.HUNDRED);
      setStepTwoCompleted(true);
      clearInterval(interval);
      interval = null;
    }
  }, [authResultData]);

  React.useEffect(() => {
    scrollUp();
  }, [routePath]);

  React.useEffect(() => {
    if (exampleAudio || exampleAuthAudio) setShowError(false);
  }, [exampleAudio, exampleAuthAudio]);

  React.useEffect(() => {
    if (enrollExample.length === CHARLIMIT.ZERO) {
      dispatch(getExampleSentence());
    }
  }, []);

  const makeid = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = CHARLIMIT.ZERO; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (e.target.files) {
      const duration = await getAudioVideoDuration(e.target.files[0]);
      if (duration && duration <= CHARLIMIT.SIXTEY) {
        setShowError(false);
        if (e.target.files && type === 'register_upload') {
          setRegisterFile(e.target.files[CHARLIMIT.ZERO]);
          setReadyToRegister(true);
          setAutoPlay(!isAutoPlay);
        } else if (e.target.files) {
          setAuthFile(e.target.files[CHARLIMIT.ZERO]);
          setReadyToAuth(true);
          setAutoPlay(!isAutoPlay);
          setStepTwoCompleted(false);
        }
      } else {
        setShowError(true);
      }
    }
  };

  const registerCallback = () => {
    setVoiceRegister(CHARLIMIT.TWO);
    setExampleLabel('');
    setEnableStepOne(true);
    setAutoPlay(!isAutoPlay);
    setApiCall(false);
  };

  const registerExampleVoice = (val: boolean) => {
    if (val) {
      stopRecording();
      setStartRecording(false);
      setApiCall(true);
    } else {
      const clientId = makeid(CHARLIMIT.EIGHT);
      setToken('VA_CLIENTID', clientId);
      const formData = new FormData();
      if (exampleAudio) {
        formData.append('audio', exampleAudio);
      } else if (registerFile && registerFile?.size > CHARLIMIT.ZERO) {
        formData.append('audio', registerFile, registerFile?.name);
      }
      formData.append('id', clientId);
      dispatch(enrollAudio(formData, registerCallback));
    }
  };

  const registerVoiceRecording = () => {
    const clientId = makeid(CHARLIMIT.EIGHT);
    setToken('VA_CLIENTID', clientId);
    const formData = new FormData();
    if (recordingBlob) formData.append('audio', recordingBlob);
    formData.append('id', clientId);
    dispatch(enrollAudio(formData, registerCallback));
  };

  React.useEffect(() => {
    if (apiCall) {
      registerVoiceRecording();
    }
  }, [recordingBlob]);

  const updateProgressVaue = () => {
    let counter = CHARLIMIT.ZERO;
    interval = setInterval(() => {
      setAuthProgress((prevTime) => prevTime + CHARLIMIT.TEN);
      counter += 1;
      if (counter === CHARLIMIT.NINE) {
        clearInterval(interval);
      }
    }, NUMBER.FIVE_THOUSAND);
  };

  const authCallback = () => {
    setReadyToAuth(false);
    setAuthApiCall(false);
  };

  React.useEffect(() => {
    dispatch(hideLoader());
  }, []);

  const authExampleVoice = (value: boolean) => {
    if (value) {
      stopRecording();
      setStartRecording(false);
      setAuthApiCall(true);
    } else {
      setAuthProgress(CHARLIMIT.ZERO);
      const clientId = String(getLocalItem('VA_CLIENTID'));
      const formData = new FormData();
      resultObject = {
        state: CHARLIMIT.ONE,
        record: `record_${Date.now()}`,
        status: false,
        verification: false,
        score: CHARLIMIT.ZERO,
        code: CHARLIMIT.ZERO,
        msg: '',
      };
      setAuthResultArray((prevState) => [...prevState, resultObject]);
      if (exampleAuthAudio) {
        formData.append('audio', exampleAuthAudio);
      } else if (authFile && authFile?.size > CHARLIMIT.ZERO) {
        formData.append('audio', authFile, authFile?.name);
      }
      formData.append('id', clientId);
      updateProgressVaue();
      setExampleAuthAudio('');
      setExampleLabel('');
      setAuthFile(undefined);
      dispatch(authAudio(formData, authCallback));
    }
  };

  const authVoiceRecording = () => {
    setAuthProgress(CHARLIMIT.ZERO);
    const clientId = String(getLocalItem('VA_CLIENTID'));
    const formData = new FormData();
    resultObject = {
      state: CHARLIMIT.ONE,
      record: `record_${Date.now()}`,
      status: false,
      verification: false,
      score: CHARLIMIT.ZERO,
      code: CHARLIMIT.ZERO,
      msg: '',
    };
    setAuthResultArray((prevState) => [...prevState, resultObject]);
    if (recordingBlob) formData.append('audio', recordingBlob);
    formData.append('id', clientId);
    updateProgressVaue();
    setExampleAuthAudio('');
    setExampleLabel('');
    setAuthFile(undefined);
    dispatch(authAudio(formData, authCallback));
  };

  React.useEffect(() => {
    if (authApiCall) {
      authVoiceRecording();
    }
  }, [recordingBlob]);

  const getMedia = async () => {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return stream?.id;
    } catch (err) {
      return false;
    }
  };

  const startVoiceRecoed = async () => {
    const checkForMedia: any = await getMedia();
    if (!startRecoeding && checkForMedia) {
      setShowError(false);
      setStartRecording(true);
      startRecording();
      setAutoPlay(!isAutoPlay);
      setReadyToRegister(true);
    } else {
      setSuccess(true);
    }
  };
  const startAuthVoiceRecord = async () => {
    const checkForMedia: any = await getMedia();
    if (!startRecoeding && checkForMedia) {
      setStartRecording(true);
      startRecording();
      setReadyToAuth(true);
      setAutoPlay(!isAutoPlay);
      setStepTwoCompleted(false);
    } else {
      setSuccess(true);
    }
  };
  const resetVal = (type: string) => {
    if (type === 'auth') {
      setAuthApiCall(false);
      stopRecording();
      setStartRecording(false);
      setReadyToAuth(false);
      setExampleAuthAudio('');
      setExampleLabel('');
      setAuthFile(undefined);
      setAutoPlay(!isAutoPlay);
      setRecordAudio(false);
    } else {
      setApiCall(false);
      stopRecording();
      setStartRecording(false);
      setExampleAudio('');
      setReadyToRegister(false);
      setExampleLabel('');
      setRegisterFile(undefined);
      setAutoPlay(!isAutoPlay);
      setStepTwoCompleted(false);
    }
  };
  const goToStepOne = () => {
    setRegisterFile(undefined);
    setStartRegistration(false);
    setExampleAuthAudio('');
    setReadyToAuth(false);
    setReadyToRegister(false);
    setAuthFile(undefined);
    setVoiceRegister(CHARLIMIT.ZERO);
    setAuthProgress(CHARLIMIT.ZERO);
    setAuthResultArray([]);
    setStartAuth(false);
    setExampleLabel('');
    setExampleAudio('');
    setEnableStepOne(false);
    setRecordAudio(false);
    setShowError(false);
  };
  const callBacksRecord = () => {
    setStartRecording(true);
    startRecording();
  };
  const resetRecordignAuth = () => {
    setAuthTimer(false);
    if (startRecoeding) {
      setStartRecording(false);
      stopRecording();
      setRecordAudio(true);
    }
  };
  React.useEffect(() => {
    if (recordAudio) {
      callBacksRecord();
    }
  }, [recordAudio, isRecording]);

  const waveform = useRef<any | never | unknown>();
  React.useEffect(() => {
    if (!isRecording) {
      waveform.current?.microphone.stop();
      waveform.current?.microphone.destroy();
      waveform.current = null;
    }
  }, [isRecording]);

  const sussceeReviewObject = {
    heading: t('Mic_Disabled.Title'),
    description: t('Mic_Disabled.Desc'),
    button_text: t('Mic_Disabled.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClickNew = () => {
    setSuccess(false);
  };

  return (
    <>
    <Card.Header>
      <ul className="list-unstyled">
      <li className={`list-item list-item-tab ${isVoiceRegister === CHARLIMIT.ZERO ? 'active' : 'active step-completed'}`}>
        <span className={`${isVoiceRegister !== CHARLIMIT.ZERO ? 'active stepComletedIcon step-completed' : 'd-none'}`}>
        <Image src={stepCompleted} alt="Icon" />
        </span>
        <span className={`${isLoading?.status || startRecoeding || !enableStepOne ? 'content-wrap disabled-btn' : 'content-wrap'}`} onClick={goToStepOne}><span>{t('Techcard_VoiceAuthentication_DetailView_Tab1')}</span> <span>{t('Techcard_VoiceAuthentication_DetailView_Tab1_Title')}</span></span>
      </li>
       <li className={`list-item list-item-tab ${isVoiceRegister === CHARLIMIT.TWO ? 'active' : ''} ${!isLoading?.status && isVoiceRegister === CHARLIMIT.TWO && stepTwoCompleted ? 'active step-completed' : ''}`}>
        <span className="content-wrap"><span>{t('Techcard_VoiceAuthentication_DetailView_Tab2')}</span> <span>{t('Techcard_VoiceAuthentication_DetailView_Tab2_Title')}</span></span>
       </li>
      </ul>
    </Card.Header>
      <div className={`box-empty-state activeRegisteringState ${startRegistration ? 'd-none' : ''}`}>
      <span className="empty-state-Img">
        <Image src={placeholderMicrophone} alt="Icon" />
      </span>
      <strong className="empty-title">{t('Techcard_VoiceAuthentication_DetailView_Tab1_Content')}</strong>
      <p className="empty-txt">{t('Techcard_VoiceAuthentication_DetailView_Tab1_Content_Desc')}</p>
      <span className="call-to-action-wrap">
        <Button className="StartRegistrationBtn" onClick={() => setStartRegistration(true)}>{t('Techcard_VoiceAuthentication_DetailView_Tab1_Button_Submit')}</Button>
      </span>
      </div>

        <div className={`box-empty-state activeAuthenticateVoice ${isVoiceRegister === CHARLIMIT.TWO && !startAuth ? '' : 'd-none'}`}>
        <span className="empty-state-Img">
            <Image src={placeholderVoiceRecognition} alt="Icon" />
        </span>
        <strong className="empty-title">{t('Techcard_VoiceAuthentication_DetailView_Tab2_Content')}</strong>
        <p className="empty-txt">{t('Techcard_VoiceAuthentication_DetailView_Tab2_Content_Desc')}</p>
        <span className="call-to-action-wrap">
            <Button className="StartRegistrationBtn" onClick={() => setStartAuth(true)}>{t('Techcard_VoiceAuthentication_DetailView_Tab2_Button_Submit')}</Button>
        </span>
        </div>
        <div className="box-space">
        {startRegistration && (isVoiceRegister === CHARLIMIT.ZERO || (isLoading?.status && !startAuth))
        && <div className="box-pd">
            <strong className="box-title">{t('Techcard_VoiceAuthentication_DetailView_Tab1_RegisterVoice')}</strong>
            <p className="box-txt">{t('Techcard_VoiceAuthentication_DetailView_Tab1_RegisterVoice_Desc')}</p>
           </div>
        }
        {startAuth && isVoiceRegister === CHARLIMIT.TWO
        && <div className="box-pd">
            <strong className="box-title">{t('Techcard_VoiceAuthentication_DetailView_Tab2_AuthenticateVoice')}</strong>
            <p className="box-txt">{t('Techcard_VoiceAuthentication_DetailView_Tab2_RegisterVoice_Desc')}</p>
           </div>
        }
        {enrollExample?.length > CHARLIMIT.ZERO && (isVoiceRegister === CHARLIMIT.ZERO || (isLoading?.status && !startAuth)) && startRegistration
        && <div className={startRegistration && startRecoeding ? 'grey-box audio' : 'grey-box audio box-center'}>
            <Loader />
            {startRecoeding && <><p className="mt-25">{enrollExample[CHARLIMIT.ZERO]?.sentence}</p><p>{enrollExample[1]?.sentence}</p></>}
             {startRegistration && startRecoeding && <div><RecordAudioBar startRecoeding={startRecoeding} waveform={waveform} /></div>}
             {!exampleAudio && !startRecoeding && !registerFile && !isLoading?.status && <div className="waves-placeholder">
            <Image src={placeholderWaves} alt="Icon" className="d-none" />
                                                                                         </div>}
           </div>
        }
        <div className={`grey-box ${isLoading?.status ? 'box-center' : ''} ${isVoiceRegister === CHARLIMIT.TWO && startRegistration && startAuth ? '' : 'd-none'}`}>
            <Loader />
            <div className={`${isLoading?.status ? 'd-none' : 'RecordVoiceContainer'}`}>
            <div className={`${isLoading?.status ? 'd-none' : 'containerWrap'}`}>
              {startAuth && !readyToAuth
              && <div className={`${startRecoeding ? '"recordVoiceBtn" d-none' : 'recordVoiceBtn'}`} onClick={() => startAuthVoiceRecord()}>
                 <span className="icon"><Image src={icMicBlack} alt="Icon" /></span>
                 <span className="icon-txt">{t('Techcard_VoiceAuthentication_DetailView_Tab2_RecordVoice')}</span>
                 {/* <ProgressBar className="recordVoiceProgress" now={60} /> */}
                 </div>
              }
                {startRecoeding && startAuth && <AuthRecordTimer startRecoeding={startRecoeding} resetRecording={resetRecordignAuth} setAuthTimer={setAuthTimer} waveform={waveform} />}
            </div>
            {!startRecoeding && !authFile && !exampleAuthAudio && !isLoading?.status && <div className="waves-placeholder">
            <Image src={placeholderWaves} className="d-none" alt="Icon" />
                                                                                        </div>}
            </div>
        </div>
        <div className={`header-with-error ${isVoiceRegister === CHARLIMIT.TWO ? 'd-none' : ''}`}>
          {startRegistration && (isVoiceRegister === CHARLIMIT.ZERO || (isLoading?.status && !startAuth))
          && <RegisterVoiceAction
            setExampleAudio={setExampleAudio}
            exampleAudio={exampleAudio}
            setExampleLabel={setExampleLabel}
            exampleLabel={exampleLabel}
            isVoiceRegister={isVoiceRegister}
            registerExampleVoice={registerExampleVoice}
            startVoiceRecoed={startVoiceRecoed}
            startRecoeding={isRecording}
            readyToRegister={readyToRegister}
            setReadyToRegister={setReadyToRegister}
            handleFileInput={handleFileInput}
            resetVal={resetVal}
            registerFile={registerFile}
            isAutoPlay={isAutoPlay}
            />
          }
        </div>
        <div className={`header-with-error ${isVoiceRegister === CHARLIMIT.TWO && startAuth ? '' : 'd-none'}`}>
        <RegisterAuthAction
          setExampleAuthAudio={setExampleAuthAudio}
          exampleAuthAudio={exampleAuthAudio}
          setExampleLabel={setExampleLabel}
          exampleLabel={exampleLabel}
          authExampleVoice={authExampleVoice}
          startVoiceRecoed={startAuthVoiceRecord}
          startRecoeding={startRecoeding}
          readyToAuth={readyToAuth}
          setReadyToAuth={setReadyToAuth}
          handleFileInput={handleFileInput}
          resetVal={resetVal}
          authFile={authFile}
          isAutoPlay={isAutoPlay}
          authResultArray={authResultArray}
          authTimer={authTimer}
          />
        </div>
        {showError && <div className="uploaded-file-row--error statusFailed">
                        <div className="uploaded-file-content">
                          <span className="fileName">{t('Message.file_duration_One_min')}</span>
                        </div>
                      </div>}
        {authResultArray.length > CHARLIMIT.ZERO && <AuthResult authResultArray={authResultArray} now={authProgress} />}
        </div>
        <SuccessModal
          showSuccess={success}
          Successclose={setSuccess}
          messageContent={sussceeReviewObject}
          buttonAction={successButtonClickNew}
          imagefile={reviewImage} />

    </>
  );
};
