import React, { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../Components/Modal/ConfirmationModal';
import './textToQA.scss';
import '../../assets/scss/loader.scss';
import { Heading } from '../../Components/Heading/Heading';
import { HEADING_CONTENT_TYPE } from '../../Constants/Heading';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import { TextQNA } from '../../Components/TextQNA/TextQNA';
import { scrollUp } from '../../Utils/ScrollTo';
import { cookieValue } from '../../Utils/LocalStorage';
import { SuccessModal } from '../../Components/Modal/SuccessModal';
import reviewImage from '../../assets/images/info-blue-ic.svg';

export const TextToQA: React.FC = (): React.ReactElement => {
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);

  const [confirm, setConfirm] = useState(false);
  const confirmation = (value: boolean) => {
    setConfrimationModal(value);
    !value && setConfirm(value);
  };
  React.useEffect(() => {
    scrollUp();
  }, []);
  const { t } = useTranslation();

  const lang = cookieValue('i18next');
  const firstUpdate = useRef(true);
  const [language, setLanguage] = useState<string | undefined>('');
  React.useEffect(() => {
    setLanguage(lang);
    if (lang !== 'ko' && !firstUpdate.current) setSuccess(true);
    if (firstUpdate.current && language) {
      firstUpdate.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, language]);

  const [isSuccess, setSuccess] = React.useState(false);

  const sussceeReviewObject = {
    heading: t('onlyCoreanTechCard.Title'),
    description: t('onlyCoreanTechCard.Desc'),
    button_text: t('onlyCoreanTechCard.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClickNew = () => {
    setSuccess(false);
  };

  return (
        <>
        <Container className="AutoSummarizationContainer TextSpeechContainer Text--QandA--Container">
          <BreadcrumbBar name={t('Breadcrumb.TextQ&AName')} url={pageUrl.home} />
          <Heading {...HEADING_CONTENT_TYPE.text_QA} korean />
          <TextQNA setConfrimationModal={confirmation} confirm={confirm} />
          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.text_QA} type="textToQa" />
        </Container>
        <CompanyLogoBanner />

    <ConfirmationModal
      close={setConfrimationModal}
      show={ShowConfirmationModal}
      confirm={setConfirm}
    />
    <SuccessModal
      showSuccess={isSuccess}
      Successclose={setSuccess}
      messageContent={sussceeReviewObject}
      buttonAction={successButtonClickNew}
      imagefile={reviewImage} />
        </>
  );
};
