/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
import getBlobDuration from 'get-blob-duration';
import { WaveformContianer, Wave } from './Waveform';
import './audiBar.scss';
import SoundTrack from './sound-file.mp3';

export const AudioProgressBar: React.FC<{numberOfBars: number, regionColorMap:[], isPausePlay: boolean, audioFile: string,
    positionOfSlick: React.Dispatch<React.SetStateAction<any | null>>, end?: () => void, setIndex: React.Dispatch<React.SetStateAction<any | null>>, zoomShow:React.Dispatch<React.SetStateAction<boolean>> }> = ({
      isPausePlay = false, audioFile, numberOfBars, positionOfSlick, setIndex, regionColorMap, end, zoomShow,
    }): React.ReactElement => {
      let audio: HTMLMediaElement = document.getElementById('audio') as HTMLMediaElement;
      let timeline: HTMLInputElement = document.querySelector('#timeline') as HTMLInputElement;
      let tempwaveform : WaveSurfer;
      const [waveform, setWaveForm] = useState<WaveSurfer | WaveSurfer>();

      useEffect(() => {
        // eslint-disable-next-line
        (async function () {
          if (audioFile) {
            // eslint-disable-next-line
            audio = document.getElementById('audio') as HTMLMediaElement;
            audio.src = audioFile;
            audio.load();
            // eslint-disable-next-line
            tempwaveform = WaveSurfer.create({
              barWidth: 5,
              barRadius: 5,
              cursorWidth: 10,
              container: '#waveform',
              backend: 'WebAudio',
              height: 202,
              progressColor: '#25adfc',
              responsive: true,
              waveColor: 'rgba(37, 173, 252, 0.20)',
              cursorColor: '#000',
              plugins: [
                RegionsPlugin.create({
                  maxRegions: regionColorMap?.length || 0,
                }),
              ],
            });
            tempwaveform?.setMute(true);
            tempwaveform?.load(audioFile);
            if (regionColorMap) {
              regionColorMap.map((item) => tempwaveform?.addRegion(item));
            }
            setWaveForm(tempwaveform);
            audio.ontimeupdate = changeTimelinePosition;
            audio.onended = audioEnded;
            const time = await getBlobDuration(audioFile);
            const width = typeof window !== 'undefined' ? window.innerWidth : 0;
            if (time > 10) {
              zoomShow(true);
            } else if (width === 0) {
              zoomShow(true);
            } else if (width <= 768 && time >= 2) {
              zoomShow(true);
            } else {
              zoomShow(false);
            }
          }
        }());
      }, [audioFile, regionColorMap]);

      function changeTimelinePosition() {
        timeline = document.querySelector('#timeline') as HTMLInputElement;
        const percentagePosition = (100 * Number(audio?.currentTime)) / Number(audio?.duration);
        percentagePosition && (positionOfSlick(percentagePosition));
        percentagePosition && (timeline.style.backgroundSize = `${percentagePosition}% 100%`);
        percentagePosition && (timeline.value = (percentagePosition as unknown as string));
        percentagePosition && (setIndex(Math.floor((audio.currentTime * 1000) / 64)));
        tempwaveform?.setMute(true);
        tempwaveform?.setCurrentTime(audio?.currentTime);
        percentagePosition && tempwaveform?.seekAndCenter(percentagePosition / 100);
      }

      function audioEnded() {
        end && end();
      }

      const toggleAudio = (toPlay: boolean) => {
        if (audio) {
          if (toPlay) {
            audio.play();
          } else {
            audio.pause();
          }
        }
        if (waveform) {
          if (toPlay) {
            waveform.play();
          } else {
            waveform.pause();
          }
        }
      };

      useEffect(() => {
        toggleAudio(isPausePlay);
        // eslint-disable-next-line
      }, [isPausePlay]);

      useEffect(() => {
        if (waveform) {
          if (numberOfBars === 0) {
            waveform.zoom();
          } else {
            waveform.zoom(numberOfBars);
          }
        }
        // eslint-disable-next-line
      }, [numberOfBars]);

      return (
    <div className="visualizer-container">
     <WaveformContianer>
        <Wave id="waveform" />
        <audio id="audio" src={audioFile ? audioFile : SoundTrack} />
     </WaveformContianer>
    </div>
      );
    };
