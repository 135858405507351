import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  Button,
  Form,
} from 'react-bootstrap';
import icSearch from '../../assets/images/ic-search.svg';
import icSearchWhite from '../../assets/images/icon-searchWhite.svg';
import { SearchKeyword } from '../../Type/SearchExample';

export const SearchForm: React.FC<SearchKeyword> = ({ searchKeyword, setkeyword, exampleClick }): React.ReactElement => {
  /* function to handle user search some video content */
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    exampleClick(searchKeyword);
    e.preventDefault();
  };
  const { t } = useTranslation();

  return (
    <div className="searchBox">
        <Form onSubmit={onFormSubmit}>
        <div className="search-input-wrap">
            <span className="searchIcon">
            <Image src={icSearch} alt="Icon" />
            </span>
            <span className="searchInput">
            <Form.Group controlId="formBasictext">
                <Form.Control type="text" placeholder={t('Techcard_VideoContentsSearch_DetailView_Input_Field_Placeholder')} onChange={(e) => setkeyword(e?.target?.value)} value={searchKeyword} />
            </Form.Group>
            </span>
        </div>
        <span className="searchBtn">
            <Button type="submit" className="btnSearch" disabled={!searchKeyword.trim()}>
              <span className="Icon">
                <Image src={icSearchWhite} alt="Icon" />
              </span>
              <span className="Icontxt">{ t('Techcard_VideoContentsSearch_DetailView_Button_Text_Search') }</span>
            </Button>
        </span>
        </Form>
    </div>
  );
};
