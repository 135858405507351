import React, { ClipboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import './SignUpFrom.scss';
import { SignUpType, SignUpReturnType } from '../../Type/SignUp';
import { signUpApp, clearSignupState } from '../../Redux/actions/signUp';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { formValidator } from '../../Utils/FormValidation';
import { Input } from '../FormFields/Inputs';
import { CHARLIMIT } from '../../Constants/Number';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SignUpForm: React.FC<{close: React.Dispatch<React.SetStateAction<boolean>>, Successclose: React.Dispatch<React.SetStateAction<boolean>>}> = ({ close, Successclose }): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [fields, setFields] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
    company: '',
    fullName: '',
    industry: '',
    purpose: '',
  });
  const industryDefault = '';
  const purposeDefault = '';
  const [errors, setErrors] = React.useState(['email', 'password', 'fullName']);

  /* getting user signup state from redux store (api response) */
  const signUpData: SignUpReturnType = useTypedSelector(
    (state) => state?.SignUp?.payload,
  );

  /* useEffect to handle sign up modal close if api gives status true and show success modal*/
  React.useEffect(() => {
    if (signUpData && signUpData?.status) {
      Successclose(true);
      close(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpData]);

  /* useEffect to handle crossbutton close modal */
  React.useEffect(() => {
    dispatch(clearSignupState());
    Successclose(false);
    close(true);
    setFields({ ...fields, purpose: purposeDefault, industry: industryDefault });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* function to handle submission of form */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, _field: SignUpType) => {
    const Payload = {
      name: _field.fullName,
      email: _field.email,
      password: _field.password,
      company: _field.company,
      meta_industry: _field.industry,
      meta_purpose: _field.purpose,
    };
    dispatch(signUpApp(Payload));
    event?.preventDefault();
  };

  /* function to handle change in user inputs */
  const handleChange = (name: string, value: string) => {
    setFields({ ...fields, [name]: value });
  };

  /* function to handle change in user selection of dropdowns */
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>, name:string) => {
    const value = event.target.value;
    setFields({ ...fields, [name]: value });
  };

  /* function to handle form validation */
  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  /* function to remove extra spaces from input value*/
  const removeExtraSpace = (s: string) => s.replace(/^\s+/g, '');

  /* function to make first word in capital letter */
  const firstCpital = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  /* function to handle handleClipboardEvents */
  const handleClipboardEvent = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    return false;
  };

  return (
  <Form className="customFormUI" onSubmit={(e) => { handleSubmit(e, fields); }}>
   {signUpData?.msg
   && <div className="errorMsgBox">
      <span>{signUpData?.msg}</span>
      </div>
   }
   <div className="CustomScrollBarWrap">
   <CustomScrollBar height={340} className="scrollBar">
    <Form.Group className="form-group" controlId="formBasicEmail">
    <Input
      autoFocus
      label={t('SignUp_Work_Email')}
      span="*"
      type="email"
      value={fields.email}
      onChange={(e: string) => handleChange('email', e.trim())}
      className={signUpData?.msg && 'error'}
      required
      tabIndex={CHARLIMIT.ONE}
      updateValidStatus={(isValid: boolean) => validatedForm('email', isValid)}
      />
    </Form.Group>
    <Form.Group className="form-group with-icon" controlId="formBasicPassword">
    <Input
      label={t('SignUp_Password')}
      span="*"
      type="password"
      value={fields.password}
      onChange={(e: string) => handleChange('password', e.trim())}
      onPaste={handleClipboardEvent}
      className={signUpData?.msg && 'error'}
      required
      isShowPassword
      tabIndex={CHARLIMIT.TWO}
      minLimit="6"
      compareValue={fields.confirmPassword}
      updateValidStatus={(isValid: boolean) => validatedForm('password', isValid)}
      />
    </Form.Group>
    <Form.Group className="form-group with-icon" controlId="formBasicPassword">
    <Input
      label={t('SignUp_ConfirmPassword')}
      span="*"
      type="password"
   //   placeholder="Password"
      value={fields.confirmPassword}
      onChange={(e: string) => handleChange('confirmPassword', e.trim())}
      onPaste={handleClipboardEvent}
      className={signUpData?.msg && 'error'}
      required
      // minLimit="6"
      isShowPassword
      tabIndex={CHARLIMIT.THREE}
      compareValue={fields.password}
      isPasswordConfirmation
      updateValidStatus={(isValid: boolean) => validatedForm('password', isValid)}
      />
    </Form.Group>
    <Form.Group className="form-group" controlId="formBasicEmail">
    <Input
      label={t('SignUp_Full_Name')}
      span="*"
      type="text"
     // placeholder="Enter Email"
      value={fields.fullName}
      maxLimit="50"
      onChange={(e: string) => handleChange('fullName', firstCpital(removeExtraSpace(e)))}
      className={signUpData?.msg && 'error'}
      required
      tabIndex={CHARLIMIT.FOUR}
      updateValidStatus={(isValid: boolean) => validatedForm('fullName', isValid)}
      />
    </Form.Group>
    <Form.Group className="form-group" controlId="formBasicEmail">
    <Input
      label={t('SignUp_Company_Name')}
      span="*"
      type="text"
     // placeholder="Enter Email"
      value={fields.company}
      maxLimit="50"
      onChange={(e: string) => handleChange('company', firstCpital(removeExtraSpace(e)))}
      className={signUpData?.msg && 'error'}
      required
      tabIndex={CHARLIMIT.FIVE}
      updateValidStatus={(isValid: boolean) => validatedForm('email', isValid)}
      />
    </Form.Group>
     <Form.Group className="form-group withArrow" controlId="formBasicEmail">
      <FloatingLabel controlId="floatingSelect" label={t('SignUp_Industry')}>
        <Form.Select onChange={(e) => selectChange(e, 'industry')} defaultValue={industryDefault} aria-label="Floating label select example" className={`form-control ${fields.industry ? 'value-selected' : ''}`} tabIndex={CHARLIMIT.SIX}>
          <option style={{ display: 'none' }}>{industryDefault}</option>
          <option value=" ">{t('Industry.Select')}</option>
          <option value="Energy">{t('Industry.Energy')}</option>
          <option value="Materials">{t('Industry.Materials')}</option>
          <option value="Industrials">{t('Industry.Industrials')}</option>
          <option value="Consumer Discretionary">{t('Industry.ConsumerDiscretionary')}</option>
          <option value="Health Care">{t('Industry.HealthCare')}</option>
          <option value="Financials">{t('Industry.Financials')}</option>
          <option value="Information Technology">{t('Industry.InformationTechnology')}</option>
          <option value="Communication Services">{t('Industry.CommunicationServices')}</option>
          <option value="Utilities">{t('Industry.Utilities')}</option>
          <option value="Real Estate">{t('Industry.Real Estate')}</option>
        </Form.Select>
      </FloatingLabel>
     </Form.Group>
     <Form.Group className="form-group withArrow" controlId="formBasicEmail">
      <FloatingLabel controlId="floatingSelect" label={t('SignUp_What_Building')}>
        <Form.Select onChange={(e) => selectChange(e, 'purpose')} defaultValue={purposeDefault} aria-label="Floating label select example" tabIndex={CHARLIMIT.SEVEN} className={`form-control ${fields.purpose ? 'value-selected' : ''}`}>
        <option style={{ display: 'none' }}>{purposeDefault}</option>
        <option value=" ">{t('WhatAreYouBuilding.Select')}</option>
          <option value="I am hacking on a personal project">{t('WhatAreYouBuilding.HackingPersonalProject')}</option>
          <option value="I am exploring different speech-to-text APIs">{t('WhatAreYouBuilding.exploringAPIs')}</option>
          <option value="I am building a feature at my company">{t('WhatAreYouBuilding.BuildingFeature')}</option>
        </Form.Select>
      </FloatingLabel>
     </Form.Group>
   </CustomScrollBar>
   </div>
      <div className="agreeConditions">
        <span>
          <Trans i18nKey="SignUp_ServiceTerms" count={CHARLIMIT.FIVE}>
            By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" tabIndex={CHARLIMIT.EIGHT} className="ServiceTerms" rel="noreferrer">Service Terms</a> and <a href={t('Privacy_Policy')} target="_blank" tabIndex={CHARLIMIT.NINE} className="PrivacyPolicy" rel="noreferrer">Privacy Policy</a>
          </Trans>
        </span>
      </div>
    <div className="action-btn">
      <Button variant="primary" type="submit" tabIndex={CHARLIMIT.TEN} disabled={errors.length > CHARLIMIT.ZERO || !fields.company}>
      {t('SignUp_Button_CreateAccount')}
      </Button>
    </div>
  </Form>
  );
};
