/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-bitwise */
import React, { useEffect, useState, useRef } from 'react';
import {
  Col,
  Card,
  Image,
  Button,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { RtsttLanguageExampleApp, sendAudioApp } from '../../Redux/actions/Realtimetextchatminutes';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import {
  ExampleAudioLanguageStatusType, partialType,
} from '../../Type/Rtstt';
import { useAudioRecorder } from '../../realtimeAudioChunck';
import { eventAPI } from '../../Constants/Api';
import { getLocalItem } from '../../Utils/LocalStorage';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { CraeteId } from '../../Utils/DevHelpers';
import EnterChatRoomPlaceholder from '../../assets/images/EnterChatRoomPlaceholder.svg';
import EnterExistingChatRoomPlaceholder from '../../assets/images/EnterExistingChatRoomPlaceholder.svg';
import icMicMute from '../../assets/images/ic-mic-mute.svg';
import { CustomScrollBar } from '../../Components/CustomScrollBar/CustomeScrollBar';
import { addBodyClass } from '../../Utils/BodyClass';
import { ChatRoom } from './ChatRoom';
import { ChatUserList } from './ChatUserList';
import icMicBlue from '../../assets/images/mic-blue.svg';
import { Input } from '../FormFields/Inputs';
import { formValidator } from '../../Utils/FormValidation';
import { CHARLIMIT, NUMBER } from '../../Constants/Number';
import { ConfirmationModal } from '../Modal/ConfirmationModal';

export const SpeechToChat: React.FC = (): React.ReactElement => {
  const [isNewChatRoom, setNewChatRoom] = useState(false);
  const [isExistingChatRoom, setExistingChatRoom] = useState(false);
  const [isChatStart, setChatStart] = useState(false);
  const [language, setLanguage] = useState('en-us-vosk-v0.22');
  const [partial, setPartial] = useState<any | null>(null);
  const [transcript, setTranscript] = React.useState<partialType[] | []>([]);
  const [transText, setTransText] = useState<any | null>(null);
  const [isMute, setMute] = useState(false);
  const [chatRoomId, setChatRoomId] = useState('');
  const eventSource = useRef<any | never | unknown>();
  const dispatch = useDispatch<AppDispatch>();
  const [hideUser, setHideUser] = React.useState<string[]>([]);
  const userData = JSON.parse(String(getLocalItem('loginData')));
  const [roomId, setRoomId] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);

  const LanguageModal: ExampleAudioLanguageStatusType = useTypedSelector(
    (state) => state.exampleVoiceModalRtstt?.payload,
  );
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
  } = useAudioRecorder();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch((RtsttLanguageExampleApp()));
  }, []);

  const { search } = useLocation();
  const params = search?.split('=')[1];
  useEffect(() => {
    if (params) {
      enterExistingRoom();
      setChatRoomId(params);
    } else if (roomId || chatRoomId) {
      startChat(true);
    }
  }, [params]);

  const checkForSound = () => {
    stopRecording();
    setMute(false);
  };
  const checkForUnmute = () => {
    startRecording(250);
    setMute(true);
  };

  const enterNewRoom = () => {
    const roomsId = CraeteId(16);
    const userString = CraeteId(5);
    const usersId = `${userData?.name}${userString}`;
    setUserId(usersId);
    setRoomId(roomsId);
    setNewChatRoom(true);
    setExistingChatRoom(false);
  };

  const enterExistingRoom = () => {
    setExistingChatRoom(true);
    setNewChatRoom(false);
  };

  useEffect(() => {
    const objectP = JSON.parse(partial);

    if (partial && objectP && objectP.result !== '') {
      const tArr = [...transcript];
      const index = tArr.map((s) => s.userId === objectP?.userId).lastIndexOf(true);
      if (tArr.length === 0 || index === -1) {
        tArr.push(objectP);
      } else if (index !== -1) {
        tArr[index].result = objectP?.result;
      }
      setTranscript(tArr);
    }
  }, [partial]);

  useEffect(() => {
    const objectP = JSON.parse(transText);
    if (transText && objectP && objectP.result !== '') {
      const tArr = [...transcript];
      const index = tArr.map((s) => s.userId === objectP?.userId).lastIndexOf(true);
      if (index !== -1) {
        tArr[index].result = objectP?.result;
        tArr.push(objectP);
        setTranscript(tArr);
      }
    }
  }, [transText]);

  const openConnection = (isExisting: boolean) => {
    if (isExisting) {
      setRoomId(chatRoomId);
      setTranscript([]);
    }
    if (!roomId && !chatRoomId) {
      const roomsId = CraeteId(16);
      setRoomId(roomsId);
      setTranscript([]);
    }
    if (!userId) {
      const userString = CraeteId(5);
      const usersId = `${userData?.name}${userString}`;
      setUserId(usersId);
    }
    if (roomId) {
      eventSource.current = new EventSource(`${eventAPI}/${roomId}`);
      //eventSource.current.addEventListener('models', (event: any) => setRoom(event.data));
      eventSource.current.addEventListener('partial', (event: any) => {
        setPartial(event.data);
      });
      eventSource.current.addEventListener('text', (event: any) => {
        setTransText(event.data);
      });
    }
  };
  useEffect(() => {
    if (roomId) {
      openConnection(false);
    }
  }, [roomId]);

  const sendFileAudio = async (fileVal: File | Blob | ArrayBuffer) => {
    const model = language;
    const formData = new FormData();
    const message = new Blob(
      [JSON.stringify({
        model,
        roomId,
        userId,
        timestamp: Date.now(),
        sampleRate: NUMBER.AUDIO_SAMPLE_RATE,
      })],
      { type: 'application/json' },
    );
    formData.append('message', message);
    formData.append('data', new Blob([fileVal], { type: 'audio/wav' }));
    dispatch(sendAudioApp(formData, model, roomId, userId));
  };

  useEffect(() => {
    if (recordingBlob) {
      sendFileAudio(recordingBlob);
    }
  }, [recordingBlob]);

  const startVoiceRecording = () => {
    if (!isRecording) {
      setMute(true);
      startRecording(250);
    } else {
      eventSource.current.close();
      setMute(false);
      setRoomId('');
      setUserId('');
      setTranscript([]);
      stopRecording();
    }
  };

  const startChat = (isExisting?: boolean, e?: any) => {
    const existingRoom = isExisting ? isExisting : false;
    e && e.preventDefault();
    setChatStart(true);
    if (isExisting) openConnection(existingRoom);
    startVoiceRecording();
  };

  const ExitRoom = () => {
    setNewChatRoom(false);
    setExistingChatRoom(false);
    eventSource?.current?.close();
    setChatStart(false);
    setRoomId('');
    setUserId('');
    setChatRoomId('');
    setTranscript([]);
    setConfrimationModal(false);
    if (isRecording) stopRecording();
  };

  const goBack = () => {
    setNewChatRoom(false);
    setExistingChatRoom(false);
    setChatStart(false);
    setRoomId('');
    setUserId('');
    setChatRoomId('');
    setTranscript([]);
    if (isRecording) stopRecording();
  };
  const handleChange = (value: string) => {
    setChatRoomId(value);
  };

  const [errors, setErrors] = React.useState(['Id']);
  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggle = (value: boolean, modal?: string) => {
    addBodyClass(value);
    setShowDropdown(value);
    if (modal) setLanguage(modal);
  };

  return (
          <><div className="AutoSummarizationRow">
      <Col className="mb-30 AutoSummarizationCard voiceChatMCard" xs="12" sm="12" lg="12">
        <Card className="realTimeMeeting">
          <div className={`box-empty-state EnterChatRoomBox ${!isNewChatRoom && !isExistingChatRoom ? '' : 'd-none'}`}>
            <div className="imgBox">
              <Image src={EnterChatRoomPlaceholder} alt="Icon" />
            </div>
            <strong className="empty-title">{t('Techcard_SpeechtoChat_DetailView_EnterChatRoom')}</strong>
            <p className="empty-txt">
              <span className="d-block" dangerouslySetInnerHTML={{ __html: t('Techcard_SpeechtoChat_DetailView_HelpText') }} />
            </p>
            <div className="call-to-action-wrap">
              <Button className="NewChatRoomBtn cursor" onClick={() => enterNewRoom()}>{t('Techcard_SpeechtoChat_DetailView_NewChatRoom_Button')}</Button>
              <Button className="ExistingChatRoomBtn cursor" onClick={() => enterExistingRoom()}>{t('Techcard_SpeechtoChat_DetailView_ExistingRoom_Button')}</Button>
            </div>
          </div>

          <div className={`box-empty-state EnterExistingChatRoom ${isExistingChatRoom && !isChatStart ? '' : 'd-none'}`}>
            <div className="ImageBox">
              <span className="outlineBox size45">
                <Image src={EnterExistingChatRoomPlaceholder} alt="Icon" />
              </span>
            </div>
            <strong className="empty-title">{t('Techcard_SpeechtoChat_DetailView_EnterExitingRoom')}</strong>
            <p className="empty-txt">
              <span className="d-block">{t('Techcard_SpeechtoChat_DetailView_EnterRoomID_TextPlaceholder')}</span>
            </p>
            <Form onSubmit={(e) => startChat(true, e)}>
              <div className="customFormUI">
                <Form.Group className="form-group" controlId="formBasictext">
                  <Input
                    autoFocus
                    label={t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_IdPlaceholder')}
                    type="text"
                    value={chatRoomId}
                    minLimit="16"
                    isRoomId
                    onChange={(e: string) => handleChange(e.trim())}
                    required
                    tabIndex={CHARLIMIT.ONE}
                    maxLimit="16"
                    updateValidStatus={(isValid: boolean) => validatedForm('Id', isValid)} />
                </Form.Group>
              </div>
              <div className="call-to-action-wrap">
                <Button className="EnterChatRoomBtn cursor" disabled={errors.length > CHARLIMIT.ZERO && chatRoomId?.length < CHARLIMIT.SIXTEN} onClick={(e) => startChat(true, e)}>{t('Techcard_SpeechtoChat_DetailView_EnterChatRoom')}</Button>
                <div className="goBackLink" onClick={() => goBack()}>
                  <span className="link">{t('Techcard_SpeechtoChat_DetailView_GoBAckButton_Text')}</span>
                </div>
              </div>
            </Form>
          </div>
          <div className={`box-empty-state StartYourConversation ${(isNewChatRoom || isExistingChatRoom) ? '' : 'd-none'}`}>
            {(!isExistingChatRoom || isChatStart) && <ChatUserList transcript={transcript} setHideUser={setHideUser} hideUser={hideUser} userIds={userId} roomId={roomId} />}
            <div className={`placeholderBox cursor ${isNewChatRoom && !isChatStart ? '' : 'd-none'}`} onClick={() => startChat()}>
              <span className="outlineBox size45">
                <Image src={EnterExistingChatRoomPlaceholder} alt="Icon" />
              </span>
              <strong className="empty-title">{t('Techcard_SpeechtoChat_DetailView_ConversationStart_Help_Text')}</strong>
            </div>
            <ChatRoom isChatStart={isChatStart} transcript={transcript} hideUser={hideUser} userId={userId} isMute={isMute} />
            {(!isExistingChatRoom || isChatStart || isNewChatRoom) && <Card.Footer>
              <div className="action-left">
                <span className="title-action-btn langurageBtnDropDown">
                  <DropdownButton
                    show={showDropdown}
                    align="end"
                    title={language ? language : 'en-us-vosk-v0.22'}
                    id="dropdown-menu-align-end"
                    className="DropdownWithOverLay"
                    onToggle={(value) => handleToggle(value)}
                  >
                    <CustomScrollBar height={80}>
                      <div className="dropdwon-scroll speaker-dropdown">
                        {LanguageModal?.models?.map((modal: string) => (
                          <label className={`${language === modal ? 'checkbox-container active' : 'checkbox-container'}`} onClick={() => handleToggle(false, modal)} onKeyDown={(event) => event.key === 'Enter' && handleToggle(false, modal)} htmlFor={modal}>
                            <input id={modal} className="radio-custom" name="radio-group" checked={language === modal} type="radio" onChange={() => handleToggle(false, modal)} />
                            <span className="checkbox-txt">{modal}</span>
                            <span className="checkmark" />
                          </label>))}
                      </div>
                    </CustomScrollBar>
                  </DropdownButton>
                  <div className="DropdownBackdrop" />
                  <span className="mute-action">
                    {isMute ? <Button className="UnmuteBtn cursor" onClick={() => checkForSound()}>
                      <span className="icon">
                        <Image src={icMicBlue} alt="Icon" />
                      </span>
                      <span className="ic-txt">{t('Techcard_SpeechtoChat_DetailView_mute_Text')}</span>
                              </Button>
                      : <Button className="UnmuteBtn mic-blue-btn cursor" onClick={() => checkForUnmute()}>
                        <span className="icon">
                          <Image src={icMicMute} alt="Icon" />
                        </span>
                        <span className="ic-txt">{t('Techcard_SpeechtoChat_DetailView_Unmute_Text')}</span>
                        </Button>}
                  </span>
                </span>

              </div>
              <div className="action-right">
                <span className="actionBtn">
                  <Button className="ExitChatRoomBtn cursor" onClick={() => setConfrimationModal(true)}>{t('Techcard_SpeechtoChat_DetailView_ExitRoom_Text')}</Button>
                </span>
              </div>
                                                                      </Card.Footer>}
          </div>
        </Card>
      </Col>
      <Col xs="12">
        <p className="continuingTerms">
          <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
            By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
          </Trans>
        </p>
      </Col>
            </div>
    <ConfirmationModal
      title={t('Techcard_SpeechtoChat_DetailView_ExitRoom_Text')}
      text={t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_ConfirmationText')}
      noText={t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_Cancel')}
      yesText={t('Techcard_SpeechtoChat_DetailView_ExitRoom_Text')}
      close={setConfrimationModal}
      show={ShowConfirmationModal}
      confirm={ExitRoom} />
          </>
  );
};
