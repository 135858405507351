import React, { useEffect, useRef } from 'react';
import {
  Image,
} from 'react-bootstrap';
// import icWhiteTime from '../../assets/images/ic-white-time.svg';
import icTime from '../../assets/images/ic-time.svg';
import { MicinputOptions, messageInstance } from '../../Type/RTMN';

export const ChatRoom: React.FC<{
   messageArray: messageInstance[] | null, userCreatedForMicUse: MicinputOptions[] | null
  }> = ({
    messageArray, userCreatedForMicUse,
  }): React.ReactElement => {
    const scrollBar = useRef<any | never | unknown>();
    useEffect(() => {
      if (scrollBar.current) scrollBar.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, [messageArray, scrollBar]);
    return (
        <div className="meeting-items">
        <div className="meetingItemScroll">
            {messageArray?.length && (
              messageArray.map((item) => (
                userCreatedForMicUse?.find((ele) => ele.userId === item.userId)?.isDisabled === false && (
                <div className="itemRow" ref={scrollBar} key={item.timestamp} style={{ borderColor: userCreatedForMicUse?.find((ele) => ele.userId === item.userId)?.color }}>
                <div className="itemHeader">
                  <span className="title">{(userCreatedForMicUse?.find((ele) => ele.userId === item.userId)?.nameGiven || userCreatedForMicUse?.find((ele) => ele.userId === item.userId)?.label)}</span>
                  <span className="updated-time">
                    <span className="timeIcon">
                      <Image src={icTime} alt="Icon" />
                    </span>
                    <span className="updaterd-time-txt">{`${new Date(item.timestamp).toDateString()} ${new Date(item.timestamp).toTimeString()}`}</span>
                  </span>
                </div>
                <div className="itemBody">
                  <p className="itemBodyTxt">
                    {item.result}
                    {item.type === 'partial' && (<span className="d-block loader-wrap"><span className="smallDotedLoading"><div className="dot-flashing" /></span></span>)}
                  </p>
                </div>
                </div>
                )))
            )}
        </div>
        </div>
    );
  };
