import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Image,
  Container,
} from 'react-bootstrap';
import Carousel from 'react-simply-carousel';
import Logo1 from '../../assets/images/logo-01.svg';
import Logo2 from '../../assets/images/logo-02.svg';
import Logo3 from '../../assets/images/logo-03.svg';
import Logo4 from '../../assets/images/logo-04.svg';
import Logo5 from '../../assets/images/logo-05.svg';
import './companyLogoBanner.scss';

export const CompanyLogoBanner: React.FC = (): React.ReactElement => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const { t } = useTranslation();
  return (
  <section className="sectionBanner">
    <Container className="companiesGrowingContainer">
      <Row className="companiesGrowingContainerRow">
        <Col xs="12">
            <p className="growingBusinessTxt">
              {/* <Trans i18nKey="Techcard_DetailView_Companies_Overview" count={5}> */}
                {t('Companies_Overview.Techcard_DetailView_Companies_Overview_1')} <span className="highLight">{t('Companies_Overview.Techcard_DetailView_Companies_Overview_Highlight')}</span> {t('Companies_Overview.Techcard_DetailView_Companies_Overview_2')}
              {/* </Trans> */}
            </p>
        </Col>
        <Col xs="12" className="companiesNameContainer">
          <div className="slider-mobile-view">
          <Carousel
            autoplay
            activeSlideIndex={activeSlide}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              style: {
                display: 'none',
              },
            }}
            backwardBtnProps={{
              style: {
                display: 'none',
              },
            }}
            itemsToShow={2}
            easing="linear"
            infinite
            speed={5000}>
                  <Image src={Logo1} alt="Icon" key={1} />
                  <Image src={Logo2} alt="Icon" key={2} />
                <Image src={Logo3} alt="Icon" key={3} />
                <Image src={Logo4} alt="Icon" key={4} />
                <Image src={Logo5} alt="Icon" key={5} />
          </Carousel>
          </div>
          <ul className="list-unstyled slider-for-desktop-view">
            <li className="list-item">
              <Image src={Logo1} alt="Icon" />
            </li>
            <li className="list-item">
              <Image src={Logo2} alt="Icon" />
            </li>
            <li className="list-item">
              <Image src={Logo3} alt="Icon" />
            </li>
            <li className="list-item">
              <Image src={Logo4} alt="Icon" />
            </li>
            <li className="list-item">
              <Image src={Logo5} alt="Icon" />
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </section>
  );
};
