import React from 'react';
import {
  DropdownButton,
  Image,
  Dropdown,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import whitePause from '../../assets/images/ic-white-pause.svg';
import whitePlay from '../../assets/images/ic-white-play.svg';
import { addBodyClass } from '../../Utils/BodyClass';
import { ExampleAudioType } from '../../Type/VoiceAuthenticate';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';
import { useTypedSelector } from '../../Hooks/useTypeSelector';

export const EnrollTryExample: React.FC<{
  setExampleA: React.Dispatch<React.SetStateAction<string>>; exampleAudio: string; setExampleLabel: React.Dispatch<React.SetStateAction<string>>; exampleLabel: string; setReadyToRegister: React.Dispatch<React.SetStateAction<boolean>>; enrollAudio: ExampleAudioType[]; isPlay: boolean;
}> = ({
  setExampleA, exampleAudio, setExampleLabel, exampleLabel, setReadyToRegister, enrollAudio, isPlay,
}): React.ReactElement => {
  const appUrl: string = process.env.REACT_APP_URL as string;
  const [isAutoPlay, setAutoPlay] = React.useState(false);
  const [stopPlay, setStopPlay] = React.useState(false);
  const { t } = useTranslation();
  const [playAudioUrl, setPlayAudioUrl] = React.useState('');
  const [audioElements, setAudioElement] = React.useState<HTMLAudioElement|null>(null);
  const isLoading = useTypedSelector((state) => state.loader);

  const playAudio = (example: ExampleAudioType) => {
    if (!example.url) {
      audioElements?.pause();
      setAutoPlay(false);
    } else if (playAudioUrl !== example.url && audioElements) {
      audioElements.pause();
      audioElements.currentTime = 0;
      const audioElement = new Audio(`${appUrl}${example.url}`);
      audioElement.crossOrigin = 'anonymous';
      audioElement.play();
      setAudioElement(audioElement);
      setPlayAudioUrl(example?.url);
      setAutoPlay(true);
    } else if (!isAutoPlay) {
      const audioElement = new Audio(`${appUrl}${example?.url}`);
      audioElement.crossOrigin = 'anonymous';
      audioElement.play();
      setAudioElement(audioElement);
      setPlayAudioUrl(example?.url);
      setAutoPlay(true);
    } else if (audioElements) {
      audioElements.pause();
      audioElements.currentTime = 0;
      setAutoPlay(false);
    }
  };

  const selectExample = (example: ExampleAudioType) => {
    setExampleA(example.url);
    setExampleLabel(example?.label);
    setReadyToRegister(true);
  };
  React.useEffect(() => {
    const example = {
      label: '',
      url: '',
    };
    playAudio(example);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exampleAudio, isPlay, stopPlay]);

  const toggleDropdown = (value: boolean) => {
    setStopPlay(value);
    addBodyClass(value);
  };

  return (
      <span className={`${isLoading?.status ? 'disabled-btn' : ''} title-action-btn`}>
        <DropdownButton
          align="end"
          title={exampleLabel ? exampleLabel : t('Techcard_VoiceAuthentication_DetailView_Tab_TryExample')}
          id="dropdown-menu-align-end"
          className="DropdownWithOverLay"
          onToggle={(value) => { toggleDropdown(value); }}
        >
        <Dropdown.Header>{t('Techcard_VoiceAuthentication_DetailView_Tab_Examples')}</Dropdown.Header>
        <div className="dropdwon-scroll">
        <CustomScrollBar>

        {enrollAudio && enrollAudio?.map((example) => (
          <div className={`d-flex align-items-center hoverState ${exampleLabel === example.label ? 'active' : ''}`}>
          <span className="icon"><Image src={isAutoPlay && example.url === playAudioUrl ? whitePause : whitePlay} alt="Icon" onClick={() => { playAudio(example); }} /></span>
            <Dropdown.Item eventKey="1" onClick={() => { selectExample(example); }} key={example?.label}>
                <span className="d-block">
                <span className="icon-txt">{example?.label}</span>
                </span>
            </Dropdown.Item>
          </div>
        ))}
        </CustomScrollBar>
        </div>
        </DropdownButton>
        <div className="DropdownBackdrop" />
      </span>
  );
};
