/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import './audiBar.scss';
import WaveSurfer from 'wavesurfer.js';
import {
  Image,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SoundTrack from './sound-file.mp3';
import { WaveformContianer, Wave } from './Waveform';
import { CHARLIMIT } from '../../Constants/Number';
import SpinnerLoader from '../../assets/images/SpinnerLoader.svg';
import placeholderImage from '../../assets/images/realtime-speech-to-text-waves.svg';

export const AudioWavesBar: React.FC<{isPausePlay: boolean | null, audioFile: string, end?: () => void, setIndex: React.Dispatch<React.SetStateAction<any | null>>, playEnable: React.Dispatch<React.SetStateAction<boolean>>}> = ({
  isPausePlay = null, audioFile, playEnable, end, setIndex,
}): React.ReactElement => {
  const [slickChange, setSlickChange] = useState(0);
  const [state, setState] = useState(false);
  const [timer, setTimer] = useState('00:00');

  let audio: HTMLMediaElement = document.getElementById('audio') as HTMLMediaElement;
  let timeline: HTMLInputElement = document.querySelector('#timeline') as HTMLInputElement;
  let tempwaveform : WaveSurfer;
  const [waveform, setWaveForm] = useState<WaveSurfer | WaveSurfer>();
  const { t } = useTranslation();

  const handleSeekChange = () => {
    const time = (Number(timeline?.value) * Number(audio?.duration)) / 100;
    audio.currentTime = time;
    setSlickChange(time);
  };

  useEffect(() => {
    if (audioFile) {
      setState(false);
      audio = document.getElementById('audio') as HTMLMediaElement;
      audio.src = audioFile;
      audio.load();
      tempwaveform = WaveSurfer.create({
        barWidth: 5,
        barRadius: 3,
        cursorWidth: 10,
        container: '#waveform',
        interact: false,
        progressColor: '#25adfc',
        responsive: true,
        waveColor: 'rgba(37, 173, 252, 0.20)',
        cursorColor: 'rgba(37, 173, 252, 0)',
      });
      tempwaveform?.load(audioFile);
      setWaveForm(tempwaveform);
      audio.ontimeupdate = changeTimelinePosition;
      audio.onended = audioEnded;
    }
  }, [audioFile]);
  function audioEnded() {
    end && end();
  }
  useEffect(() => {
    if (waveform?.isReady) {
      playEnable(true);
      setState(true);
    }
  }, [waveform?.isReady]);

  function changeTimelinePosition() {
    timeline = document.querySelector('#timeline') as HTMLInputElement;
    const percentagePosition = (100 * Number(audio?.currentTime)) / Number(audio?.duration);
    percentagePosition && (setIndex(Math.floor((audio.currentTime) / CHARLIMIT.POINT_TWO_FIVE)));
    percentagePosition && (setSlickChange(percentagePosition));
    percentagePosition && (timeline.style.backgroundSize = `${percentagePosition}% 100%`);
    percentagePosition && (timeline.value = (percentagePosition as unknown as string));
    tempwaveform?.setCurrentTime(audio?.currentTime);
    const minutes = parseInt(String((audio.currentTime / 60) % 60), 10);
    const seconds = parseInt(String(audio.currentTime % 60), 10);
    const exactTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    setTimer(exactTime);
  }

  const toggleAudio = () => {
    if (audio) {
      if (audio.paused && isPausePlay) {
        audio.play();
        waveform?.play();
        waveform?.setMute(true);
      } else if (!isPausePlay) {
        waveform?.pause();
        audio.pause();
        waveform?.setMute(true);
      }
    }
  };

  useEffect(() => {
    if (isPausePlay !== null) {
      toggleAudio();
    }
  }, [isPausePlay]);

  return (
    <>
    <div className="visualizer-container">
    <div className="visualizer-container">
    {audioFile && !state && <div className="VoiceWaveFieldBox" id="microphone">
                    <Image src={placeholderImage} className="loaderwithPlaceholder" alt="Icon" />
                    <div className="summaryCardLoading">
                      <div className="loaderWrap">
                        <div className="loaderImg">
                          <Image src={SpinnerLoader} alt="Icon" width="24" height="24" />
                        </div>
                        <div className="spinner" />
                        <p>{t('Techcard_RealtimeSpeechtoText_DetailView_FileLoading')}</p>
                      </div>
                    </div>
                            </div>}
     <WaveformContianer>
       {audioFile && <Wave id="waveform" />}
        <audio preload="auto" id="audio" src={audioFile ? audioFile : SoundTrack} crossOrigin="anonymous" />
     </WaveformContianer>
    </div>
    </div>
      <div className="audio-player">
            <div className="controls">
          <input
            style={{ backgroundSize: `${slickChange}% 100%` }}
            type="range"
            title=""
            className="timeline"
            id="timeline"
            min="0"
            max="100"
            defaultValue={Math.floor(slickChange)}
            onChange={() => handleSeekChange()}
          />
        <span className="toolTipTimer" style={{ left: `calc( ${slickChange}%  - 23px)` }}>{ timer }</span>
            </div>
      </div>
    </>
  );
};
