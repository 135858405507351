import { PERMISSIONS } from '../actions/actionType';
import { PermissionsReducerType } from '../actionType/Permissions';
import { PermissionType } from '../../Type/Permission.d';
import { Err } from '../../Type/Error.d';

type State = {
    payload: string[] | PermissionType | Err;
    error: string | null;
  }
const initialState = {
  payload: [],
  error: null,
};
export const permissionsReducer = (state: State = initialState, action: PermissionsReducerType = {} as PermissionsReducerType):State => {
  if (action?.type === PERMISSIONS) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
