import { Err } from '../../Type/Error';
import { ExampleAudioLanguageStatusType, ExampleAudioStatusType, SendAudioPostStatusType } from '../../Type/Rtstt';
import { AUDIOEXAMPLE, AUDIOSEND, LANGUAGEMODAL } from '../actions/actionType';
import { RtsttAudioReducerType, RtsttExampleAudioReducerType, RtsttlanguageModalReducerType } from '../actionType/RealTimeTextChatMinutes';

type State = {
    payload: string[] | SendAudioPostStatusType | ExampleAudioStatusType | ExampleAudioLanguageStatusType | Err;
    error: string | null;
  }
const initialState = {
  payload: [],
  error: null,
};

export const RtsttAudioPostReducer = (state: State = initialState, action: RtsttAudioReducerType = {} as RtsttAudioReducerType):State => {
  if (action?.type === AUDIOSEND) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
export const RtsttLanguageModelReducer = (state: State = initialState, action: RtsttlanguageModalReducerType = {} as RtsttlanguageModalReducerType):State => {
  if (action?.type === LANGUAGEMODAL) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
export const RtsttAudioExampleReducer = (state: State = initialState, action: RtsttExampleAudioReducerType = {} as RtsttExampleAudioReducerType):State => {
  if (action?.type === AUDIOEXAMPLE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
