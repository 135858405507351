import i18next from 'i18next';
import { HIDE_LOADER, SHOW_LOADER } from '../actionType/loader';

export const showLoader = (text = i18next.t('Message.Loader_Message')) => ({
  type: SHOW_LOADER,
  payload: { status: true, text },
});

export const hideLoader = () => ({
  type: HIDE_LOADER,
  payload: { status: false },
});
