/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Col,
  Card,
  Image,
  Button,
  DropdownButton,
  Dropdown,
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Trans, useTranslation } from 'react-i18next';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { SED_OPTIONS, MIME_TYPE_AUDIO_ONLY } from '../../Constants/Static';
import { ConfirmationModal } from '../../Components/Modal/ConfirmationModal';
import './soundEventDetection.scss';
import '../../assets/scss/loader.scss';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { RecordAudioBar } from '../../Components/Audio/RecordAudioBar';
import { AudioProgressBar } from '../../Components/Audio/AudioProgressBar';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { Loader } from '../../Components/Loader/Loader';
import {
  CHARLIMIT, NUMBER, OVERVIEW_BANNER_CONTENT_TYPE, pageUrl,
} from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import placeholderSoundEvent from '../../assets/images/placeholderSoundEvent.svg';
import icWhiteUpload from '../../assets/images/ic-white-upload.svg';
import zoomIcon from '../../assets/images/zoom-icon.svg';
import albumCover from '../../assets/images/album-cover.svg';
import placeholderWavesSED from '../../assets/images/placeholderWavesSED.svg';
import musicForward from '../../assets/images/music-forward.svg';
import musicPause from '../../assets/images/music-pause.svg';
import musicBackward from '../../assets/images/music-backward.svg';
import IcPlay from '../../assets/images/Icon--Play.svg';
import '../../assets/scss/smallLoader.scss';
import { addBodyClass } from '../../Utils/BodyClass';
import { scrollUp } from '../../Utils/ScrollTo';
import {
  secondsToHHMMSS, bytesToSize, createFile, getUrlFileType, waitFotTimeBeforeRetry,
  floatFixedNToDigit, prepareDataForHighlight, getAudioVideoDuration,
} from '../../Utils/DevHelpers';
import { SEDUploadFile, SEDResultOFInput, SEDExampleApp } from '../../Redux/actions/soundEventDetection';
import { showLoader, hideLoader } from '../../Redux/actions/loader';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { SEDExampleAudioResponse, SEDUploadedAudioFileResult, SEDResponseForResult } from '../../Type/Rtstt';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { SuccessModal } from '../../Components/Modal/SuccessModal';
import reviewImage from '../../assets/images/IconMicBlocked.svg';

export const SoundEventDetection: React.FC = (): React.ReactElement => {
  const [slickChangeOnPage, setSlickChangeOnPage] = useState(0);
  const [numberOfBars, setNumberOfBars] = useState(0);
  const [currentIndexResult, setCurrentIndexResult] = useState(0);
  const [showZoom, setShowZoom] = useState(true);
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);
  const [startRecoeding, setStartRecording] = React.useState(false);
  const [optionChoosed, setOptionChoosed] = useState(SED_OPTIONS.notSelected);
  const [file, setFile] = useState<any | null>(null);
  const [fileNameToshow, setFileNameToshow] = useState<string>('');
  const [audioBuffer, setAudioBuffer] = useState<any | null>(null);
  const [exampleData, setExampleData] = useState<any[]>([]);
  const [isPausePlay, setPausePlay] = React.useState<boolean>(false);
  const [sedApiRes, setSedApiRes] = useState<any | null>(null);
  const [regionColorMap, setRegionColorMap] = useState<any | null>(null);
  const [showError, setShowError] = useState(false);
  const [sendBlobCheck, setSendBlobCheck] = useState(true);
  const [success, setSuccess] = React.useState(false);
  const { t } = useTranslation();

  const setConfirm = (input: boolean) => {
    if (input) {
      setConfrimationModal(false);
      startOver();
    }
  };

  const startOver = () => {
    setSendBlobCheck(false);
    setOptionChoosed(SED_OPTIONS.notSelected);
    setSlickChangeOnPage(0);
    if (startRecoeding) startVoiceRecoed(true);
    setFile(null);
    setFileNameToshow('');
    setAudioBuffer(null);
    setPausePlay(false);
    setSedApiRes(null);
    setCurrentIndexResult(0);
    setNumberOfBars(0);
    setRegionColorMap(null);
  };

  const handlePausePlay = () => {
    setPausePlay(!isPausePlay);
  };

  const handleZoom = () => {
    if (numberOfBars < 100) {
      const setNumber = numberOfBars + 10 >= 100 ? 100 : numberOfBars + 10;
      setNumberOfBars(setNumber);
    }
  };

  const getAudioAndTimeline = () => {
    const audio: HTMLMediaElement = document.getElementById('audio') as HTMLMediaElement;
    const timeline: HTMLInputElement = document.querySelector('#timeline') as HTMLInputElement;
    return { audio, timeline };
  };

  const handleSeekChange = () => {
    const { audio, timeline } = getAudioAndTimeline();
    const time = (Number(timeline?.value) * Number(audio?.duration)) / 100;
    audio.currentTime = time;
    setSlickChangeOnPage(time);
  };

  const changeByTenSec = (changeBy: number) => {
    const { audio, timeline } = getAudioAndTimeline();
    const time = (Number(timeline?.value) * Number(audio?.duration)) / 100;
    const duration = audio.duration;
    if (changeBy === 10) {
      const change = (duration > 10) ? time + 10 : duration;
      setSlickChangeOnPage(change);
      audio.currentTime = change;
      const percentagePosition = (100 * Number(audio?.currentTime)) / Number(audio?.duration);
      percentagePosition && (timeline.style.backgroundSize = `${percentagePosition}% 100%`);
      percentagePosition && (timeline.value = (percentagePosition as unknown as string));
    } else {
      const change = time > 10 ? time - 10 : 0;
      setSlickChangeOnPage(change);
      audio.currentTime = change;
      const percentagePosition = (100 * Number(audio?.currentTime)) / Number(audio?.duration);
      percentagePosition && (timeline.style.backgroundSize = `${percentagePosition}% 100%`);
      percentagePosition && (timeline.value = (percentagePosition as unknown as string));
    }
  };

  const isLoading = useTypedSelector((state) => state.loader);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // eslint-disable-next-line
    (async function () {
      try {
        const example = await dispatch(SEDExampleApp()) as SEDExampleAudioResponse;
        setExampleData(example?.data);
      } catch (err) {
        // eslint-disable-next-line
        console.log('[err]', err);
      }
    }());
    // eslint-disable-next-line
  }, []);

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const duration = await getAudioVideoDuration(e.target.files[0]);
    if (duration && duration <= NUMBER.DURATION_LIMIT) {
      const blob = URL.createObjectURL(e.target.files[0]);
      setFile(blob);
      setFileNameToshow(e.target.files[0]?.name);
      //this is to handle the view of upload
      setOptionChoosed(SED_OPTIONS.uploadRecording);
      sendRecording(e.target.files[0]);
    } else {
      setShowError(true);
    }
  };

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    recordingTime,
    isRecording,
  } = useAudioRecorder();

  const getMedia = async () => {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return stream?.id;
    } catch (err) {
      return false;
    }
  };

  const startVoiceRecoed = async (check?: boolean) => {
    const checkForMedia: any = await getMedia();
    setShowError(false);
    try {
      if (!startRecoeding && checkForMedia) {
        await startRecording();
        setStartRecording(true);
        setSendBlobCheck(true);
      } else if (startRecoeding) {
        await stopRecording();
        setStartRecording(false);
        if (check) setOptionChoosed(SED_OPTIONS.notSelected);
      } else setSuccess(true);
    } catch (err) {
    // eslint-disable-next-line
      console.log('[err]', err);
    }
  };

  const handleExample = async (hUrl: string) => {
    setShowError(false);
    try {
      dispatch(showLoader());
      const type = getUrlFileType(hUrl);
      const data = await createFile(hUrl, type, true);
      if (data) {
        dispatch(hideLoader());
        setOptionChoosed(SED_OPTIONS.example);
        sendRecording(data);
        setFileNameToshow(`${Date.now()}-example.mp3`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log('[err]', err);
    }
  };

  const fnEndAudio = () => {
    setPausePlay(false);
  };

  useEffect(() => {
    if (recordingBlob && sendBlobCheck) {
      setFileNameToshow(`${Date.now()}-reconding.wav`);
      sendRecording(recordingBlob);
      setOptionChoosed(SED_OPTIONS.soundRecorded);
    }
    // eslint-disable-next-line
  }, [recordingBlob]);

  const getResultFromAPIQuery = async (filename: string, model: string, retryCount = 0): Promise<any> => {
    try {
      const res = await dispatch(SEDResultOFInput(filename, model)) as SEDResponseForResult;
      if (!res?.status) {
        if (retryCount > 10) {
          throw new Error('Server is not responding');
        }
        await waitFotTimeBeforeRetry(5000);
        return await getResultFromAPIQuery(filename, model, retryCount + 1);
      }
      return res;
    } catch (err) {
      // eslint-disable-next-line
      console.log('error getting result', err);
    }
  };

  const sendRecording = async (fileVal: File | Blob) => {
    const lastModified = Date.now();
    const fileA = new File([fileVal], `${lastModified}-recording.wav`, {
      type: 'audio/x-raw',
      lastModified,
    });
    setFile(fileVal);
    const reader = new FileReader();
    reader.readAsDataURL(fileVal);
    // eslint-disable-next-line
    reader.onloadend = function () {
      const buffer = reader.result;
      setAudioBuffer(buffer);
    };
    const formData: FormData = new FormData();
    formData.append('audio', fileA);
    try {
      const res = await dispatch(SEDUploadFile(formData)) as SEDUploadedAudioFileResult;
      if (res?.status) {
        const result = await getResultFromAPIQuery(res.filename, 'v1') as SEDResponseForResult;
        if (result.status) {
          setSedApiRes(result.data);
          const data = prepareDataForHighlight(result.data);
          setRegionColorMap(data);
        }
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log('uploading Error', err);
    }
  };

  React.useEffect(() => {
    scrollUp();
  }, []);

  const waveform = useRef<any | never | unknown>();
  React.useEffect(() => {
    if (!isRecording) {
      waveform.current?.microphone.stop();
      waveform.current?.microphone.destroy();
      waveform.current = null;
    }
  }, [isRecording]);
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
     {fileNameToshow}
    </Tooltip>
  );

  const sussceeReviewObject = {
    heading: t('Mic_Disabled.Title'),
    description: t('Mic_Disabled.Desc'),
    button_text: t('Mic_Disabled.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClick = () => {
    setSuccess(false);
  };

  return (
        <>
        <Container className="AutoSummarizationContainer SoundEventDetectionContainer">
          <BreadcrumbBar name={t('Breadcrumb.SoundEventDetectionName')} url={pageUrl.home} />
          <h1>
            <span>{t('Techcard_SoundEventDetection_Title')}</span>
            <span className="btnDemo">{t('Techcard_SoundEventDetection_ButtonText')}</span>
          </h1>
          <p className="heading-txt">
            {t('Techcard_SoundEventDetection_DetailView_Desc')}
            <br />
            {t('Techcard_SoundEventDetection_DetailView_Desc2')}
          </p>
          <div className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="12" lg="12">
              <Card className={`SoundEventDetection ${optionChoosed === SED_OPTIONS.recordSound ? 'd-none' : ''}`}>
              <Loader />
              { (!isLoading || !isLoading.status) && (
              <>
                <div className={`${optionChoosed === SED_OPTIONS.notSelected ? '' : 'd-none'} box-empty-state SoundEventDetectionEmptyBox`}>
                  <span className="empty-state-Img">
                    <Image src={placeholderSoundEvent} alt="Icon" />
                  </span>
                  <strong className="empty-title">{t('Techcard_SoundEventDetection_DetailView_Title_2')}</strong>
                  <p className="empty-txt">{t('Techcard_SoundEventDetection_DetailView_Sub_Title')}</p>
                  <span className="call-to-action-wrap">
                    <span className="title-action-btn">
                      <DropdownButton
                        align="end"
                        title={t('Techcard_SoundEventDetection_DetailView_Dropdown_TryExample_Label')}
                        id="dropdown-menu-align-end"
                        className="DropdownWithOverLay"
                        onToggle={(value) => { addBodyClass(value); }}
                        >
                        <Dropdown.Header>{t('Techcard_SoundEventDetection_DetailView_Dropdown_Example_Label')}</Dropdown.Header>
                        <div className="dropdwon-scroll">
                          {exampleData.length && (
                            exampleData.map((item, index) => (
                              <Dropdown.Item key={item?.url} eventKey={index} onClick={() => handleExample(`${process.env.REACT_APP_URL}${item.url}`)}>
                              <span className="d-block">
                                <span className="icon-txt">{item?.label}</span>
                              </span>
                              </Dropdown.Item>
                            ))
                          )}
                        </div>
                      </DropdownButton>
                      <div className="DropdownBackdrop" />
                    </span>
                    <span className="upload-action-btn">
                      <div className="customUploadBtn">
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <Image src={icWhiteUpload} alt="Icon" />
                          <span className="uploadTxt">{t('Techcard_SoundEventDetection_DetailView_Tab_UploadFile')}</span>
                          <input id="file-upload" type="file" accept={MIME_TYPE_AUDIO_ONLY} onChange={(e) => onFileInputChange(e)} />
                        </label>
                      </div>
                    </span>
                    <span className="RecordSoundWrap">
                      <Button className="RecordSoundBtn" onClick={() => setOptionChoosed(SED_OPTIONS.recordSound)}>{t('Techcard_SoundEventDetection_DetailView_Tab_Record_Sound')}</Button>
                    </span>
                  </span>
                </div>
                {showError && <div className="uploaded-file-row--error statusFailed">
                                <div className="uploaded-file-content">
                                  <span className="fileName">{t('Message.file_duration_Three_min')}</span>
                                </div>
                              </div>}
                <div className={`SoundEventDetectionContentBox ${(optionChoosed === SED_OPTIONS.example || optionChoosed === SED_OPTIONS.uploadRecording || optionChoosed === SED_OPTIONS.soundRecorded) ? '' : 'd-none'}`}>
                  <div className="waveBox">
                  <AudioProgressBar
                    zoomShow={setShowZoom}
                    isPausePlay={isPausePlay}
                    audioFile={audioBuffer}
                    positionOfSlick={setSlickChangeOnPage}
                    numberOfBars={numberOfBars}
                    setIndex={setCurrentIndexResult}
                    end={fnEndAudio}
                    regionColorMap={regionColorMap}
                  />
                  </div>
                  <div className="wave-action-group">
                    <div className="select-any-option">
                      <label className="checkbox-container HumanVoice" htmlFor="HumanVoice">
                        <input
                          id="HumanVoice"
                          className="radio-custom"
                          name="radio-group"
                          type="checkbox"
                        />
                        <span className="checkmark" />
                        <span className="checkbox-txt">{t('Techcard_SoundEventDetection_DetailView_Tab1_Voice')}</span>
                      </label>
                      <label className="checkbox-container Noise" htmlFor="Noise">
                        <input
                          id="Noise"
                          className="radio-custom"
                          name="radio-group"
                          type="checkbox"
                        />
                        <span className="checkmark" />
                        <span className="checkbox-txt">{t('Techcard_SoundEventDetection_DetailView_Tab2_Noise')}</span>
                      </label>
                      <label className="checkbox-container BothVoice" htmlFor="Both (Human voice and noise)">
                        <input
                          id="Both-Human-voice-and-noise"
                          className="radio-custom"
                          name="radio-group"
                          type="checkbox"
                        />
                        <span className="checkmark" />
                        <span className="checkbox-txt">{t('Techcard_SoundEventDetection_DetailView_Tab3_Both_Voice_Noise')}</span>
                      </label>
                    </div>
                    <div className={`wave-controls ${showZoom ? '' : 'd-none'}`}>
                      <span className="wave-range-control">
                             <input
                               style={{ backgroundSize: `${numberOfBars}% 100%` }}
                               type="range"
                               className="timeline"
                               id="zoonBarIcon"
                               min={0}
                               max={100}
                               value={numberOfBars}
                               onChange={(e) => setNumberOfBars(Number(e.target.value))} />
                      </span>
                      <span className="zoomIcon" onClick={() => handleZoom()}><Image src={zoomIcon} alt="Icon" /></span>
                    </div>
                  </div>
                  <div className="wave-data-label">
                    <div className="btn-w-50">
                      <span className="CalucatingHumanVoiceBtn active1">
                      {sedApiRes ? (
                    <>
                        <span className="btn-txt-small">{t('Techcard_SoundEventDetection_DetailView_Tab1_Voice')}</span>
                        <span className="btn-txt-big">{sedApiRes[1][currentIndexResult] ? floatFixedNToDigit(sedApiRes[1][currentIndexResult] * 100, 2) : 0} %</span>
                        <ProgressBar now={Number(`${floatFixedNToDigit(sedApiRes[1][currentIndexResult] * 100, 0)}`)} />
                        <span style={{ width: `${floatFixedNToDigit(sedApiRes[1][currentIndexResult] * 100, 0)}%` }} className="progressBarShadow" />
                    </>) : (
                      <>
                       <span className="btn-txt-small">{t('Techcard_SoundEventDetection_DetailView_Tab2_CalculatinHumanVoice')}</span>
                       <span className="btn-txt-big">0 %</span>
                      </>
                      )}
                      </span>
                    </div>
                    <div className="btn-w-50">
                      <span className="CalucatingNoiseBtn active1">
                      {sedApiRes ? (
                    <>
                        <span className="btn-txt-small">{t('Techcard_SoundEventDetection_DetailView_Tab2_Noise')}</span>
                        <span className="btn-txt-big">{sedApiRes[2][currentIndexResult] ? floatFixedNToDigit(sedApiRes[2][currentIndexResult] * 100, 2) : 0} %</span>
                        <ProgressBar now={Number(`${floatFixedNToDigit(sedApiRes[2][currentIndexResult] * 100, 0)}`)} />
                              <span style={{ width: `${floatFixedNToDigit(sedApiRes[2][currentIndexResult] * 100, 0)}%` }} className="progressBarShadow" />
                    </>) : (
                      <>
                       <span className="btn-txt-small">{t('Techcard_SoundEventDetection_DetailView_Tab2_CalculatingNoise')}</span>
                       <span className="btn-txt-big">0 %</span>
                      </>
                      )}
                      </span>
                      <span className="NoiseNotFoundBtn d-none">
                        <span className="btn-txt-small">{t('Techcard_SoundEventDetection_DetailView_Tab2_Noise')}</span>
                        <span className="btn-txt-big">{t('Techcard_SoundEventDetection_DetailView_Tab2_Noise_Voice_NotFound')}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <Card.Footer className={`SoundEventDetection-custom-card-footer ${(optionChoosed === SED_OPTIONS.example || optionChoosed === SED_OPTIONS.uploadRecording || optionChoosed === SED_OPTIONS.soundRecorded) ? '' : 'd-none'}`}>
                  <span className="toolTipTimer" style={{ left: `calc( ${slickChangeOnPage}%  - 23px)` }} />
                  <div className="sound-row">
                    <div className="sound-left-col">
                      <div className="sound-details">
                        <span className="sound-detail-placeholder">
                          <Image src={albumCover} alt="Icon" />
                        </span>
                        <span className="detail-txt">
                        {file && <OverlayTrigger
                          placement="right"
                          delay={{ show: 200, hide: 100 }}
                          overlay={renderTooltip}
                          >
                            <span className="sound-name">{fileNameToshow.slice(0, CHARLIMIT.FIFTEEN) + (fileNameToshow.length > CHARLIMIT.FIFTEEN ? '...' : '')}</span>
                                 </OverlayTrigger>
}
                          <span className="sound-format">{file?.type} ({bytesToSize(file?.size)})</span>
                        </span>
                      </div>
                    </div>
                    <input
                      style={{ backgroundSize: `${slickChangeOnPage}% 100%` }}
                      type="range"
                      className="timeline"
                      id="timeline"
                      min="0"
                      max="100"
                      defaultValue={slickChangeOnPage}
                      onChange={() => handleSeekChange()}
                    />
                    <div className="sound-center-col">
                      <span className="music-forward" onClick={() => changeByTenSec(-10)}>
                        <Image src={musicBackward} alt="Icon" />
                      </span>
                      <span className="music-pause" onClick={() => handlePausePlay()}>
                        <Image src={isPausePlay ? musicPause : IcPlay} alt="Icon" />
                      </span>
                      <span className="music-backward" onClick={() => changeByTenSec(10)}>
                        <Image src={musicForward} alt="Icon" />
                      </span>
                    </div>
                    <div className="sound-right-col">
                      <span className="RecordSoundWrap"><Button className="StartOverBtn" onClick={() => startOver()}>{t('Techcard_SoundEventDetection_DetailView_Tab_StartOver')}</Button></span>
                    </div>
                  </div>
                </Card.Footer>
              </>
              )}
              </Card>
              <Card className={`SoundEventDetection RecordSound ${optionChoosed === SED_OPTIONS.recordSound ? '' : 'd-none'}`}>
              { (!isLoading || !isLoading.status) && (
                <>
                <div className="SoundEventDetectionContentBox">
                  <strong className="RecordSound-title">{t('Techcard_SoundEventDetection_DetailView_Tab2_Record_Sound')}</strong>
                  <p className="RecordSound-txt">{t('Techcard_SoundEventDetection_DetailView_Record_Sound_sub_Head')}</p>
                  <div className="waveBox">
                  {/* <div className={startRecoeding ? 'grey-box audio' : 'grey-box box-center'}> */}
                  {startRecoeding ? <RecordAudioBar startRecoeding={startRecoeding} waveform={waveform} />
                    : <div className="waves-placeholder"><Image src={placeholderWavesSED} alt="Icon" className="d-none" /></div>
                  }
                  {/* </div> */}
                    <span className="recordTime">{t('Techcard_SoundEventDetection_DetailView_RecordinTime')}: {recordingTime ? secondsToHHMMSS(recordingTime) : '00:00:00'}</span>
                  </div>
                </div>
                <Card.Footer>
                  <Button className="clearBtn cursor" onClick={() => setConfrimationModal(true)}>{t('Techcard_SoundEventDetection_DetailView_Tab2_CancelRecording')}</Button>
                  <Card.Link className="submitBtn cursor" onClick={() => startVoiceRecoed()}>{startRecoeding ? t('Techcard_SoundEventDetection_DetailView_Tab1_SaveRecording_Test') : t('Techcard_SoundEventDetection_DetailView_Tab1_StartRecording')}</Card.Link>
                </Card.Footer>
                </>
              )}
              </Card>
            </Col>
            <Col xs="12">
            <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
            </p>
            </Col>
          </div>

          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.sound_event_detection} type="soundeventdetections" />
        </Container>
        <CompanyLogoBanner />

    <ConfirmationModal
      close={setConfrimationModal}
      show={ShowConfirmationModal}
      confirm={setConfirm}
      title={t('confirmationModalOfSed.title')}
      text={t('confirmationModalOfSed.text')}
      noText={t('confirmationModalOfSed.noText')}
      yesText={t('confirmationModalOfSed.yesText')}
    />
    <SuccessModal
      showSuccess={success}
      Successclose={setSuccess}
      messageContent={sussceeReviewObject}
      buttonAction={successButtonClick}
      imagefile={reviewImage} />

        </>
  );
};
