//This file contains regular expressions used in the application for validation.
export const EMAIL_REGEX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9.,$;]{2,15}$/;

export const PASSWORD_REGEX = /^(?=.*[a-zA-z])(?=.*\d)(?=.*[@$!%*?+&~`#^_=|"':;,.()-])[A-Za-z\d@$!%*?&~`#^_=|"':;,.()-].*$/;

export const ACCEPT_NUMBER_HYPHEN_CHAR_REGEX = '^[A-Za-z0-9\\-\\s]+$';

export const ONLY_DIGIT_REGEX = '^[0-9]*$';

export const PASSWORD = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const PHONE = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const STRING_CHECK = /[^\s]+/;

export const WEBSITE = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const ADD_HTTP = /^(?:f|ht)tps?\\:\/\//;

export const NUMBER_ONLY = /^[0-9]+$/;

export const NUMBER_ONLY_WITH_DECIMAL = /^\d{0,10}(\.\d{0,2})?$/;

export const NUMBER_ONLY_WITH_PLUS = /^(?:[+\d].*\d|\d)$/;

export const NUMBER_CHARACTERS_ONLY = /^[a-zA-Z0-9]*$/;
