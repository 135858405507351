/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import WaveSurfer from 'wavesurfer.js';
import { WaveformContianer, Wave } from './Waveform';
import { NUMBER } from '../../Constants/Number';
import './audiBar.scss';
import SoundTrack from './sound-file.mp3';

export const AudioBar: React.FC<{ isMute: boolean | null, isPausePlay: boolean | null, audioFile: string, end?: () => void, mute: React.Dispatch<React.SetStateAction<boolean | null>>}> = ({
  isMute, isPausePlay = null, audioFile, end, mute,
}): React.ReactElement => {
  const [slickChange, setSlickChange] = useState(0);
  const [soundButton, setSoundButton] = useState(false);
  const [timer, setTimer] = useState('00 : 00');
  const [volume, setVolume] = useState(10);
  let audio: HTMLMediaElement = document.getElementById('audio') as HTMLMediaElement;
  let tempwaveform : WaveSurfer;
  const [waveform, setWaveForm] = useState<WaveSurfer | WaveSurfer>();
  let timeline: HTMLInputElement = document.querySelector('#timeline') as HTMLInputElement;
  const toggleAudio = () => {
    if (audio) {
      if (audio.paused) {
        audio.play();
        waveform?.play();
      } else {
        audio.pause();
        waveform?.pause();
      }
    }
  };
  function changeTimelinePosition() {
    const percentagePosition = (100 * Number(audio?.currentTime)) / Number(audio?.duration);
    percentagePosition && (setSlickChange(percentagePosition));
    percentagePosition && (timeline.style.backgroundSize = `${percentagePosition}% 100%`);
    percentagePosition && (timeline.value = (percentagePosition as unknown as string));
    const minutes = parseInt(String((audio.currentTime / 60) % 60), 10);
    const seconds = parseInt(String(audio.currentTime % 60), 10);
    const exactTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    waveform?.setMute(true);
    waveform?.setCurrentTime(audio?.currentTime);
    setTimer(exactTime);
  }
  function audioEnded() {
    end && end();
  }
  function changeSeek() {
    const time = (Number(timeline?.value) * Number(audio?.duration)) / 100;
    audio.currentTime = time;
    waveform?.setCurrentTime(time);
    setSlickChange(time);
  }
  function toggleSound() {
    // audio.muted = !audio.muted;
    setSoundButton(!soundButton);
  }
  function volumeSeekChange(vol: string) {
    const volumeTemp = Number(vol);
    setVolume(volumeTemp);
    audio.volume = volumeTemp / 10;
  }

  useEffect(() => {
    (async function () {
      if (audioFile) {
        end && end();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audio = document.getElementById('audio') as HTMLMediaElement;
        audio.src = audioFile;
        tempwaveform = WaveSurfer.create({
          barWidth: 5,
          barRadius: 5,
          cursorWidth: 10,
          container: '#waveform',
          backend: 'WebAudio',
          height: 202,
          progressColor: '#25adfc',
          responsive: true,
          waveColor: 'rgba(37, 173, 252, 0.20)',
          cursorColor: 'rgba(37, 173, 252, 0)',
        });
        tempwaveform?.setMute(true);
        tempwaveform?.load(audioFile);
        setWaveForm(tempwaveform);
        audio.load();
        audio.ontimeupdate = changeTimelinePosition;
        audio.onended = audioEnded;
        timeline = document.querySelector('#timeline') as HTMLInputElement;
      }
    }());
  }, [audioFile]);

  useEffect(() => {
    if (isMute !== null) { toggleSound(); }
    // eslint-disable-next-line
    }, [isMute]);

  useEffect(() => {
    if (isPausePlay !== null) {
      toggleAudio();
    }
    // eslint-disable-next-line
    }, [isPausePlay]);

  const debounces = <F extends ((...args: any) => any)>(waitFor: number, func: F) => {
    const timeout = 0;

    const debounced = (...args: any) => {
      clearTimeout(timeout);
      setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
  };

  return (
    <>
    <div className="visualizer-container">
    <WaveformContianer>
        <Wave id="waveform" />
    </WaveformContianer>
    </div>
      <div className="audio-player">
      <audio id="audio" src={audioFile ? audioFile : SoundTrack} />
        <div className="controls">
          <input
            style={{ backgroundSize: `${slickChange}% 100%` }}
            type="range"
            title=""
            className="timeline"
            id="timeline"
            min="0"
            max="100"
            defaultValue={slickChange}
            onChange={() => {
              changeSeek();
            }}
          />
          <span className="toolTipTimer" style={{ left: `calc( ${slickChange}%  - 23px)` }}>{ timer }</span>
          <Button className="sound-button d-none" onClick={() => toggleSound()} />
        </div>
        {soundButton && <div className="volume-control">
          <span className="volume-control--Box">
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              style={{ backgroundSize: `${volume * 10}% 100%` }}
              type="range"
              id="volume-bar"
              title="volume"
              min="0"
              max="10"
              step="1"
              className="timeline"
              defaultValue={volume}
              onBlur={() => { debounces(NUMBER.HUNDRED, mute)(!isMute); }}
              onChange={(e) => {
                volumeSeekChange(e?.target?.value);
              }}
            />
          </span>
                        </div>}
      </div>
    </>
  );
};
