/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CHARLIMIT } from '../../Constants/Number';

export const CustomScrollBar: React.FC<any> = (props): React.ReactElement => {
  const renderThumb = () => (<div style={{
    borderRadius: CHARLIMIT.THREE,
    backgroundColor: 'rgb(82 98 108 / 10%)',
    width: CHARLIMIT.THREE,
  }} />
  );

  return (
    <Scrollbars
      renderThumbVertical={renderThumb}
      thumbMinSize={CHARLIMIT.THREE}
      style={{ height: props?.height ? props?.height : 229 }}
      {...props}
    />
  );
};
