import React from 'react';
import { Image } from 'react-bootstrap';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import SpinnerLoader from '../../assets/images/SpinnerLoader.svg';

export const Loader:React.FC = (): React.ReactElement => {
  const isLoading = useTypedSelector((state) => state.loader);
  return (
    isLoading?.status && (
    <div className="summaryCardLoading">
        <div className="loaderWrap">
          <div className="loaderImg">
            <Image src={SpinnerLoader} alt="Icon" width="80" height="80" />
          </div>
          <div className="spinner" />
          <p className="text-center">{isLoading?.text}</p>
        </div>
    </div>)
  );
};
