import { Err } from '../../Type/Error';
import { changePasswordStatusType, profileUpdateStatusType, viewProfileStatusType } from '../../Type/Profile';
import { PROFILE } from '../actions/actionType';
import {
  changePasswordReducerType, deleteAccountReducerType, profileUpdateReducerType, viewProfileReducerType,
} from '../actionType/Profile';

type State = {
    payload: string[] | viewProfileStatusType | profileUpdateStatusType | changePasswordStatusType | Err;
    error: string | null;
  }
const initialState = {
  payload: [],
  error: null,
};

export const viewProfileReducer = (state: State = initialState, action: viewProfileReducerType = {} as viewProfileReducerType):State => {
  if (action?.type === PROFILE.VIEW_PROFILE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const ProfileUpdateReducer = (state: State = initialState, action: profileUpdateReducerType = {} as profileUpdateReducerType):State => {
  if (action?.type === PROFILE.EDIT_PROFILE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const changePasswordReducer = (state: State = initialState, action: changePasswordReducerType = {} as changePasswordReducerType):State => {
  if (action?.type === PROFILE.CHANGE_PASSWORD) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const deleteAccountReducer = (state: State = initialState, action: deleteAccountReducerType = {} as deleteAccountReducerType):State => {
  if (action?.type === PROFILE.DELETE_ACCOUNT) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
