import {
  ExampleSentenceReducerType, ExampleAudioReducerType, EnrollAudioReducerType, OpenSseReducerType, AuthAudioReducerType,
} from '../actionType/Va';
import { VOICE_AUTHENTICATION, SSE } from '../actions/actionType';
import {
  ExampleSentenceType, ExampleAudioType, EnrollAudioType, SseType, AuthAudioType,
} from '../../Type/VoiceAuthenticate';

type State = {
    payload: string[] | ExampleSentenceType | ExampleAudioType | EnrollAudioType | SseType | AuthAudioType;
    error: string | null;
  };
const initialState = {
  payload: [],
  error: null,
};

export const ExampleSentenceReducer = (state: State = initialState, action: ExampleSentenceReducerType = {} as ExampleSentenceReducerType):State => {
  if (action?.type === VOICE_AUTHENTICATION.EXAMPLE_SENTENCE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const ExampleAudioReducer = (state: State = initialState, action: ExampleAudioReducerType = {} as ExampleAudioReducerType):State => {
  if (action?.type === VOICE_AUTHENTICATION.EXAMPLE_AUDIO) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const EnrollAudioReducer = (state: State = initialState, action: EnrollAudioReducerType = {} as EnrollAudioReducerType):State => {
  if (action?.type === VOICE_AUTHENTICATION.EXAMPLE_AUDIO) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const sseReducer = (state: State = initialState, action: OpenSseReducerType = {} as OpenSseReducerType):State => {
  if (action?.type === SSE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const AuthReducer = (state: State = initialState, action: AuthAudioReducerType = {} as AuthAudioReducerType):State => {
  if (action?.type === VOICE_AUTHENTICATION.AUTH_AUDIO) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
