/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Card,
  Image,
  Dropdown,
  DropdownButton,
  Button,
  OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
//import audioBufferToWav from 'audiobuffer-to-wav';
import { Trans, useTranslation } from 'react-i18next';
import { useAudioRecorder } from '../../realtimeAudioChunck';
import { ConfirmationModal } from '../Modal/ConfirmationModal';
import './realTimeSpeechTOText.scss';
import '../../assets/scss/loader.scss';
import { eventAPI } from '../../Constants/Api';
import TranscriptPlaceholder from '../../assets/images/TranscriptPlaceholder.svg';
import placeholderImage from '../../assets/images/realtime-speech-to-text-waves.svg';
import waves from '../../assets/images/waves.svg';
import '../../assets/scss/smallLoader.scss';
import { addBodyClass } from '../../Utils/BodyClass';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';
import icUpload from '../../assets/images/ic-upload.svg';
import icMicBlue from '../../assets/images/mic-small-icon.svg';
import musicPause from '../../assets/images/music-pause.svg';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { sendAudioApp, RtsttAudioExampleApp, RtsttLanguageExampleApp } from '../../Redux/actions/Realtimetextchatminutes';
import IcPlay from '../../assets/images/Icon--Play.svg';
import { showLoader, hideLoader } from '../../Redux/actions/loader';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { ExampleAudioLanguageStatusType, ExampleAudioStatusType, transscirptType } from '../../Type/Rtstt';
import { RecordAudioBar } from '../Audio/RecordAudioBar';
import { getLocalItem } from '../../Utils/LocalStorage';
import {
  CraeteId, createFile, getUrlFileType, getAudioVideoDuration, getFileTypeAudioOrVideo,
} from '../../Utils/DevHelpers';
import { CHARLIMIT, NUMBER } from '../../Constants/Number';
import { AudioBufferSlice } from '../../Utils/RealTimeChunks';
import { AudioWavesBar } from '../Audio/AudioWavesBar';
import { MIME_TYPE } from '../../Constants/Static';
import reviewImage from '../../assets/images/IconMicBlocked.svg';
import { SuccessModal } from '../Modal/SuccessModal';

export const RealTimeSpeechTOTexts: React.FC = (): React.ReactElement => {
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);
  const [currentIndexResult, setCurrentIndexResult] = useState(-1);
  const [chunkArray, setChunkArray] = useState<any | null>(null);
  const [file, setFile] = useState<any | null>(null);
  const [fileData, setFileData] = useState<any | null>(null);
  const [isPausePlay, setPausePlay] = React.useState<null | boolean>(null);
  const [transcriptToShow, settranscriptToShow] = React.useState<transscirptType[]>([]);
  const [language, setLanguage] = useState('en-us-vosk-v0.22');
  //const [perviousLanguage, setPreLanguage] = useState('en-us-vosk-v0.22');
  const [record, setRecord] = useState(false);
  const [eventOpen, setEventOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const eventSource = useRef<any | never | unknown>();
  const appUrl: string = process.env.REACT_APP_URL as string;
  const [exampleLabel, setExampleLabel] = React.useState('');
  const [roomId, setRoomId] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [active, setActive] = React.useState(false);
  const interval = useRef<any | never | unknown>();
  const [enable, setPlayEnable] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const { t } = useTranslation();
  const [isRecordingStart, setRecording] = React.useState(false);
  const [activee, setActivee] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
  } = useAudioRecorder();

  const getMedia = async () => {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return stream?.id;
    } catch (err) {
      return false;
    }
  };

  const startVoiceRecording = async () => {
    const checkForMedia: any = await getMedia();
    if (!isRecording && checkForMedia) {
      setShowError(false);
      setVideoError(false);
      setRecording(true);
      setRecord(true);
      startRecording(NUMBER.TWO_FIFTY);
      setExampleLabel('');
    } else if (isRecording) {
      setActivee(true);
      setRecording(false);
      stopRecording();
    } else setSuccess(true);
  };
  const userData = JSON.parse(String(getLocalItem('loginData')));

  useEffect(() => {
    dispatch(RtsttAudioExampleApp());
  }, []);

  useEffect(() => {
    dispatch((RtsttLanguageExampleApp()));
  }, []);

  const tryAudioExample: ExampleAudioStatusType = useTypedSelector(
    (state) => state.exampleAudioRtstt?.payload,
  );
  const LanguageModal: ExampleAudioLanguageStatusType = useTypedSelector(
    (state) => state.exampleVoiceModalRtstt?.payload,
  );

  const fnEndAudio = () => {
    setPausePlay(false);
  };

  const clearModal = () => {
    setConfrimationModal(true);
  };

  /*const checkSampleRate = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    const sampleRate = stream.getAudioTracks()[0].getSettings().sampleRate;
    console.log('sampleRate', sampleRate);
  };*/

  useEffect(() => {
    if (recordingBlob) {
      sendFileAudio(recordingBlob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    const elem = document.getElementById('scroll');
    if (elem) elem.scrollTop = elem.scrollHeight;
  }, [transcriptToShow]);

  useEffect(() => {
    if (!roomId) {
      const userString = CraeteId(CHARLIMIT.FIVE);
      const userIds = `${userData?.name.slice(CHARLIMIT.ZERO, CHARLIMIT.FIVE)}${userString}`;
      const roomIds = CraeteId(CHARLIMIT.SEVEN);
      setRoomId(roomIds);
      setUserId(userIds);
    }
  }, [roomId]);

  useEffect(() => {
    if (!eventOpen && roomId) {
      // const APIss = `${eventAPI}/${roomId}`;
      setEventOpen(true);
      eventSource.current = new EventSource(`${eventAPI}/${roomId}`);
      //eventSource.current.addEventListener('models', (event: any) => setRoom(event.data));
      eventSource.current.addEventListener('text', (event: any) => {
        dispatch(showLoader());
        const parsedEventData = JSON.parse(event.data);
        settranscriptToShow((pre) => {
          if (pre) {
            const datatoreturn = pre.map((item) => {
              if (item.type === 'partial') {
                return { ...item, result: `${parsedEventData.result}`, type: 'text' };
              }
              return item;
            });
            return datatoreturn;
          }
          return [parsedEventData];
        });
        dispatch(hideLoader());
      });

      eventSource.current.addEventListener('partial', (event: any) => {
        dispatch(showLoader());
        const parsedEventData = JSON.parse(event.data);
        settranscriptToShow((pre) => {
          if (pre) {
            let matched = false;
            const datatoreturn = pre.map((item) => {
              if (item.type === 'partial') {
                matched = true;
                return parsedEventData;
              }
              return item;
            });
            if (!matched) {
              datatoreturn.push(parsedEventData);
            }
            return datatoreturn;
          }
          return [parsedEventData];
        });
        dispatch(hideLoader());
      });
    }
  }, [roomId]);

  const sendFileAudio = async (fileVal: File | Blob | ArrayBuffer) => {
    const model = language;
    const formData = new FormData();
    const message = new Blob(
      [JSON.stringify({
        model,
        roomId,
        userId,
        timestamp: Date.now(),
        sampleRate: NUMBER.AUDIO_SAMPLE_RATE,
      })],
      { type: 'application/json' },
    );
    formData.append('message', message);
    formData.append('data', new Blob([fileVal], { type: 'audio/wav' }));
    dispatch(sendAudioApp(formData, model, roomId, userId));
  };

  const [showError, setShowError] = useState(false);
  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    //function to get duration of a file
    const duration = await getAudioVideoDuration(e.target.files[0]);
    const video = await getFileTypeAudioOrVideo(e.target.files[0]);
    if (!video) {
      setVideoError(false);
      if (duration && duration <= NUMBER.DURATION_LIMIT) {
        setShowError(false);
        settranscriptToShow([]);
        const blob = URL.createObjectURL(e.target.files[CHARLIMIT.ZERO]);
        setFileData(e.target.files[CHARLIMIT.ZERO]);
        setFile(blob);
        audioInChunks(e.target.files[CHARLIMIT.ZERO]);
      } else {
        setShowError(true);
      }
    } else {
      setVideoError(true);
    }
  };

  const handleExample = async (hUrl: string, label: string) => {
    setShowError(false);
    setVideoError(false);
    settranscriptToShow([]);
    setExampleLabel(label);
    const exampleUrl = `${appUrl}${hUrl}`;
    setFile(exampleUrl);
    const type = getUrlFileType(exampleUrl);
    const data = await createFile(exampleUrl, type);
    setFileData(data);
    if (data) {
      const convertedFile = new File([data], 'abc.wav', { type: 'audio/mp3' });
      audioInChunks(convertedFile);
    }
  };

  const audioInChunks = async (fileDataBlob: File) => {
    if (!fileDataBlob) return;
    const audioContext = new (window.AudioContext)();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileDataBlob);
    fileReader.onloadend = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      // Convert array buffer into audio buffer
      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        const source = audioContext.createBufferSource();
        const totalDuration = audioBuffer?.duration;
        const chunksDuration = Math.ceil(totalDuration / CHARLIMIT.POINT_TWO_FIVE);
        let end = NUMBER.TWO_FIFTY;
        let begin = CHARLIMIT.ZERO;
        let i = CHARLIMIT.ZERO;
        const chunksArr:any = [];
        while (i < chunksDuration) {
          AudioBufferSlice(audioBuffer, chunksArr, begin, end, (slicedAudioBuffer: AudioBuffer | null) => {
            source.buffer = slicedAudioBuffer;
            const gainNode = audioContext.createGain();
            gainNode.gain.value = 1;
            source.connect(gainNode);
            gainNode.connect(audioContext.destination);
          });
          end += NUMBER.TWO_FIFTY;
          i += 1;
          begin += NUMBER.TWO_FIFTY;
        }
        if (chunksArr.length !== CHARLIMIT.ZERO) {
          setChunkArray(chunksArr);
        }
      });
    };
  };

  useEffect(() => {
    if (chunkArray?.length) {
      setTimeout(() => {
        const obj = chunkArray[currentIndexResult];
        const files = new File([obj], 'abc.wav', { type: 'audio/wav' });
        sendFileAudio(files);
      }, NUMBER.TWO_FIFTY);
    }
  }, [currentIndexResult]);

  const startOver = () => {
    setRecording(false);
    setPausePlay(false);
    clearInterval(interval.current);
    eventSource.current.close();
    setRoomId('');
    settranscriptToShow([]);
    setPlayEnable(false);
    setEventOpen(false);
    setFile(null);
    setFileData(null);
    setActivee(false);
    setExampleLabel('');
    setActive(!active);
    setRecord(false);
    stopRecording();
  };
  const clearTranscript = () => {
    setPausePlay(false);
    clearInterval(interval.current);
    eventSource.current.close();
    setEventOpen(false);
    setRoomId('');
    setPlayEnable(false);
    settranscriptToShow([]);
    setTimeout(() => settranscriptToShow([]), 1000);
    setExampleLabel('');
    setFile(null);
    setRecording(false);
    setFileData(null);
    setActivee(false);
    setConfrimationModal(false);
    if (record) {
      setRecord(false);
      stopRecording();
    }
    setActive(!active);
  };

  const waveform = useRef<any | never | unknown>();
  React.useEffect(() => {
    if (!isRecording) {
      waveform.current?.microphone.stop();
      waveform.current?.microphone.destroy();
      waveform.current = null;
    }
  }, [isRecording]);

  const isLoading = useTypedSelector((state) => state.loader);
  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggle = (value: boolean, modal?: string) => {
    addBodyClass(value);
    setShowDropdown(value);
    if (modal && language !== modal) {
      const model = language;
      const formDataEmpty = new FormData();
      const messageEmpty = new Blob(
        [JSON.stringify({
          model,
          roomId,
          userId,
          timestamp: Date.now(),
          sampleRate: NUMBER.AUDIO_SAMPLE_RATE,
        })],
        { type: 'application/json' },
      );
      formDataEmpty.append('message', messageEmpty);
      formDataEmpty.append('data', new Blob([''], { type: 'audio/wav' }));
      dispatch(sendAudioApp(formDataEmpty, model, roomId, userId));
    }
    if (modal) {
      setLanguage(modal);
    }
  };

  const truncateString = (string: string) => (string.length > CHARLIMIT.TEN ? `${string.substring(CHARLIMIT.ZERO, CHARLIMIT.TEN)}…` : string);
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
     {fileData && !exampleLabel ? fileData?.name : exampleLabel}
    </Tooltip>
  );

  const sussceeReviewObject = {
    heading: t('Mic_Disabled.Title'),
    description: t('Mic_Disabled.Desc'),
    button_text: t('Mic_Disabled.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClick = () => {
    setSuccess(false);
  };

  return (
        <>
          <div className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6">
              <Card className="cardText">
                <Card.Header>
                    <span className="title-card">{t('Techcard_RealtimeSpeechtoText_DetailView_Head_Voice')}</span>
                    <div className="two-action-btn">
                      {(file || record) && <span className="starOverbtnWithLanguage">
                        <Button className="btnstartOver cursor" onClick={() => startOver()}>
                          {/* <span className="btn-ic">
                            <Image src={icMicSmall} alt="Icon" />
                          </span> */}
                          <span className="btn-txt">{t('Techcard_RealtimeSpeechtoText_DetailView_Tab_UseMicrophone')}</span>
                        </Button>
                                           </span>
                      }
                      <span className="title-action-btn">
                        <DropdownButton
                          show={showDropdown}
                          align="end"
                          title={`${language ? language : 'en-us-vosk-v0.22'}`}
                          id="dropdown-menu-align-top"
                          className="DropdownWithOverLay SpeakerBtn"
                          onToggle={(value) => handleToggle(value)}
                        >
                          <div className="dropdwon-scroll speaker-dropdown height-none">
                            { LanguageModal?.models?.map((modal: string) => (
                            <label className={`${language === modal ? 'checkbox-container active' : 'checkbox-container'}`} onClick={() => handleToggle(false, modal)} onKeyDown={(event) => event.key === 'Enter' && handleToggle(false, modal)} htmlFor={modal}>
                              <input id={modal} className="radio-custom" name="radio-group" checked={language === modal} type="radio" onChange={() => handleToggle(false, modal)} />
                                <span className="checkbox-txt">{modal}</span>
                                <span className="checkmark" />
                            </label>))}
                          </div>
                        </DropdownButton>
                        <div className="DropdownBackdrop" />
                      </span>
                    </div>
                </Card.Header>
                <Card.Body className={`${file ? 'd-none' : 'VoiceWavePlacholderBody'}`}>
                  <div className={`${!record || !file ? '' : 'd-none'} VoiceWavePlacholderBox`}>
                {(!file && !record && !activee)
                  && <span>
                      <Image src={placeholderImage} alt="Icon" className="d-none" />
                     </span>
                }
                {isRecording && <RecordAudioBar startRecoeding={record} waveform={waveform} />}
                {(!isRecording && activee)
                  && <span>
                      <Image src={waves} alt="Icon" />
                     </span>
                }
                  </div>
                </Card.Body>
                <Card.Footer className="whiteBgFooter">
                  {!file && <div className="action-left">
                  {!file && !record && <span className={`${isRecording ? 'disabled-btn' : 'title-action-btn'}`}>
                        <DropdownButton
                          align="end"
                          title={exampleLabel ? truncateString(exampleLabel) : t('Techcard_RealtimeSpeechtoText_DetailView_Dropdown_TryExample_Label')}
                          id="dropdown-menu-align-end"
                          className="DropdownWithOverLay tryExampleRTS"
                          onToggle={(value) => { addBodyClass(value); }}
                        >
                            <Dropdown.Header>{t('Techcard_RealtimeSpeechtoText_DetailView_Dropdown_Example_Label')}</Dropdown.Header>
                            <CustomScrollBar height={180}>
                              <div className="dropdwon-scroll height-none">
                                { tryAudioExample?.data?.map((data) => (
                                <Dropdown.Item eventKey="1" className={exampleLabel === data?.label ? 'active' : ''} onClick={() => { handleExample(data?.url, data.label); }} key={data?.url}>
                                  <span className="d-block">
                                    <span className="icon-txt">{data?.label}</span>
                                  </span>
                                </Dropdown.Item>
                                ))
                              }
                              </div>
                            </CustomScrollBar>
                        </DropdownButton>
                        <div className="DropdownBackdrop" />
                                       </span>}
                    {!record && !file && <span className="upload-action-btn">
                      <div className="customUploadBtn">
                        <label htmlFor="file-upload" className="custom-file-upload">
                          <Image src={icUpload} alt="Icon" />
                          <span className="uploadTxt">{t('Techcard_RealtimeSpeechtoText_DetailView_Tab_UploadFile')}</span>
                      <input id="file-upload" type="file" accept={MIME_TYPE} onChange={(e) => onFileInputChange(e)} />
                        </label>
                      </div>
                                         </span>}
                            </div>}
                  {!file && <div className="action-right stop-button">
                    <div className="two-action-btn">
                      <span className="actionBtn">
                      { !isRecordingStart ? <Button className="startSpeakingBtn cursor" onClick={startVoiceRecording}>{t('Techcard_RealtimeSpeechtoText_DetailView_Tab_StartSpeaaking')}</Button>
                        : <Button className="stopRecordinggBtn cursor" onClick={startVoiceRecording}>{t('Techcard_RealtimeSpeechtoText_DetailView_Tab_StopRecording')}</Button>}
                      </span>
                    </div>
                            </div>}
                </Card.Footer>
                {showError && <div className="uploaded-file-row--error statusFailed">
                  <div className="uploaded-file-content">
                    <span className="fileName">{t('Message.file_duration_Three_min')}</span>
                  </div>
                              </div>}
                {videoError && <div className="uploaded-file-row--error statusFailed">
                  <div className="uploaded-file-content">
                    <span className="fileName">{t('Message.Files_Allowed')}</span>
                  </div>
                               </div>}
                {file && !record && <AudioWavesBar
                  setIndex={setCurrentIndexResult}
                  isPausePlay={isPausePlay}
                  audioFile={file}
                  playEnable={setPlayEnable}
                  end={fnEndAudio}
                />}
                {file && <Card.Footer className="blue-footer-bg">
                  <div className="sound-row">
                    <div className="sound-left-col">
                      <div className="sound-details">
                        <span className="sound-detail-placeholder">
                          <Image src={icMicBlue} alt="Icon" />
                        </span>
                        <span className="detail-txt">
                        {!record
                             && <OverlayTrigger
                               placement="right"
                               delay={{ show: 200, hide: 100 }}
                               overlay={renderTooltip}
                          >
                             <span className="sound-name">{fileData && !exampleLabel ? fileData?.name : exampleLabel}</span>
                                </OverlayTrigger>}
                          <span className="sound-format">{fileData ? fileData?.type : 'type'} {`(${(fileData && (fileData.size / (NUMBER.ONE_THOUSAND_TWENTY_FOUR * NUMBER.ONE_THOUSAND_TWENTY_FOUR)).toFixed(CHARLIMIT.FOUR))} ${t('Techcard_RealtimeSpeechtoText_DetailView_Size')})`}</span>
                        </span>
                      </div>
                    </div>
                    <div className="sound-right-col">
                    <span className={`${!enable ? 'disabled-btn music-pause' : 'music-pause'}`} onClick={() => setPausePlay(!isPausePlay)}>
                        <Image src={isPausePlay ? musicPause : IcPlay} alt="Icon" />
                    </span>
                    </div>
                  </div>
                         </Card.Footer>}
              </Card>
            </Col>
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6">
                <Card className="cardSummary cardTranscript">
                    <Card.Header>
                        <span className="title-card">{t('Techcard_RealtimeSpeechtoText_DetailView_HeadingTranscript')}</span>
                    </Card.Header>
                    <Card.Body>
                      {transcriptToShow?.length > CHARLIMIT.ZERO ? <div id="scroll" className="CardTextScroll">
                        <Card.Text>
                          <span>
                            <span className="d-block">{transcriptToShow?.map((item) => item.result)}</span>
                            <span className="d-block">{(isLoading.status || isPausePlay) && <span className="smallDotedLoading"><div className="dot-flashing" /></span>}</span>
                          </span>
                        </Card.Text>
                                                                   </div>
                        : <div className="summaryCardAEmptyBox">
                      <Image src={TranscriptPlaceholder} alt="Icon" width="65" height="65" />
                          </div>}
                    </Card.Body>
                    <Card.Footer>
                      <Button className={`clearBtn cursor  ${file || record || transcriptToShow.length > CHARLIMIT.ZERO ? '' : 'disabled-btn'}`} onClick={clearModal}>{t('Techcard_RealtimeSpeechtoText_DetailView_Tab_ClearTranscript')}</Button>
                    </Card.Footer>

                </Card>
            </Col>
            <Col xs="12">
            <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
            </p>
            </Col>
          </div>
    <ConfirmationModal
      close={setConfrimationModal}
      show={ShowConfirmationModal}
      confirm={clearTranscript}
      title={t('Techcard_RealtimeSpeechtoText_Modal_Title')}
      text={t('Techcard_RealtimeSpeechtoText_Modal_Text')}
    />
    <SuccessModal
      showSuccess={success}
      Successclose={setSuccess}
      messageContent={sussceeReviewObject}
      buttonAction={successButtonClick}
      imagefile={reviewImage} />

        </>
  );
};
