import { NavigateFunction } from 'react-router-dom';
import { clearLocalItems, setLocal, setToken } from '../../Utils/LocalStorage';
import { SignInActionType, forgetPasswordActionType } from '../actionType/session';
import {
  SignInApi, ForgetPasswordApi, ResetPasswordApi, veirificationCodeApi,
} from '../../Api';

import { AppDispatch } from '../../Hooks/useAppDispatch';

import {
  SIGNIN, SIGN_OUT, FORGETPASSWORD, RESETPASSWORD, VERIFIACTIOCODE,
} from './actionType';

import { AUTH_TOKEN, LOGIN_DATA } from '../../Constants/Static';
import {
  SignInPostType, resetPasswordType, forgetPasswordPostType, resetPasswordStatusType, verifiactioCodePostType, verifiactioCodeStatusType,
} from '../../Type/Session';

/* action to handle signIn data post api for user signin */
export const signInApp = (data: SignInPostType, path: string, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  try {
    const resp = await SignInApi(data) as SignInActionType;
    if (resp?.status) {
      setLocal(LOGIN_DATA, resp?.data);
      setToken(AUTH_TOKEN, resp?.data?.token);
    }
    dispatch({
      type: SIGNIN,
      payload: resp?.data,
    });
    path && navigate(path);
  } catch (error) {
    dispatch({
      type: SIGNIN,
      payload: error,
    });
  }
};

/* action to handle signOut and reove data from local storage */
export const signOut = () => (dispatch: AppDispatch) => {
  clearLocalItems();
  dispatch({
    type: SIGNIN,
    payload: '',
  });
  dispatch({
    type: SIGN_OUT,
  });
};

/* action to handle forget password post api */
export const forgetPasswordApp = (payload: forgetPasswordPostType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await ForgetPasswordApi(payload) as forgetPasswordActionType;
    dispatch({
      type: FORGETPASSWORD,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: FORGETPASSWORD,
      payload: error,
    });
  }
};

/* action to handle verificationCode post api */
export const verificationCodeApp = (payload: verifiactioCodePostType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await veirificationCodeApi(payload) as verifiactioCodeStatusType;
    dispatch({
      type: VERIFIACTIOCODE,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: VERIFIACTIOCODE,
      payload: error,
    });
  }
};

/* action to handle resetPasswor post api */
export const resetPasswordApp = (payload: resetPasswordType) => async (dispatch: AppDispatch) => {
  try {
    const resp = await ResetPasswordApi(payload) as resetPasswordStatusType;
    dispatch({
      type: RESETPASSWORD,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: RESETPASSWORD,
      payload: error,
    });
  }
};
