/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-bitwise */
import React, { useEffect, useRef } from 'react';
import {
  Image,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import icWhiteTime from '../../assets/images/ic-white-time.svg';
import icTime from '../../assets/images/ic-time.svg';
import { partialType } from '../../Type/Rtstt';
import { CHARLIMIT } from '../../Constants/Number';

export const ChatRoom: React.FC<{
  isChatStart: boolean; transcript: partialType[], hideUser: string[], userId: string, isMute: boolean,
  }> = ({
    isChatStart, transcript, hideUser, userId, isMute,
  }): React.ReactElement => {
    const scrollBar = useRef<any | never | unknown>();
    useEffect(() => {
      if (scrollBar.current) scrollBar.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, [transcript, scrollBar]);
    const { t } = useTranslation();
    // console.log('trans', transcript);
    return (
    <div className={`meeting-items chatRoomItems ${isChatStart ? '' : 'd-none'}`}>
        <div className="meetingItemScroll">
          {transcript.length > 0 && transcript?.map((PartialR: partialType, index) => (
            <>
            {PartialR && PartialR?.result && PartialR?.result !== '' && PartialR?.userId !== userId && !hideUser?.includes(PartialR?.userId)
            && <div className="itemRow" id="abcdabcd" ref={scrollBar}>
                <div className="itemHeader">
                <span className="title">{PartialR?.userId.slice(0, PartialR.userId.length - CHARLIMIT.FIVE)}</span>
                <span className="updated-time">
                    <span className="timeIcon">
                    <Image src={icTime} alt="Icon" />
                    </span>
                    <span className="updaterd-time-txt">{new Date(PartialR?.timestamp).toLocaleString()}</span>
                </span>
                </div>
                <div className="itemBody">
                <p className="itemBodyTxt">
                  <span className="d-block">{PartialR?.result}</span>
                  {transcript.length === (index + 1) && isMute && <span className="d-block loader-wrap">
                    <span className="smallDotedLoading"><div className="dot-flashing" /></span>
                                                                  </span>}
                </p>
                </div>
               </div>
            }
            {PartialR && PartialR?.result && PartialR?.result !== '' && PartialR?.userId === userId
            && <div className="itemRow chatReply" ref={scrollBar}>
                <div className="itemHeader">
                <span className="title">{t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_UserListYou')}</span>
                <span className="updated-time">
                    <span className="timeIcon">
                    <Image src={icWhiteTime} alt="Icon" />
                    </span>
                    <span className="updaterd-time-txt">{new Date(PartialR?.timestamp).toLocaleString()}</span>
                </span>
                </div>
                <div className="itemBody">
                <p className="itemBodyTxt">
                  <span className="d-block">{PartialR?.result}</span>
                  {transcript.length === (index + 1) && isMute && <span className="d-block loader-wrap">
                    <span className="smallDotedLoading"><div className="dot-flashing" /></span>
                                                                  </span>}
                </p>
                </div>
               </div>
            }
            </>
          ))}
        </div>
    </div>
    );
  };
