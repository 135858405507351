import React, { ElementType, useState } from 'react';
import i18next from 'i18next';
import Form from 'react-bootstrap/Form';
import { Image } from 'react-bootstrap';
import { FormFields } from '../../Type/FormFields';
import { generateInputErrorMessage } from '../../Utils/FormValidation';
import EyeHide from '../../assets/images/ic-eye-hide.svg';
import EyeShow from '../../assets/images/ic-eye-show.svg';

const checkInputValue = (required?: boolean, trimValue?: string) => (required && !trimValue ? i18next.t('Message.Required') : '');

const setErrorClass = (isError: string) => (isError ? 'error' : '');

const setPasswordType = (showPassword: boolean, type?: string) => (showPassword ? 'text' : (type === 'email' ? 'text' : type));

export const Input:React.FC<FormFields> = ({
  name,
  label,
  type,
  as,
  tabIndex,
  className,
  errorMessage,
  required,
  readOnly,
  placeholder = ' ',
  isValid,
  value,
  inputRef,
  maxLimit,
  minLimit,
  wrapperStyle,
  onChange,
  onBlur,
  onClick,
  onFocus,
  pattern,
  isRoomId,
  compareValue,
  compareConfirmValue,
  isOldPassword,
  isShowPassword,
  isOldPasswordSame,
  updateValidStatus,
  isPasswordConfirmation,
  isNewPasswordConfirmation,
  span,
  onPaste,
  autoFocus,
}: FormFields): React.ReactElement => {
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const validatedField = (val: string) => {
    let err = checkInputValue(required, val);
    if (!err) {
      err = generateInputErrorMessage({
        compareValue,
        isOldPasswordSame,
        compareConfirmValue,
        isOldPassword,
        isPasswordConfirmation,
        isNewPasswordConfirmation,
        max: maxLimit,
        min: minLimit,
        pattern,
        text: val,
        type,
        isRoomId,
      });
    }

    setError(err);

    updateValidStatus && updateValidStatus(!err);
  };
  const handleChange = (event: { target: { value: string; }; }) => {
    if (onChange) {
      onChange(event.target.value);
    } else if (!errorMessage) {
      validatedField(event.target.value);
    }
  };

  const handleBlur = (event: { target: { value: string; }; }) => {
    if (onBlur) {
      onBlur(event.target.value);
    } else {
      validatedField(event.target.value);
    }
  };

  const isError = errorMessage || error;

  return (
    <div style={wrapperStyle as React.CSSProperties} className={`form-group form-floating ${className} ${setErrorClass(isError)}`}>
      <Form.Control
        type={setPasswordType(showPassword, type)}
        as={as as ElementType| undefined}
        name={name}
        ref={inputRef}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        maxLength={maxLimit}
        minLength={minLimit}
        min={minLimit}
        onChange={handleChange}
        onPaste={onPaste}
        onBlur={handleBlur}
        value={value}
        isValid={isValid}
        onClick={onClick}
        onFocus={onFocus}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
        autoComplete="new-password"
        onInput={(event) => required && validatedField((event.target as HTMLInputElement).value)}
      />
      {label && <Form.Label>{label} {span && <span>{span}</span>}</Form.Label>}
      {isError && <Form.Text className="text-muted">
        {isError}
                  </Form.Text>}
      {isShowPassword && (
        <span onClick={() => setShowPassword(!showPassword)} className="show-text">
         {!showPassword
           ? <span className="passwordView eyeShow1" onClick={() => setShowPassword(!showPassword)}>
          <Image src={EyeHide} alt="Icon" />
             </span>
           : <span className="passwordView eyeShow1" onClick={() => setShowPassword(!showPassword)}>
          <Image src={EyeShow} alt="Icon" />
             </span>}
        </span>
      )}
    </div>);
};
