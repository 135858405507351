import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { Input } from '../FormFields/Inputs';
import { formValidator } from '../../Utils/FormValidation';
import { forgetPasswordApp } from '../../Redux/actions/sessions';
import { forgetPasswordType } from '../../Type/Session';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import './SignInForm.scss';
import { CHARLIMIT } from '../../Constants/Number';
import { FORGETPASSWORD } from '../../Redux/actions/actionType';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ForgetPassword: React.FC<{
  showForgetPassword: boolean; email: string; setEmail: React.Dispatch<React.SetStateAction<string>>; Forgetclose: React.Dispatch<React.SetStateAction<boolean>>; Successclose: React.Dispatch<React.SetStateAction<boolean>>; buttonAction: () => void;
}> = ({
  showForgetPassword, email, setEmail, Forgetclose, Successclose, buttonAction,
}): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [errors, setErrors] = React.useState(['email']);

  /* getting password status from redux store (api response) */
  const passwordSuccess: forgetPasswordType = useTypedSelector(
    (state) => state?.forgetPassword?.payload,
  );

  /* useEffect to close modal if api give status true */
  React.useEffect(() => {
    if (passwordSuccess && passwordSuccess?.status) {
      Successclose(true);
      Forgetclose(false);
      dispatch({
        type: FORGETPASSWORD,
        payload: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSuccess]);
  /* function to handle form submission */
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(forgetPasswordApp({ email }));
    e.preventDefault();
  };
  /* function to validate user inputs */
  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  const handleClose = () => {
    Forgetclose(false);
    setEmail('');
  };

  return (
  <Modal
    className="SignInModal ForgotPasswordModal"
    show={showForgetPassword}
    backdrop="static"
    onHide={() => handleClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{t('ForgotPassword.Title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <span className="d-block">{t('ForgotPassword.Desc')}</span>
      </p>
      <Form onSubmit={onFormSubmit} className="customFormUI">
        <Form.Group className="form-group" controlId="formBasicEmail">
          <Input
            label={t('ForgotPassword.Email_Address')}
            span="*"
            type="email"
            value={email}
            onChange={(value: string) => setEmail(value)}
            required
            updateValidStatus={(isValid: boolean) => validatedForm('email', isValid)}
          />
        </Form.Group>
        <div className="action-btn">
          <Button variant="primary" disabled={errors.length > CHARLIMIT.ZERO} type="submit">{t('ForgotPassword.Button_Submit')}</Button>
        </div>
        <div className="BacktoSignIn">
          <span onClick={() => buttonAction()}>{t('ForgotPassword.Back_To_SignIn')}</span>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
  );
};
