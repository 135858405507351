import React from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { DefaultLayout } from '../Components/layout/DefaultLayout';
import { AutoSummerization } from '../Views/AutoSummerization/AutoSummerization';
import Dashboard from '../Views/Dashboard/Dashboard';
import { RealTimeSpeechTOText } from '../Views/RealTimeSpeechTOText/RealTimeSpeechTOText';
import { SoundEventDetection } from '../Views/SoundEventDetection/SoundEventDetection';
// import { TextToQA } from '../Views/TextToQA/TextToQA';
import { TextToSpeech } from '../Views/TextToSpeech/TextToSpeech';
// import { VideoContentSearch } from '../Views/VideoContentSearch/VideoContentSearch';
import { VoiceAuthentication } from '../Views/VoiceAuthentication/VoiceAuthentication';

const PublicRoutes: React.FC = () => (
        <BrowserRouter>
            <DefaultLayout>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/auto-summerization" element={<AutoSummerization />} />
                    <Route path="/text-to-speech" element={<TextToSpeech />} />
                    {/* <Route path="/text-to-qa" element={<TextToQA />} /> */}
                    {/* <Route path="/video-Search" element={<VideoContentSearch />} /> */}
                    <Route path="/voice-authentication" element={<VoiceAuthentication />} />
                    <Route path="/sound-event-detection" element={<SoundEventDetection />} />
                    <Route path="/realtime-speech-to-text" element={<RealTimeSpeechTOText />} />
                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                </Routes>
            </DefaultLayout>
        </BrowserRouter>);

export default PublicRoutes;
