import { sedExampleApi, sedResultApi, sedUploadAudioFile } from '../../Api';
import { SEDExampleAudioResponse, SEDResponseForResult, SEDUploadedAudioFileResult } from '../../Type/Rtstt';
import { showLoader, hideLoader } from './loader';
import { AppDispatch } from '../../Hooks/useAppDispatch';

export const SEDExampleApp = () => async () => {
  try {
    return await sedExampleApi() as SEDExampleAudioResponse;
  } catch (error) {
    return error;
  }
};

export const SEDResultOFInput = (filename:string, model:string) => async () => {
  try {
    const resp = await sedResultApi(filename, model) as SEDResponseForResult;
    return resp;
  } catch (error) {
    return error;
  }
};

export const SEDUploadFile = (FormData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await sedUploadAudioFile(FormData) as SEDUploadedAudioFileResult;
    dispatch(hideLoader());
    return resp;
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
