import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CHARLIMIT } from '../Constants/Number';
import { AUTH_TOKEN } from '../Constants/Static';
import { PermissionType } from '../Type/Permission';
import { getLocalItem } from '../Utils/LocalStorage';

const AuthorizedRoutes: React.FC<{permissions: PermissionType[], children: React.ReactElement | null, label?:string }> = ({ permissions, children, label }) => {
  const location = useLocation();
  let pathToSendTo = location.pathname;
  const isLoggedIn = getLocalItem(AUTH_TOKEN);
  const filterValue = permissions.filter((resp) => resp.label === label)[0];
  if (filterValue?.permission === CHARLIMIT.ZERO || isLoggedIn) {
    return children;
  }
  if (location.search) {
    pathToSendTo = location.pathname + location.search;
  }
  return <Navigate to="/" replace state={{ path: pathToSendTo }} />;
};

export default AuthorizedRoutes;
