import { showLoader, hideLoader } from './loader';
import { AutoSummaryApiType, AutoSummaryExampleApiType } from '../../Type/ApiType';
import { SummaryExampleApi, GenerateSummaryApi } from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { AutoSummarizationPostType } from '../actionType/AutoSummarization';
import { AUTO_SUMMARIZATION } from './actionType';

/* action to handle auto summary example api */
export const autoSummarizationExample = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await SummaryExampleApi() as AutoSummaryExampleApiType;
    dispatch({
      type: AUTO_SUMMARIZATION.EXAMPLE,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: AUTO_SUMMARIZATION.EXAMPLE,
      payload: [],
    });
  }
};

/* action to handle auto summarry data post api for summary output */
export const autoSummarizationGenerate = (data: AutoSummarizationPostType) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await GenerateSummaryApi(data) as AutoSummaryApiType;
    dispatch({
      type: AUTO_SUMMARIZATION.GENERATE,
      payload: resp,
    });
    dispatch(hideLoader());
  } catch (error) {
    dispatch({
      type: AUTO_SUMMARIZATION.GENERATE,
      payload: [],
    });
    dispatch(hideLoader());
  }
};
