import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './breadcrumbBar.scss';

export const BreadcrumbBar: React.FC<{name: string, url: string}> = ({ name, url }): React.ReactElement => {
  const navigate = useNavigate();

  /* function to handle click on demo and route to dashboard */
  const goto = (_url: string) => {
    navigate(_url);
  };
  const { t } = useTranslation();
  return (
    <Breadcrumb>
        <Breadcrumb.Item onClick={() => goto(url)}>{t('Breadcrumb.Breadcrumb_Demo')}</Breadcrumb.Item>
        <Breadcrumb.Item active>{name}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
