import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ShowProfile } from '../../Components/Profile/ShowProfile';
import './profile.scss';

export const Profile: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Container className="profileContainer">
        <h1>{t('Profile.ProfileHeader')}</h1>
      <ShowProfile />
    </Container>
  );
};
