import {
  ExampleSentenceApi, ExampleAudioApi, enrollAudioApi, openSseApi, authAudioApi,
} from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { hideLoader, showLoader } from './loader';
import {
  ExampleSentenceActionType, ExampleAudioActionType, EnrollAudioActionType, OpenSseActionType,
} from '../actionType/Va';
//import { EnrollAudioPostType } from '../../Type/VoiceAuthenticate';
import { VOICE_AUTHENTICATION, SSE } from './actionType';

export const getExampleSentence = () => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await ExampleSentenceApi() as ExampleSentenceActionType;
    dispatch({
      type: VOICE_AUTHENTICATION.EXAMPLE_SENTENCE,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: VOICE_AUTHENTICATION.EXAMPLE_SENTENCE,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

export const getExampleAudio = (type: string) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await ExampleAudioApi(type) as ExampleAudioActionType;
    dispatch({
      type: VOICE_AUTHENTICATION.EXAMPLE_AUDIO,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: VOICE_AUTHENTICATION.EXAMPLE_AUDIO,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

export const enrollAudio = (payload: FormData, cb: () => void) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await enrollAudioApi(payload) as EnrollAudioActionType;
    dispatch({
      type: VOICE_AUTHENTICATION.ENROLL_AUDIO,
      payload: resp,
    });
    dispatch(hideLoader());
    return cb && cb();
  } catch (error) {
    dispatch({
      type: VOICE_AUTHENTICATION.ENROLL_AUDIO,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

export const openSSE = (roomId: string) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await openSseApi(roomId) as OpenSseActionType;
    dispatch({
      type: SSE,
      payload: resp,
    });
  } catch (error) {
    dispatch({
      type: SSE,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

export const authAudio = (payload: FormData, cb: () => void) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await authAudioApi(payload) as EnrollAudioActionType;
    dispatch({
      type: VOICE_AUTHENTICATION.AUTH_AUDIO,
      payload: resp,
    });
  } catch (error) {
    if (error === undefined) {
      const obj = {
        code: -100,
        msg: 'Unhandled Error',
        status: false,
      };
      dispatch({
        type: VOICE_AUTHENTICATION.AUTH_AUDIO,
        payload: obj,
      });
    } else {
      dispatch({
        type: VOICE_AUTHENTICATION.AUTH_AUDIO,
        payload: error,
      });
    }
  }
  dispatch(hideLoader());
  return cb && cb();
};
