/* eslint-disable react/no-danger */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Col,
  Card,
  Image,
  Button,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import placeholderSearch from '../../assets/images/placeholder-search.svg';
import icPlay from '../../assets/images/ic-play.svg';
import '../../assets/scss/smallLoader.scss';
import { setToken, getLocalItem } from '../../Utils/LocalStorage';
import { url } from '../../Constants/Url';
import { NUMBER, VIDEO_SEARCH_MESSAGE, CHARLIMIT } from '../../Constants/Index';
import { SearchForm } from './SearchForm';
import { SearchExample } from './SearchExample';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { VideoSearchContentType, VideoSearchContentDataType } from '../../Type/VideoSearch';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { searchVideoContent } from '../../Redux/actions/videoContentSearch';
import { VideoModal } from '../../Components/Modal/VideoModal';
import { Loader } from '../Loader/Loader';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';

export const VideoContent: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [isSearchClick, setSearchClick] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoData, setVideoData] = React.useState<VideoSearchContentDataType[] | []>([]);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [videoStartTime, setVideoStartTime] = React.useState('');
  const [videoCount, setVideoCount] = React.useState(CHARLIMIT.ZERO);
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const [searchContent, setSearchContent] = React.useState<VideoSearchContentDataType>();
  const pageLimit = NUMBER.VIDEO_SEARCH_LIMIT;
  const isLoading = useTypedSelector((state) => state.loader);

  /* getting searchContents from redux store (api response) */
  const searchContents: VideoSearchContentType = useTypedSelector(
    (state) => state.SearchVideoContent?.payload,
  );

  /* useEffect to show video content and load more button */
  React.useEffect(() => {
    if (searchKeyword && searchContents?.data?.length === pageLimit) {
      setShowLoadMore(true);
    } else {
      setShowLoadMore(false);
    }
    setVideoCount(searchContents?.total || 0);
    if (searchKeyword) {
      const searchData = searchContents?.data || [];
      const mergeData = [...videoData, ...searchData];
      setVideoData(mergeData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContents]);

  /* call back for api response */
  const searckCallback = () => {
    setSearchClick(true);
  };

  /* function to handle search content data (api call) */
  const getSearchContent = (searchVkeyword: string, pageNum: number) => {
    dispatch(searchVideoContent(searchVkeyword.trim(), pageLimit, pageNum, searckCallback));
  };
  /* function to fetchmore video data (pagination) */
  const fetchMoreData = () => {
    const searchLocalKeyword = getLocalItem('searchKeyword');
    let searchApiKeyword = searchKeyword;
    if (!searchKeyword && searchLocalKeyword) {
      searchApiKeyword = searchLocalKeyword;
      setSearchKeyword(searchLocalKeyword);
    }
    const pageNum = videoData.length;
    getSearchContent(searchApiKeyword.trim(), pageNum);
  };
  /* funtion to handle example selection */
  const exampleClick = (value: string) => {
    setVideoData([]);
    setToken('searchKeyword', value);
    setSearchKeyword(value);
    getSearchContent(value, CHARLIMIT.ZERO);
  };
  /* function to play video from starting of the video*/
  const openVideo = (vid: VideoSearchContentDataType) => {
    const videourl = `${url.YOUTUBE}?v=${vid.vid}`;
    setVideoUrl(videourl);
    setVideoStartTime('');
    setShowVideo(true);
    if (vid) setSearchContent(vid);
  };
    /* function to remove last word from string*/
  const removeLastWord = (str: string) => {
    const lastIndexOfSpace = str.lastIndexOf('.');
    if (lastIndexOfSpace === CHARLIMIT.NEGATIVE_ONE) {
      return str;
    }
    return str.substring(CHARLIMIT.ZERO, lastIndexOfSpace);
  };
  /* function to play video from a specific timestamp */
  const openVideoTime = (vid: VideoSearchContentDataType, startTime: string) => {
    const videourl = `${url.YOUTUBE}?v=${vid.vid}`;
    setVideoUrl(videourl);
    setVideoStartTime(startTime);
    setShowVideo(true);
    if (vid) setSearchContent(vid);
  };

  return (
    <div className="AutoSummarizationRow">
        <Col className="mb-30 AutoSummarizationCard searchingCard" xs="12" sm="12" lg="12">
          <Card className="searching">
           <SearchForm exampleClick={exampleClick} searchKeyword={searchKeyword} setkeyword={setSearchKeyword} />
           <SearchExample exampleClick={exampleClick} searchKeyword={searchKeyword} />
            {videoData.length === CHARLIMIT.ZERO && !isLoading.status
            && <div className="search-empty-state">
               <Image src={placeholderSearch} alt="Icon" />
               <strong className="title">{isSearchClick ? t('Techcard_VideoContentsSearch_DetailView_No_Results') : VIDEO_SEARCH_MESSAGE.pn_page_load.heading}</strong>
               {isSearchClick
                && <p className="search-txt" dangerouslySetInnerHTML={{ __html: t('Techcard_VideoContentsSearch_DetailView_No_Results_Msg') }} />
               }
               </div>
            }
            <Loader />
              <div className="searchCardRow">
              {videoData && videoData?.map((searchContent) => (
              <Col className="mb-30 searchingCardView" xs="12" sm="6" lg="4">
                <Card className="searchCard">
                  <div className="thumbnailImgMedia" onClick={() => openVideo(searchContent)}>
                    <Image src={`http://img.youtube.com/vi/${searchContent.vid}/mqdefault.jpg`} className="thumbnailImg" />
                    <span className="playIcon">
                      <Image src={icPlay} alt="Icon" />
                    </span>
                  </div>
                  <div className="cardContent">
                  <div className="cardTitle">{searchContent.title}</div>
                    <div className="contentScroll">
                      <CustomScrollBar height={CHARLIMIT.ONE_THIRTY_SEVEN} className="scrollBar">
                        <div className="list-unstyled">
                        {searchContent.chain && searchContent.chain?.map((timeChain) => (
                          <li className="list-item loopItem">
                            <div className="left-item"><span onClick={() => openVideoTime(searchContent, removeLastWord(timeChain.timestamp['0'].toString()))}>{removeLastWord(timeChain.timestamp['0'].toString())}</span></div>
                            <div className="right-item">
                              <span className="txt" dangerouslySetInnerHTML={{ __html: timeChain.text['0'] }} />
                            </div>
                          </li>
                        ))}
                        </div>
                      </CustomScrollBar>
                    </div>
                  </div>
                </Card>
              </Col>
              ))}
              {videoCount > videoData.length && showLoadMore && !isLoading?.status && <Button className="LoadMoreBtn" onClick={() => fetchMoreData()}>{ t('Techcard_VideoContentsSearch_DetailView_Button_Text_LoadMore') }</Button>}
              </div>
          </Card>
        </Col>
        <Col xs="12">
        <p className="continuingTerms">
          <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={CHARLIMIT.FIVE}>
            By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
          </Trans>
        </p>
        </Col>
      <VideoModal
        close={setShowVideo}
        show={showVideo}
        url={videoUrl}
        videoStartTime={videoStartTime}
        videoData={searchContent}
        openVideoTime={openVideoTime}
      />
    </div>
  );
};
