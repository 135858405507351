import i18next from 'i18next';
import { EMAIL_REGEX } from '../Constants/Index';
import {
  isValidEmail, isValidId, isValidText,
} from './RegexValidator';

export const formValidator = (key: string, isValid: boolean, errors: string[]) => {
  const index = errors.indexOf(key);

  if (isValid && index > -1) {
    errors.splice(index, 1);
  } else if (!isValid && index < 0) {
    errors.push(key);
  }
  return errors;
};

const verifyEmailPassword = (type?: string, text?: string) => {
  if (type === 'email' && !isValidEmail(text as string)) {
    return i18next.t('Message.InValid_Email');
  }
  return '';
};

export const generateInputErrorMessage = (props: {text: string,
    type?: string,
    min?: string,
    max?: string,
    pattern?: string,
    compareValue?: string,
    isOldPasswordSame?: boolean,
    isRoomId?: boolean,
    compareConfirmValue?: string,
    isOldPassword?: boolean,
    isPasswordConfirmation?: boolean,
    isNewPasswordConfirmation?: boolean}) => {
  const {
    text,
    type,
    min = '',
    max = '',
    pattern = '',
    isRoomId = false,
    compareValue = '',
    compareConfirmValue = '',
    isOldPassword = false,
    isOldPasswordSame = false,
    isPasswordConfirmation = false,
    isNewPasswordConfirmation = false,
  } = props;
  const isEmailPasswordInValid = verifyEmailPassword(type, text);

  if (isEmailPasswordInValid) {
    return isEmailPasswordInValid;
  }
  if (pattern && !isValidText(text)) {
    return i18next.t('Message.Invalid_Number');
  }
  if (isRoomId && !isValidId(text)) {
    return i18next.t('Message.Invalid_RoomId');
  }
  if (min && (text.length as unknown as string) < min) {
    return i18next.t('Message.Required_Min_Char', { min });
  }
  if (max && (text.length as unknown as string) > max) {
    return i18next.t('Message.Required_Max_Char', { max });
  }
  if (isPasswordConfirmation && text !== compareValue) {
    return i18next.t('Message.Confirm_NewPassword_Mustbe_Same');
  }
  if (isNewPasswordConfirmation && text !== compareValue && compareValue.length !== 0) {
    return i18next.t('Message.Confirm_NewPassword_Mustbe_Same');
  }
  if (compareValue && isOldPasswordSame && text === compareValue) {
    return i18next.t('Message.InValid_Old_New_Password');
  }
  if (compareValue && isOldPassword && text === compareValue) {
    return i18next.t('Message.InValid_Old_New_Password');
  }
  if (compareConfirmValue && isOldPasswordSame && text !== compareConfirmValue && compareConfirmValue.length !== 0) {
    return i18next.t('Message.Confirm_NewPassword_Mustbe_Same');
  }
  return '';
};

export function validateEmail(emailAdress: string) {
  // Validate Email formate.
  let returnstatus = false;
  if (emailAdress && emailAdress.match(EMAIL_REGEX)) {
    returnstatus = true;
  }
  return returnstatus;
}

export function checkEmpty(value: string) {
  // Validate Email Empty.
  let returnstatus = false;
  if (value === '' || value === null) {
    returnstatus = true;
  }
  return returnstatus;
}
