import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Image,
  ListGroup,
} from 'react-bootstrap';
import './overviewBanner.scss';
import MockeupTextSpeech from '../../assets/images/Text_to_Speech.png';
import TextVoice from '../../assets/images/Ic-EasyConvertTextVoice.svg';
import ShortenTextSummary from '../../assets/images/ic-Shorten-Text-Summary.svg';
import FasterResults from '../../assets/images/ic-Faster-Results.svg';
import AutoSummarizationMockup from '../../assets/images/Auto_Summary.png';
import TextToQAMockup from '../../assets/images/Text_Q&A.png';
import FindYourQuestion from '../../assets/images/Ic-find-your-question.svg';
import VideoSearchMockup from '../../assets/images/Video_Contents_Search.png';
import SubtitleVideo from '../../assets/images/ic-Easily-check-subtitles-videos.svg';
import MockeupVoiceAuth from '../../assets/images/Voice_Authentication.png';
import VoiceToText from '../../assets/images/Ic-EasyConvertVoiceToText.svg';
import RtsttMockup from '../../assets/images/Realtime_Speech_to_Text.png';
import SEDMockup from '../../assets/images/Sound_Event_Detection.png';
import IconRtstt from '../../assets/images/icon.svg';
import IconSED from '../../assets/images/music_ico.svg';
import MeetingMinutes from '../../assets/images/REALTIME_MEETING_MINUTES.png';
import voiceChatMockup from '../../assets/images/SPEECH_TO_CHAT.png';

import { OverviewBannerImageType, OverviewBannerType } from '../../Type/OverviewBanner';
import { CHARLIMIT } from '../../Constants/Number';

export const OverviewBanner : React.FC<OverviewBannerType> = ({ overviewBannerProp, type }): React.ReactElement => {
  const [imageValue, setImageValue] = useState({} as OverviewBannerImageType);
  const { t } = useTranslation();

  /* useEffect to handle different overview banners */
  useEffect(() => {
    const imageCall = (typeValue: string) => {
      switch (typeValue) {
        case 'autoSummary': setImageValue({
          sideImage: AutoSummarizationMockup,
          headingLogo: [
            ShortenTextSummary,
            FasterResults,
          ],
        });
          break;
        case 'textToSpeech': setImageValue({
          sideImage: MockeupTextSpeech,
          headingLogo: [
            TextVoice,
            FasterResults,
          ],
        });
          break;
        case 'textToQa': setImageValue({
          sideImage: TextToQAMockup,
          headingLogo: [
            FindYourQuestion,
            FasterResults,
          ],
        });
          break;
        case 'videoContentSearch': setImageValue({
          sideImage: VideoSearchMockup,
          headingLogo: [
            SubtitleVideo,
            FasterResults,
          ],
        });
          break;
        case 'voiceChat': setImageValue({
          sideImage: voiceChatMockup,
          headingLogo: [
            IconRtstt,
            FasterResults,
          ],
        });
          break;
        case 'voiceAuthentication': setImageValue({
          sideImage: MockeupVoiceAuth,
          headingLogo: [
            VoiceToText,
            FasterResults,
          ],
        });
          break;
        case 'Realtimespeechtotext': setImageValue({
          sideImage: RtsttMockup,
          headingLogo: [
            IconRtstt,
            FasterResults,
          ],
        });
          break;
        case 'soundeventdetections': setImageValue({
          sideImage: SEDMockup,
          headingLogo: [
            IconSED,
            FasterResults,
          ],
        });
          break;
        case 'realTimeMeeting': setImageValue({
          sideImage: MeetingMinutes,
          headingLogo: [
            IconRtstt,
            FasterResults,
          ],
        });
          break;
        default: break;
      }
    };
    imageCall(type);
  }, [type]);

  return (
    <>
        <Row className="solveYourProblemsContainer">
            <Col xs="12">
                <p className="overview-badge">
                    <span className="badgeView">{ t('Techcard_DetailView_Middle_Overview') }</span>
                </p>
                <h2>{ t('Techcard_DetailView_Middle_Title') }</h2>
            </Col>
        </Row>
        <Row className="solveYourProblemsContainer solveYourProblemsContainerCenter">
                <Col className="mb-30 solveYourProblemsCard" xs="12" sm="6" lg="6">
                    <ListGroup>
                    { overviewBannerProp?.map((overviewData, index) => (
                        <ListGroup.Item key={t(overviewData?.title)}>
                            <span className="item-image">
                                <Image src={Object.keys(imageValue).length > CHARLIMIT.ZERO ? imageValue?.headingLogo[index] : undefined} alt="Icon" />
                            </span>
                            <span className="item-content">
                                <strong>{t(overviewData.title)}</strong>
                                <p>{t(overviewData.discription)}</p>
                            </span>
                        </ListGroup.Item>
                    ))
                    }
                    </ListGroup>
                </Col>
                <Col className="mb-30 solveYourProblemsCard" xs="12" sm="6" lg="6">
                    <div className="solveYourProblemsMediaImage">
                        <Image src={imageValue?.sideImage} alt="Icon" />
                    </div>
                </Col>
        </Row>
    </>
  );
};
