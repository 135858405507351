import React, { useState } from 'react';
import {
  Image,
  Form,
  DropdownButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import icMicBlue from '../../assets/images/mic-blue.svg';
import icMicMute from '../../assets/images/ic-mic-mute.svg';
import blueEyeIcon from '../../assets/images/blue-eye-icon.svg';
import '../../assets/scss/smallLoader.scss';
import { MicinputOptions, ActionForListCard } from '../../Type/RTMN';
import { REAL_TIME_MEETING_NOTES } from '../../Constants/Static';
import { addBodyClass } from '../../Utils/BodyClass';
import EyeHide from '../../assets/images/ic-eye-hide.svg';

export const UserListing
: React.FC<{
    item: MicinputOptions, optionChoosed?:string, addActionstoTheExistingItem?:(e: any, arg: string, arg1: string, arg2: boolean, val?: string) => void, actionsCreatedOnDeatail: ActionForListCard[] | null, index: number, inputLanguageOptions: string[] | null, hideAndShowUsers: (arg: string) => void, muteUnmuteUser: (arg: string) => void, onKeyUpEvent: (e: any, arg1: string, arg2: string, arg3: string) => void,
}> = ({
  item, index, optionChoosed, actionsCreatedOnDeatail, addActionstoTheExistingItem, inputLanguageOptions, hideAndShowUsers, onKeyUpEvent, muteUnmuteUser,
}): React.ReactElement => {
  const { t } = useTranslation();
  const [showDropdownForList, setShowDropdownForList] = useState(false);
  const checkAction: ActionForListCard | undefined = actionsCreatedOnDeatail?.find((ele) => ele.userId === item.userId);
  const itemToUse: ActionForListCard | MicinputOptions = checkAction ? checkAction : item;
  const handleToggle = (val: boolean) => {
    setShowDropdownForList(val);
    if (optionChoosed !== REAL_TIME_MEETING_NOTES.chatScreen && optionChoosed !== REAL_TIME_MEETING_NOTES.startYourConversation) {
      console.log(1211);
      addBodyClass(val);
    }
  };
  return (
<div className="membersRow">
<div className="membersColEmpty withICon">
    <span className="selectedMicContainer">
      <span className="micCount">{index + 1}</span>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6570_16754)">
          <path d="M14.9999 1.25391C15.8207 1.25391 16.6334 1.41557 17.3917 1.72966C18.15 2.04375 18.839 2.50412 19.4193 3.08449C19.9997 3.66486 20.4601 4.35385 20.7742 5.11213C21.0883 5.87042 21.2499 6.68314 21.2499 7.50391V12.5039C21.2499 14.1615 20.5914 15.7512 19.4193 16.9233C18.2472 18.0954 16.6575 18.7539 14.9999 18.7539C13.3423 18.7539 11.7526 18.0954 10.5805 16.9233C9.40839 15.7512 8.74991 14.1615 8.74991 12.5039V7.50391C8.74991 5.8463 9.40839 4.25659 10.5805 3.08449C11.7526 1.91239 13.3423 1.25391 14.9999 1.25391ZM3.81866 13.7539H6.33741C6.64028 15.8349 7.68225 17.7373 9.2727 19.113C10.8632 20.4887 12.8958 21.2458 14.9987 21.2458C17.1016 21.2458 19.1342 20.4887 20.7246 19.113C22.3151 17.7373 23.357 15.8349 23.6599 13.7539H26.1799C25.8957 16.2898 24.7582 18.6538 22.9539 20.4583C21.1497 22.2628 18.7858 23.4006 16.2499 23.6852V28.7539H13.7499V23.6852C11.2138 23.4009 8.84964 22.2632 7.04513 20.4587C5.24062 18.6542 4.10293 16.29 3.81866 13.7539Z" fill={itemToUse.color} />
        </g>
        <defs>
          <clipPath id="clip0_6570_16754">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
</div>
<div className="membersCol membersSpeakerNameField">
    <span className="members-thead">
    <div className="membersCol membersSpeakerName">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_SpeakerName')}</div>
    </span>
    <Form.Group controlId="formBasictext">
    <Form.Control
      placeholder={itemToUse.nameGiven || itemToUse.label}
      onKeyUp={(e) => {
        if (addActionstoTheExistingItem) {
          addActionstoTheExistingItem(e, itemToUse.userId, 'nameGiven', false, (e.target as HTMLInputElement).value);
        } else {
          onKeyUpEvent(e, itemToUse.userId, 'nameGiven', (e.target as HTMLInputElement).value);
        }
      }} />
    </Form.Group>
</div>
<div className="membersCol membersLanguageModelField">
    <span className="members-thead">
    <div className="membersCol membersLanguageModel">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_languageModal')}</div>
    </span>
<DropdownButton
  show={showDropdownForList}
  align="end"
  title={itemToUse.language}
  id={`dropdown-menu-align-end-${index}`}
  className="DropdownWithOverLay SpeakerBtn"
  onToggle={(value) => { handleToggle(value); }}
  >
  <div className="dropdwon-scroll speaker-dropdown">
    { inputLanguageOptions?.map((modal: string) => (
    <label
      key={`${modal}${index}`}
      className={`${itemToUse.language === modal ? 'checkbox-container active' : 'checkbox-container'}`}
      onClick={(e) => {
        if (addActionstoTheExistingItem) {
          addActionstoTheExistingItem(e, itemToUse.userId, 'language', false, modal); handleToggle(false);
        } else {
          onKeyUpEvent(e, itemToUse.userId, 'language', modal); handleToggle(false);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (addActionstoTheExistingItem) {
            addActionstoTheExistingItem(event, itemToUse.userId, 'language', false, modal); handleToggle(false);
          } else {
            onKeyUpEvent(event, itemToUse.userId, 'language', modal); handleToggle(false);
          }
        }
      }}
      htmlFor={modal}>
      <input
        id={modal}
        className="radio-custom"
        name={`radio-group-${index}`}
        checked={itemToUse.language === modal}
        type="radio" />
        <span className="checkbox-txt">{modal}</span>
        <span className="checkmark" />
    </label>))}
  </div>
</DropdownButton>

<div className="DropdownBackdrop" />
</div>
<div className="membersCol membersActionsItems">
    <span className="action-item">
    <span
      className="mic-icon"
      onClick={(e) => {
        if (addActionstoTheExistingItem) {
          addActionstoTheExistingItem(e, itemToUse.userId, 'mic', !itemToUse.isMuted);
        } else {
          muteUnmuteUser(itemToUse.userId);
        }
      }}>
        <Image src={itemToUse.isMuted ? icMicMute : icMicBlue} alt="Icon" width="16" height="16" />
    </span>
    <span
      className="view-members"
      onClick={(e) => {
        if (addActionstoTheExistingItem) {
          addActionstoTheExistingItem(e, itemToUse.userId, 'disabled', !itemToUse.isDisabled);
        } else {
          hideAndShowUsers(itemToUse.userId);
        }
      }}>
        <Image src={itemToUse.isDisabled ? EyeHide : blueEyeIcon} alt="Icon" />
    </span>
    </span>
</div>
</div>
  );
};
