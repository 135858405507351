import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Image } from 'react-bootstrap';
import { SuccessModalType } from '../../Type/Modal';
import './ConfirmationModal.scss';
import iconTickBlue from '../../assets/images/ic-greenTick.svg';
import profileSubmittedIcon from '../../assets/images/iconTickBlue.svg';

export const SuccessModal: React.FC<SuccessModalType> = ({
  showSuccess, Successclose, messageContent, buttonAction, imagefile,
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
  <Modal
    className="SignInModal successModal"
    show={showSuccess}
    onHide={() => Successclose(false)}
    animation={false}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <div className="outerBox">
        { messageContent?.heading === t('Message.Profile_Submitted') ? <Image src={profileSubmittedIcon} alt="Icon" /> : <Image src={imagefile ? imagefile : iconTickBlue} alt="Icon" /> }
      </div>
<Modal.Title id="contained-modal-title-vcenter">{messageContent?.heading}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <span className="d-block">{messageContent?.description}</span>
      </p>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={() => buttonAction()} className="btnYes">
    {messageContent?.button_text}
    </Button>
    {messageContent.redirect_text && <span className="backToHomeLink" onClick={() => buttonAction(true)}>{t('modals.Success_Modal_Button_Text')}</span> }
    </Modal.Footer>
  </Modal>
  );
};
