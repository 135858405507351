import {
  NAME_REGEX, PASSWORD_REGEX, EMAIL_REGEX, ONLY_DIGIT_REGEX, NUMBER_ONLY_WITH_PLUS, NUMBER_CHARACTERS_ONLY,
} from '../Constants/Index';

export const UserNameValidate = (value: string) => NAME_REGEX.test(value);

export const isValidEmail = (value: string) => EMAIL_REGEX.test(value);

export const isValidPassword = (value: string) => PASSWORD_REGEX.test(value);

export const isValidText = (text: string) => new RegExp(ONLY_DIGIT_REGEX, 'g').test(text);

export const isValidPhone = (text: string) => new RegExp(NUMBER_ONLY_WITH_PLUS, 'g').test(text);

export const isValidId = (text: string) => NUMBER_CHARACTERS_ONLY.test(text);
