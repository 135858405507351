import { HIDE_LOADER, SHOW_LOADER } from '../actionType/loader';

export const loader = (state = false, action: { type: string, payload: unknown } = { type: '', payload: {} }) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LOADER:
      return payload;
    case HIDE_LOADER:
      return payload;
    default:
      return state;
  }
};
