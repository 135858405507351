import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { videoSearchExample } from '../../Redux/actions/videoContentSearch';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { VideoSearchExampleType } from '../../Type/VideoSearch';
import { ExampleClick } from '../../Type/SearchExample';

export const SearchExample: React.FC<ExampleClick> = ({ exampleClick, searchKeyword }) : React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  /* getting searchExamples from redux store (api response) */
  const searchExamples: VideoSearchExampleType[] = useTypedSelector(
    (state) => state.VideoExample?.payload,
  );
  /* api call of searchExamples */
  React.useEffect(() => {
    dispatch(videoSearchExample());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="searchExampleHeader">
      <span className="example-txt">{ t('Techcard_VideoContentsSearch_DetailView_Dropdown_Example_Label') }: </span>
      <div className="example-types">
        {searchExamples && searchExamples?.map((searchExample) => (
        <span onClick={() => exampleClick(searchExample.keyword)} className={`type ${searchKeyword === searchExample.keyword ? 'active' : ''}`}>{searchExample.keyword}</span>
        ))}
      </div>
    </div>
  );
};
