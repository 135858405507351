import { SignUpApi } from '../../Api';
import { hideLoader, showLoader } from './loader';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { SignUpPostType, SignUpReturnType } from '../../Type/SignUp';
import { SIGNUP } from './actionType';

export const signUpApp = (data: SignUpPostType) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await SignUpApi(data) as SignUpReturnType;
    if (resp?.status) {
      dispatch({
        type: SIGNUP,
        payload: resp,
      });
    }
  } catch (error) {
    dispatch({
      type: SIGNUP,
      payload: error,
    });
  }
  dispatch(hideLoader());
};

export const clearSignupState = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: SIGNUP,
    payload: {},
  });
};
