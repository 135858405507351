export const AUTO_SUMMARIZATION = {
  EXAMPLE: 'AUTO_SUMMARIZATION_EXAMPLE',
  GENERATE: 'AUTO_SUMMARIZATION',
};
export const SIGNIN = 'SIGNIN';
export const SIGN_OUT = 'SIGN_OUT';
export const TTS = {
  EXAMPLE: 'TTS_EXAMPLE',
  GENERATE: 'TTS_GENERATE',
};
export const PERMISSIONS = 'PERMISSIONS';
export const TEXTQA = {
  EXAMPLE: 'TTQ_EXAMPLE',
  ANSWER: 'TTQ_ANSWER',
};
export const VIDEOSEARCH = {
  EXAMPLE: 'VCS_EXAMPLE',
  SEARCH_RESULT: 'SEARCH_RESULT',
  SEARCH_RESULT_COUNT: 'SEARCH_RESULT_COUNT',
};
export const SIGNUP = 'SIGNUP';
export const FORGETPASSWORD = 'FORGETPASSWORD';

export const VOICE_AUTHENTICATION = {
  EXAMPLE_SENTENCE: 'VA_EXAMPLE',
  EXAMPLE_AUDIO: 'VA_AUDIO',
  ENROLL_AUDIO: 'ENROLL_AUDIO',
  AUTH_AUDIO: 'AUTH_AUDIO',
};

export const RESETPASSWORD = 'RESETPASSWORD';

export const VERIFIACTIOCODE = 'VERIFIACTIOCODE';

export const SSE = 'SSE';
export const AUDIOSEND = 'RESETPASSWORD';

export const LANGUAGEMODAL = 'LANGUAGEMODAL';

export const AUDIOEXAMPLE = 'AUDIOEXAMPLE';

export const PROFILE = {
  VIEW_PROFILE: 'VIEWPROFILE',
  EDIT_PROFILE: 'EDITPROFILE',
  CHANGE_PASSWORD: 'CHANGEPASSWORD',
  DELETE_ACCOUNT: 'DELETEACCOUNT',
};
