//This file contains constans urls used in the application.
export const url = {
  BLOG: 'https://actionpower.medium.com/',
  RECRUIT: 'https://actionpower.notion.site/ACTIONPOWER-Recruit-9d4fb6a9179841f9ab5bc01d33e8946e',
  PRIVACY_POLICY: 'https://actionpower.notion.site/Privacy-Policy-143977ab2f8d4185af382c4034eb4658',
  SERVICE_TERM: 'https://actionpower.notion.site/Terms-of-Use-dc4269a354a74fcdada141158d77e0d7',
  COMPANY: 'https://actionpower.kr/',
  MAIL_US: 'mailto:partnership@actionpower.kr',
  YOUTUBE: 'https://www.youtube.com/watch',
};

export const pageUrl = {
  home: '/',
};
