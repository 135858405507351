import React from 'react';
import { Container } from 'react-bootstrap';
import '../../assets/scss/loader.scss';
import { useTranslation } from 'react-i18next';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { RealTimeSpeechTOTexts } from '../../Components/RealTimeSpeechTOText/RealTimeSpeechTOTexts';
import { scrollUp } from '../../Utils/ScrollTo';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';

export const RealTimeSpeechTOText: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  React.useEffect(() => {
    scrollUp();
  }, []);

  return (
    <>
        <Container className="AutoSummarizationContainer RealTimeSpeechToTextContainer">
          <BreadcrumbBar name={t('Breadcrumb.RealtimeSpeechToTextName')} url={pageUrl.home} />
          <h1>
            <span>{t('Techcard_RealtimeSpeechtoText_Title')}</span>
            <span className="btnDemo">{t('Techcard_RealtimeSpeechtoText_ButtonText')}</span>
          </h1>
          <p className="heading-txt">
            {t('Techcard_RealtimeSpeechtoText_DetailView_Desc')}
            <br />
            {t('Techcard_RealtimeSpeechtoText_DetailView_Desc2')}
          </p>
         <RealTimeSpeechTOTexts />
         <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.realtime_speech_to_text} type="Realtimespeechtotext" />
        </Container>
        <CompanyLogoBanner />
    </>
  );
};
