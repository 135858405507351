//This file contains tech card keys.
export const TECH_CARD_CONTENT = [
  {
    title: 'Techcard_Autosummary_Title',
    discription: 'Techcard_Autosummary_Desc',
    buttonText: 'Techcard_Autosummary_ButtonText',
    url: '/auto-summerization',
    label: 'summary',
    isMemberOnly: false,
    index: 1,
  },
  {
    title: 'Techcard_TexttoSpeech_Title',
    discription: 'Techcard_TexttoSpeech_Desc',
    buttonText: 'Techcard_TexttoSpeech_ButtonText',
    url: '/text-to-speech',
    isMemberOnly: true,
    label: 'tts',
    index: 2,
  },
  {
    title: 'Techcard_VoiceAuthentication_Title',
    discription: 'Techcard_VoiceAuthentication_Desc',
    buttonText: 'Techcard_VoiceAuthentication_ButtonText',
    url: '/voice-authentication',
    isMemberOnly: true,
    label: 'sv',
    index: 3,
  },
  {
    title: 'Techcard_SoundEventDetection_Title',
    discription: 'Techcard_SoundEventDetection_Desc',
    buttonText: 'Techcard_SoundEventDetection_ButtonText',
    url: '/sound-event-detection',
    isMemberOnly: false,
    label: 'sed',
    index: 4,
  },
  {
    title: 'Techcard_Text_QnA_Title',
    discription: 'Techcard_Text_QnA_Desc',
    buttonText: 'Techcard_Text_QnA_ButtonText',
    url: '/text-to-qa',
    isMemberOnly: true,
    label: 'textqa',
    index: 5,
  },
  {
    title: 'Techcard_VideoContentsSearch_Title',
    discription: 'Techcard_VideoContentsSearch_Desc',
    buttonText: 'Techcard_VideoContentsSearch_ButtonText',
    url: '/video-Search',
    isMemberOnly: false,
    label: 'yourean',
    index: 6,
  },
  {
    title: 'Techcard_RealtimeSpeechtoText_Title',
    discription: 'Techcard_RealtimeSpeechtoText_Desc',
    buttonText: 'Techcard_RealtimeSpeechtoText_ButtonText',
    url: '/realtime-speech-to-text',
    isMemberOnly: false,
    label: 'rtstt',
    index: 7,
  },
  {
    title: 'Techcard_SpeechtoChat_Title',
    discription: 'Techcard_SpeechtoChat_Desc',
    buttonText: 'Techcard_SpeechtoChat_ButtonText',
    url: '/voice-chat',
    isMemberOnly: false,
    label: 'rtstc',
    index: 8,
  },
  {
    title: 'Techcard_RealtimeMeetingMinutes_Title',
    discription: 'Techcard_RealtimeMeetingMinutes_Desc',
    buttonText: 'Techcard_RealtimeMeetingMinutes_ButtonText',
    url: '/realtime-meeting',
    isMemberOnly: false,
    label: 'rtstm',
    index: 9,
  },
];

export const LABEL_CARD = {
  as: 'summary',
  tts: 'tts',
  sv: 'sv',
  sed: 'sed',
  tqa: 'textqa',
  vcs: 'yourean',
  rtstt: 'rtstt',
  vc: 'vc',
  rtctt: 'rtctt',
  profile: 'profile',
};
