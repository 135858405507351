/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Col,
  Card,
  Image,
  Button,
  DropdownButton,
  Dropdown,
  // Form,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import audioBufferToWav from 'audiobuffer-to-wav';
import { Trans, useTranslation } from 'react-i18next';
import { UserListing } from './userListing';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { ChatRoom } from './chatRoom';
import './realTimeMeetingMinutes.scss';
import '../../assets/scss/loader.scss';
import { REAL_TIME_MEETING_NOTES, CHAT_COLOR_CODE } from '../../Constants/Static';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
// import { useAudioRecorder } from '../../realtimeAudioChunck';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import placeHolder01 from '../../assets/images/placeHolder-01.svg';
import placeHolder02 from '../../assets/images/placeHolder-02.svg';
import icMicBlue from '../../assets/images/mic-blue.svg';
import icMicMute from '../../assets/images/ic-mic-mute.svg';
import icMember from '../../assets/images/ic-Member.svg';
import StartYourConversation from '../../assets/images/StartYourConversation-placeholder.svg';
import { addBodyClass } from '../../Utils/BodyClass';
import { rtmmavailableLanuge } from '../../Redux/actions/RealTimeMeetingMinutes';
import { getLocalItem, clearLocal, setToken } from '../../Utils/LocalStorage';
import { sendAudioAppRTMM } from '../../Redux/actions/Realtimetextchatminutes';
import { CraeteId, exportHelper } from '../../Utils/DevHelpers';
import '../../assets/scss/smallLoader.scss';
import { scrollUp } from '../../Utils/ScrollTo';
import {
  ExampleAudioLanguageStatusType, MicinputOptions, ActionForListCard, messageInstance,
} from '../../Type/RTMN';
import { reltimeMeetingeventAPI } from '../../Constants/Api';
import { MEDIACONSTANTS } from '../../Constants/Number';
import { ConfirmationModal } from '../../Components/Modal/ConfirmationModal';

export const RealTimeMeetingMinutes: React.FC = (): React.ReactElement => {
  // const {
  //   startRecording,
  //   stopRecording,
  //   isRecording,
  //   recordingBlob,
  // } = useAudioRecorder();

  const dispatch = useDispatch<AppDispatch>();
  const [errorMessage, setErrorMessage] = useState('');
  const [optionChoosed, setOptionChoosed] = useState(REAL_TIME_MEETING_NOTES.notSelected);
  const [userCreatedForMicUse, setUserCreatedForMicUse] = useState< MicinputOptions[] | null>(null);
  const [micOptionsAvailable, setMicOptionsAvailable] = useState< MicinputOptions[] | null>(null);
  const [actionsCreatedOnDeatail, setActionsCreatedOnDeatail] = useState< ActionForListCard[] | null>(null);
  const [messageArrayToShow, setMessageArrayToShow] = useState< messageInstance[] | null>(null);
  const [inputLanguageOptions, setInputLanguageOptions] = useState< string[] | null>(null);
  const [audioContext, setAudioContext] = useState <AudioContext | null>(null);
  const [scriptProcessorNode, setScriptProcessorNode] = useState <ScriptProcessorNode | null>(null);
  // const [bufferStore, setBufferStore] = useState <any[] | null>(null);
  const [mediaStream, setMediaStream] = useState <MediaStream | null>(null);
  const [numberOfChannel, setNumberOfChannel] = useState(1);
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStreamAudioSourceNode, setMediaStreamAudioSourceNode] = useState <MediaStreamAudioSourceNode | null>(null);
  const [language, setLanguage] = useState('en-us-vosk-v0.22');
  const [micChoosed, setMicChoosed] = useState< MicinputOptions | null>(null);
  const eventSource = useRef<any | never | unknown>();
  const { t } = useTranslation();

  const setMicrophoneSelected = (val: any) => {
    const item = micOptionsAvailable?.find((item) => item.deviceId === val.deviceId);
    if (item) {
      setMicChoosed(item);
    }
  };

  // const setAudioBufferData = async (buffer: any) => {
  //   await setBufferStore((pre) => {
  //     if (pre) {
  //       return pre.map((item, i) => Float32Array.of(...item, ...buffer[i]));
  //     }
  //     return buffer;
  //   });
  // };

  const startRecording = async () => {
    const settingIsRecordingTrue = true;
    await setIsRecording(true);
    if (audioContext === null) return;
    setScriptProcessorNode(null);
    const scpNode = audioContext.createScriptProcessor(
      MEDIACONSTANTS.BUFFER_LEN,
      numberOfChannel,
      numberOfChannel,
    );
    scpNode.onaudioprocess = async (event) => {
      if (!isRecording || !settingIsRecordingTrue || !userCreatedForMicUse) return;
      const audioBuffer = event.inputBuffer; // raw audio data
      if (MEDIACONSTANTS.SAMPLE_RATE !== audioContext.sampleRate) {
        // Resampling
        const offlineAudioContext = new OfflineAudioContext({
          numberOfChannels: numberOfChannel,
          length: MEDIACONSTANTS.SAMPLE_RATE * audioBuffer.duration,
          sampleRate: MEDIACONSTANTS.SAMPLE_RATE,
        });
        const offlineAudioBufferSourceNode = offlineAudioContext.createBufferSource();
        offlineAudioBufferSourceNode.buffer = audioBuffer;
        offlineAudioBufferSourceNode.connect(offlineAudioContext.destination);
        offlineAudioBufferSourceNode.start();
        offlineAudioContext.startRendering().then(async (renderedAudioBuffer) => {
          const buffer = [];
          for (let channel = 0; channel < numberOfChannel; channel += 1) {
            buffer.push(renderedAudioBuffer.getChannelData(channel));
          }
          // setAudioBufferData(buffer);
          const res = await exportHelper(buffer, numberOfChannel);
          for (let i = 0; i < res.length; i += 1) {
            sendFileAudio(res[i], userCreatedForMicUse[i]);
          }
        });
      } else {
        const buffer = [];
        for (let channel = 0; channel < numberOfChannel; channel += 1) {
          buffer.push(audioBuffer.getChannelData(channel)); // get audio of a single channel
        }
        // setAudioBufferData(buffer);
        const res = await exportHelper(buffer, numberOfChannel);
        for (let i = 0; i < res.length; i += 1) {
          sendFileAudio(res[i], userCreatedForMicUse[i]);
        }
      }
    };
    mediaStreamAudioSourceNode?.connect(scpNode);
    scpNode.connect(audioContext.destination);
    setScriptProcessorNode(scpNode);
  };

  const stopRecording = async () => {
    // const res = await exportHelper(bufferStore, numberOfChannel);
    // for (let i = 0; i < res.length; i += 1) {
    //   sendFileAudio(res[i], userCreatedForMicUse[i]);
    // }
    setIsRecording(false);
    scriptProcessorNode?.disconnect();
  };

  const addActionstoTheExistingItem = (e: any, userId: string, actionName: string, bool: boolean, value?:string) => {
    const test = userCreatedForMicUse?.find((obj) => obj.userId === userId);
    if (test) {
      const newStateItem = { ...test };
      let matched = false;
      const newState = actionsCreatedOnDeatail?.map((obj) => {
        if (obj.userId === userId) {
          matched = true;
          if (actionName === 'mic') {
            return { ...obj, isMuted: bool };
          }
          if (actionName === 'disabled') {
            return { ...obj, isDisabled: bool };
          }
          if (actionName === 'nameGiven') {
            return { ...obj, nameGiven: value ? value : obj.nameGiven };
          }
          if (actionName === 'language') {
            return { ...obj, language: value ? value : obj.language };
          }
        }
        return obj;
      });
      if (!matched) {
        newState?.push({ ...newStateItem, actionName });
      }
      newState ? setActionsCreatedOnDeatail(newState) : setActionsCreatedOnDeatail([{ ...newStateItem, actionName }]);
    }
    e.preventDefault();
  };

  const hideDropDown = () => {
    const element = document.getElementById('DropdownBackdrop');
    addBodyClass(false);
    element?.click();
  };

  const applyChanges = () => {
    if (actionsCreatedOnDeatail) {
      actionsCreatedOnDeatail.map((item) => {
        setUserCreatedForMicUse((pre) => {
          if (pre) {
            return pre.map((one) => {
              if (one.userId === item.userId) {
                return {
                  ...one, isDisabled: item.isDisabled, isMuted: item.isMuted, nameGiven: item.nameGiven, language: item.language,
                };
              }
              return one;
            });
          }
          return null;
        });
        return item;
      });
    }
    hideDropDown();
  };

  const discardChanges = () => {
    setActionsCreatedOnDeatail(null);
    hideDropDown();
  };

  const hideAndShowUsers = (userId: string) => {
    try {
      const newState = userCreatedForMicUse?.map((obj) => {
        if (obj.userId === userId) {
          return { ...obj, isDisabled: !obj.isDisabled };
        }
        return obj;
      });
      newState ? setUserCreatedForMicUse(newState) : setUserCreatedForMicUse(null);
    } catch (err) {
      setErrorMessage('Show hide user error..!');
    }
  };

  const muteUnmuteUser = (userId: string) => {
    try {
      const newState = userCreatedForMicUse?.map((obj) => {
        if (obj.userId === userId) {
          return { ...obj, isMuted: !obj.isMuted };
        }
        return obj;
      });
      newState ? setUserCreatedForMicUse(newState) : setUserCreatedForMicUse(null);
    } catch (err) {
      setErrorMessage('mute unmute user error..!');
    }
  };

  const onKeyUpEvent = (e: any, userId: string, keyToUpdate: string, value: string) => {
    e.preventDefault();
    const newState = userCreatedForMicUse?.map((obj) => {
      if (obj.userId === userId) {
        return { ...obj, [keyToUpdate]: value };
      }
      return obj;
    });
    newState ? setUserCreatedForMicUse(newState) : setUserCreatedForMicUse(null);
  };

  const exitMeeting = () => {
    eventSource.current?.close();
    setConfrimationModal(false);
    // setOptionChoosed(REAL_TIME_MEETING_NOTES.startYourConversation);
    setOptionChoosed(REAL_TIME_MEETING_NOTES.notSelected);
    clearLocal('roomId');
    stopRecording();
    mediaStream?.getAudioTracks().forEach((t) => t.stop());
    audioContext?.suspend().then(() => {
      setAudioContext(null);
    });
  };

  const muteUnmuteAllUser = () => {
    const muted:boolean = userCreatedForMicUse?.find((ele) => ele.isMuted === false) ? true : false;
    if (userCreatedForMicUse?.length) {
      setUserCreatedForMicUse(userCreatedForMicUse.map((obj) => ({ ...obj, isMuted: muted })));
      if (muted) {
        stopRecording();
      } else {
        startRecording();
      }
    }
  };

  const openConnection = () => {
    let roomId = getLocalItem('roomId');
    if (!roomId) {
      roomId = CraeteId(15);
      setToken('roomId', roomId);
    }
    eventSource.current = new EventSource(`${reltimeMeetingeventAPI}/${roomId}`);

    eventSource.current.addEventListener('text', (event: any) => {
      const parsedEventData = JSON.parse(event.data);
      setMessageArrayToShow((pre) => {
        if (pre) {
          const datatoreturn = pre.map((item) => {
            if (item.userId === parsedEventData.userId && item.type === 'partial') {
              return { ...item, result: `${parsedEventData.result}`, type: 'text' };
            }
            return item;
          });
          return datatoreturn;
        }
        return [parsedEventData];
      });
    });

    eventSource.current.addEventListener('partial', (event: any) => {
      const parsedEventData = JSON.parse(event.data);
      setMessageArrayToShow((pre) => {
        if (pre) {
          let matched = false;
          const datatoreturn = pre.map((item) => {
            if (item.userId === parsedEventData.userId && item.type === 'partial') {
              matched = true;
              return parsedEventData;
            }
            return item;
          });
          if (!matched) {
            datatoreturn.push(parsedEventData);
          }
          return datatoreturn;
        }
        return [parsedEventData];
      });
    });
  };

  const sendFileAudio = async (fileVal: File | Blob | ArrayBuffer, item: MicinputOptions) => {
    const roomId = getLocalItem('roomId') || '';
    const userId = item.userId;
    const model = item.language;
    const formData = new FormData();
    const message = new Blob(
      [JSON.stringify({
        model,
        roomId,
        userId,
        timestamp: Date.now(),
        sampleRate: MEDIACONSTANTS.SAMPLE_RATE,
      })],
      { type: 'application/json' },
    );
    formData.append('message', message);
    // console.log('fileVal', fileVal);
    // console.log('blob', URL.createObjectURL(new Blob([fileVal], { type: 'audio/x-raw' })));
    formData.append('data', new Blob([fileVal], { type: 'audio/x-raw' }));
    if (!item.isMuted) {
      dispatch(sendAudioAppRTMM(formData, model, roomId, userId));
    }
  };

  useEffect(() => {
    scrollUp();
  }, []);

  useEffect(() => {
    (async () => {
      if (optionChoosed === REAL_TIME_MEETING_NOTES.hardWareCountScreen) {
        const resOfLanguage = await rtmmavailableLanuge() as ExampleAudioLanguageStatusType;
        if (resOfLanguage.status) {
          setInputLanguageOptions(resOfLanguage.models);
        }
      }

      if (optionChoosed === REAL_TIME_MEETING_NOTES.chatScreen) {
        openConnection();
        startRecording();
        // userCreatedForMicUse?.find((ele) => ele.isMuted === false) ? startRecording() : stopRecording();
      }
    })();
    // eslint-disable-next-line
  }, [optionChoosed]);

  useEffect(() => {
    if (optionChoosed === REAL_TIME_MEETING_NOTES.chatScreen) {
      userCreatedForMicUse?.find((ele) => ele.isMuted === false) ? startRecording() : stopRecording();
    }
    // eslint-disable-next-line
  }, [userCreatedForMicUse]);

  const chooseLanguageAndMics = () => {
    if (micChoosed) {
      navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: micChoosed.deviceId } },
      })
        .then((stream) => {
          setMediaStream(stream);
          const ac = new AudioContext();
          setAudioContext(ac);
          const mediaStreamAudioSourceNode = ac.createMediaStreamSource(stream);
          setNumberOfChannel(mediaStreamAudioSourceNode.channelCount);
          setMediaStreamAudioSourceNode(mediaStreamAudioSourceNode);
          const userData = [];
          for (let i = 0; i < mediaStreamAudioSourceNode.channelCount; i++) {
            const output = {
              deviceId: micChoosed.deviceId,
              groupId: micChoosed.groupId,
              label: micChoosed.label,
              nameGiven: `mic ${i + 1}`,
              language,
              color: CHAT_COLOR_CODE[i],
              userId: CraeteId(5),
              isMuted: true,
              isDisabled: false,
            };
            userData.push(output);
          }
          setUserCreatedForMicUse(userData);
        });
      setOptionChoosed(REAL_TIME_MEETING_NOTES.memberSettingsScreen);
    } else {
      setErrorMessage('Please select the microphone');
    }
  };

  const additionalHardware = async () => {
    await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    }).then(() => {
      navigator.mediaDevices.enumerateDevices().then((res) => {
        const userData = [];
        const mediaItem = res.filter((item) => item.kind === 'audioinput');
        for (let i = 0; i < mediaItem.length; i++) {
          const output = {
            deviceId: mediaItem[i].deviceId,
            groupId: mediaItem[i].groupId,
            label: mediaItem[i].label,
            nameGiven: `mic ${i + 1}`,
            language,
            color: CHAT_COLOR_CODE[i],
            userId: '',
            isMuted: true,
            isDisabled: false,
          };
          userData.push(output);
        }
        setMicOptionsAvailable(userData);
      });
    }).catch((err) => {
      console.log('err', err);
    });
    setOptionChoosed(REAL_TIME_MEETING_NOTES.hardWareCountScreen);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggle = (value: boolean, modal?: string) => {
    addBodyClass(value);
    setShowDropdown(value);
    if (modal) setLanguage(modal);
  };
  const [showDropdownMic, setShowDropdownMic] = useState(false);
  const handleToggleMic = (value: boolean, modal?: string) => {
    addBodyClass(value);
    setShowDropdownMic(value);
    if (modal) setMicrophoneSelected(modal);
  };

  return (
        <>
        <Container className="AutoSummarizationContainer RealTimeMeetingContainer">
          <BreadcrumbBar name={t('Breadcrumb.RealtimeMettingMinutesName')} url={pageUrl.home} />
          <h1>
            <span>{t('Techcard_RealtimeMeetingMinutes_DetailView_Title')}</span>
            <span className="btnDemo">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText')}</span>
          </h1>
          <p className="heading-txt" id="heading-txt">
            {t('Techcard_RealtimeMeetingMinutes_DetailView_Desc')}
            <br />
            {t('Techcard_RealtimeMeetingMinutes_DetailView_Desc1')}
          </p>
          <div className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard realTimeMeetingMCard" xs="12" sm="12" lg="12">
              <Card className="realTimeMeeting">
               {optionChoosed === REAL_TIME_MEETING_NOTES.notSelected && (
                <div className="box-empty-state">
                  <div className="empty-box-image">
                    <span className="col-50 img1">
                      <Image src={placeHolder01} alt="Icon" />
                    </span>
                    <span className="col-50 img2">
                      <Image src={placeHolder02} alt="Icon" />
                    </span>
                  </div>
                  <strong className="empty-title">{t('Techcard_RealtimeMeetingMinutes_DetailView_title')}</strong>
                  <p className="empty-txt">
                    <span className="d-block">{t('Techcard_RealtimeMeetingMinutes_DetailView_subTitle')}</span>
                    <span className="d-block">{t('Techcard_RealtimeMeetingMinutes_DetailView_subTitile1')}</span>
                  </p>
                  <div className="call-to-action-wrap">
                    <Button className="ContinueBtn cursor" onClick={() => additionalHardware()}>{t('Techcard_RealtimeMeetingMinutes_DetailView_Continue_Button')}</Button>
                  </div>
                </div>
               )}
                {optionChoosed === REAL_TIME_MEETING_NOTES.hardWareCountScreen && (
                <div className="box-empty-state MicrophoneSettingsBox">
                  <div className="ImageBox">
                    <span className="outlineBox size45">
                      <Image src={icMicBlue} alt="Icon" />
                    </span>
                  </div>
                  <strong className="empty-title">{t('Techcard_RealtimeMeetingMinutes_DetailView_Microphone_Setting')}</strong>
                  <p className="empty-txt">
                    <span className="d-block">{t('Techcard_RealtimeMeetingMinutes_DetailView_helpingText')}</span>
                  </p>
                  <div className="customFormUI">
                  {errorMessage && (<div className="errorMsgBox"><span>{errorMessage}</span></div>)}
                  {/* <Form.Group className="form-group withArrow">
                      <FloatingLabel tabIndex={-3} label={t('Techcard_RealtimeMeetingMinutes_DetailView_NumberOfMics')}>
                      <Form.Select onChange={(e) => setMicrophoneSelected(e.target.value)} aria-label="Floating label select example" className="form-control value-selected">
                        <option style={{ display: 'none' }}>{micChoosed === null ? t('Techcard_RealtimeMeetingMinutes_DetailView_Choose_Microphones') : micChoosed.label}</option>
                        {micOptionsAvailable?.map((one) => (
                          <option key={one.deviceId} value={one.deviceId}>{one.label}</option>
                        ))}
                      </Form.Select>
                      </FloatingLabel>
                  </Form.Group> */}
                  <span>
                      <DropdownButton
                        show={showDropdownMic}
                        align="end"
                        title={`${micChoosed ? micChoosed.label : t('Techcard_RealtimeMeetingMinutes_DetailView_Choose_Microphones')}`}
                        id="dropdown-menu-align-top"
                        className="DropdownWithOverLay SpeakerBtn customSelectDropDown choose-mic"
                        onToggle={(value) => handleToggleMic(value)}
                        >
                          <div className="dropdwon-scroll speaker-dropdown height-none">
                            { micOptionsAvailable?.map((modal: any) => (
                            <label key={modal.label} className={`${micChoosed?.label === modal.label ? 'checkbox-container active' : 'checkbox-container'}`} onClick={() => handleToggleMic(false, modal)} onKeyDown={(event) => event.key === 'Enter' && handleToggleMic(false, modal)} htmlFor={modal.label}>
                              <input id={modal.label} className="radio-custom" name="radio-group" checked={micChoosed?.deviceId === modal.deviceId} type="radio" onChange={() => handleToggleMic(false, modal)} />
                                <span className="checkbox-txt">{modal.label}</span>
                                <span className="checkmark" />
                            </label>))}
                          </div>
                      </DropdownButton>
                      <div className="DropdownBackdrop" />
                  </span>

                  {/* <Form.Group className="form-group withArrow"> */}
                    <span>
                      {/* <FloatingLabel tabIndex={-3} label={t('Techcard_RealtimeMeetingMinutes_DetailView_Default_Language')}> */}
                      {/* <Form.Select defaultValue={language} onChange={(e) => setLanguage(e.target.value)} aria-label="Floating label select example" className="form-control value-selected">
                      <option style={{ display: 'none' }}>{language ? language : 'Choose language'}</option>
                      { inputLanguageOptions?.map((modal: string) => (
                                <option key={modal} value={modal}>{modal}</option>
                      ))}
                      </Form.Select> */}
                      <DropdownButton
                        show={showDropdown}
                        align="end"
                        title={`${language ? language : 'en-us-vosk-v0.22'}`}
                        id="dropdown-menu-align-top"
                        className="DropdownWithOverLay SpeakerBtn customSelectDropDown default-language-model"
                        onToggle={(value) => handleToggle(value)}
                        >
                          <div className="dropdwon-scroll speaker-dropdown height-none">
                            { inputLanguageOptions?.map((modal: string) => (
                            <label key={modal} className={`${language === modal ? 'checkbox-container active' : 'checkbox-container'}`} onClick={() => handleToggle(false, modal)} onKeyDown={(event) => event.key === 'Enter' && handleToggle(false, modal)} htmlFor={modal}>
                              <input id={modal} className="radio-custom" name="radio-group" checked={language === modal} type="radio" onChange={() => handleToggle(false, modal)} />
                                <span className="checkbox-txt">{modal}</span>
                                <span className="checkmark" />
                            </label>))}
                          </div>
                      </DropdownButton>
                      <div className="DropdownBackdrop" />
                    </span>
                      {/* </FloatingLabel> */}
                  {/* </Form.Group> */}
                  </div>
                  <div className="call-to-action-wrap">
                    <Button className="ContinueBtn cursor" onClick={() => chooseLanguageAndMics()}>{t('Techcard_RealtimeMeetingMinutes_DetailView_Continue_Button')}</Button>
                  </div>
                </div>
                )}
                 {optionChoosed === REAL_TIME_MEETING_NOTES.memberSettingsScreen && (
                  <div className="box-empty-state MemberSettingsBox">
                  <div className="ImageBox">
                    <span className="outlineBox size45">
                      <Image src={icMember} alt="Icon" />
                    </span>
                  </div>
                  <strong className="empty-title">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_MemberSetting')}</strong>
                  <div className="members-table">
                      <div className="members-thead">
                        <div className="membersColEmpty" />
                        <div className="membersCol membersSpeakerName">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_SpeakerName')}</div>
                        <div className="membersCol membersLanguageModel">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_languageModal')}</div>
                        <div className="membersCol membersActions">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_Action')}</div>
                      </div>
                      <div className="members-tbody">
                        <div className="members-tbody-scroll">
                  {userCreatedForMicUse && (
                    userCreatedForMicUse.map((item, index) => (
                      <UserListing
                        actionsCreatedOnDeatail={actionsCreatedOnDeatail}
                        inputLanguageOptions={inputLanguageOptions}
                        key={item.userId}
                        item={item}
                        index={index}
                        hideAndShowUsers={hideAndShowUsers}
                        muteUnmuteUser={muteUnmuteUser}
                        onKeyUpEvent={onKeyUpEvent}
                      />
                    ))
                  )}
                        </div>
                      </div>
                  </div>
                  <div className="call-to-action-wrap">
                    <Button className="ContinueBtn cursor" onClick={() => setOptionChoosed(REAL_TIME_MEETING_NOTES.startYourConversation)}>{t('Techcard_RealtimeMeetingMinutes_DetailView_Continue_Button')}</Button>
                  </div>
                  </div>
                 )}

                 {(optionChoosed === REAL_TIME_MEETING_NOTES.startYourConversation || optionChoosed === REAL_TIME_MEETING_NOTES.chatScreen) && (
                <div className="box-empty-state StartYourConversation">
                  <Card.Header>
                    <span className="title-card">{t('Techcard_RealtimeMeetingMinutes_DetailView_MeetingText')}</span>
                    <span className="title-action-btn SettingsBtnDropDown">
                        <DropdownButton
                          align="end"
                          title={t('Techcard_RealtimeMeetingMinutes_DetailView_SettingText')}
                          id="dropdown-menu-align-end"
                          className="DropdownWithOverLay"
                          onToggle={(value) => { addBodyClass(value); }}
                        >
                            <Dropdown.Header>{t('Techcard_RealtimeMeetingMinutes_DetailView_SettingText')}</Dropdown.Header>
                            <div className="members-table">
                              <div className="members-thead">
                                <div className="membersColEmpty" />
                                <div className="membersCol membersSpeakerName">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_SpeakerName')}</div>
                                <div className="membersCol membersLanguageModel">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_languageModal')}</div>
                                <div className="membersCol membersActions">{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_Action')}</div>
                              </div>
                              <div className="members-tbody">
                                <div className="members-tbody-scroll">
                                {userCreatedForMicUse && (
                                  userCreatedForMicUse.map((item, index) => (
                                    <UserListing
                                      addActionstoTheExistingItem={addActionstoTheExistingItem}
                                      actionsCreatedOnDeatail={actionsCreatedOnDeatail}
                                      inputLanguageOptions={inputLanguageOptions}
                                      key={item.userId}
                                      item={item}
                                      index={index}
                                      hideAndShowUsers={hideAndShowUsers}
                                      muteUnmuteUser={muteUnmuteUser}
                                      onKeyUpEvent={onKeyUpEvent}
                                      optionChoosed={optionChoosed}
                                    />
                                  ))
                                )}
                                </div>

                              </div>
                            </div>
                            <div className="dropdDownFooter">
                              <Button className="DiscardBtn" onClick={() => discardChanges()}>{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_Discard')}</Button>
                              <Button className="ApplyBtn" onClick={() => applyChanges()}>{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_Apply')}</Button>
                            </div>
                        </DropdownButton>
                        <div className="DropdownBackdrop " id="DropdownBackdrop" />
                    </span>
                  </Card.Header>
                  {(optionChoosed === REAL_TIME_MEETING_NOTES.startYourConversation) ? (
                  <div className="placeholderBox">
                    <div className="ImageBox cursor">
                      <Image src={StartYourConversation} onClick={() => setOptionChoosed(REAL_TIME_MEETING_NOTES.chatScreen)} alt="Icon" />
                    </div>
                    <strong className="empty-title mt-0 mb-0 cursor" onClick={() => setOptionChoosed(REAL_TIME_MEETING_NOTES.chatScreen)}>{t('Techcard_RealtimeMeetingMinutes_DetailView_ButtonText_StartConversation')}</strong>
                  </div>
                  ) : (
                    <ChatRoom
                      userCreatedForMicUse={userCreatedForMicUse}
                      messageArray={messageArrayToShow}
                    />
                  )}
                  <Card.Footer>
                    <div className="action-left">
                      <Button className="MuteAllBtn cursor" onClick={() => muteUnmuteAllUser()}>
                        <span className="icon">
                          <Image src={userCreatedForMicUse?.find((ele) => ele.isMuted === false) ? icMicBlue : icMicMute} alt="Icon" />
                        </span>
                        <span className="ic-txt">{userCreatedForMicUse?.find((ele) => ele.isMuted === false) ? 'Mute All' : 'Unmute All' }</span>
                      </Button>
                    </div>
                    <div className="action-right">
                      <span className="actionBtn">
                        <Button className="ExitMeetingBtn cursor" onClick={() => setConfrimationModal(true)}>{t('Techcard_RealtimeMeetingMinutes_DetailView_Exit_Meeting')}</Button>
                      </span>
                    </div>
                  </Card.Footer>

                </div>
                 )}
              </Card>
            </Col>
            <Col xs="12">
            <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
            </p>
            </Col>
          </div>

          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.real_time_meeting} type="realTimeMeeting" />
        </Container>
        <CompanyLogoBanner />
        <ConfirmationModal
          title={t('Techcard_RealtimeMeetingMinutes_DetailView_ExitMeetingText')}
          text={t('Techcard_RealtimeMeetingMinutes_DetailView_Meeting_Leave_Desc')}
          noText={t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_Cancel')}
          yesText={t('Techcard_RealtimeMeetingMinutes_DetailView_ExitMeetingText')}
          close={setConfrimationModal}
          show={ShowConfirmationModal}
          confirm={exitMeeting} />
        </>
  );
};
