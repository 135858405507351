import { CombinedState, combineReducers } from 'redux';
import { TextQaAnswerType, TextQaExampleType } from '../../Type/TextQa.d';
import { VideoSearchExampleType, VideoSearchContentType } from '../../Type/VideoSearch.d';
import { textQaPostReducer, textQaExampleReducer } from './textQa';
import { permissionsReducer } from './permissions';
import { PermissionType } from '../../Type/Permission.d';
import { ttsExampleReducer, ttsGenerateReducer } from './tts';
import {
  SignInType, forgetPasswordType, resetPasswordStatusType, verifiactioCodeStatusType,
} from '../../Type/Session.d';
import { SignUpReturnType } from '../../Type/SignUp.d';
import {
  signinReducer, forgetPasswordReducer, resetPasswordReducer, verificatioCodeReducer,
} from './sessions';
import { signUpReducer } from './signUp';
import { loader } from './loader';
import { AutoSummarizationResultType } from '../actionType/AutoSummarization';
import {
  ExampleSentenceReducer, ExampleAudioReducer, EnrollAudioReducer, sseReducer, AuthReducer,
} from './vA';
import {
  autoSummarizationExampleReducer,
  autoSummarizationGenerate,
} from './autoSummarization';
import { TtsExample, TtsResult } from '../../Type/Tts';
import {
  ExampleSentenceType, ExampleAudioType, EnrollAudioType, SseType, AuthAudioType,
} from '../../Type/VoiceAuthenticate';
import { Err } from '../../Type/Error';
import { VideoExampleReducer, SearchVideoContentReducer } from './vcs';
import { RtsttAudioExampleReducer, RtsttAudioPostReducer, RtsttLanguageModelReducer } from './RtsttChatMinutesReducer';
import { ExampleAudioLanguageStatusType, ExampleAudioStatusType, SendAudioPostStatusType } from '../../Type/Rtstt';
import {
  changePasswordReducer, deleteAccountReducer, ProfileUpdateReducer, viewProfileReducer,
} from './ProfileReducer';
import {
  changePasswordStatusType, deleteAccountStatusType, profileUpdateStatusType, viewProfileStatusType,
} from '../../Type/Profile';

const reducers = combineReducers({
  autoSummarizationExample: autoSummarizationExampleReducer,
  autoSummarizationGenerate,
  loader,
  signIn: signinReducer,
  ttsExample: ttsExampleReducer,
  ttsResult: ttsGenerateReducer,
  permissions: permissionsReducer,
  textQaAnswer: textQaPostReducer,
  textQaExample: textQaExampleReducer,
  VideoExample: VideoExampleReducer,
  SearchVideoContent: SearchVideoContentReducer,
  SignUp: signUpReducer,
  forgetPassword: forgetPasswordReducer,
  exampleSentence: ExampleSentenceReducer,
  resetPassword: resetPasswordReducer,
  exampleAudio: ExampleAudioReducer,
  enrollAudio: EnrollAudioReducer,
  sseOpen: sseReducer,
  verifiactionCode: verificatioCodeReducer,
  RtsttAudioSend: RtsttAudioPostReducer,
  exampleVoiceModalRtstt: RtsttLanguageModelReducer,
  exampleAudioRtstt: RtsttAudioExampleReducer,
  authAudio: AuthReducer,
  profileData: viewProfileReducer,
  editProfile: ProfileUpdateReducer,
  changePassword: changePasswordReducer,
  deleteAccount: deleteAccountReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        autoSummarizationExample: {
          payload: string[] | AutoSummarizationResultType;
          error: string | null;
        };
        autoSummarizationGenerate: {
          payload: string[] | AutoSummarizationResultType;
          error: string | null;
        };
        loader: never;
        signIn: { payload: Err | string[] | SignInType | forgetPasswordType | resetPasswordStatusType | verifiactioCodeStatusType; error: string | null };
        ttsExample: {
          payload: string[] | TtsExample | TtsResult;
          error: string | null;
        };
        ttsResult: {
          payload: string[] | TtsExample | TtsResult;
          error: string | null;
        };
        permissions: {
          payload: Err | string[] | PermissionType;
          error: string | null;
        };
        textQaAnswer: {
          payload: TextQaAnswerType | Err | string[] | TextQaExampleType[];
          error: string | null;
        };
        textQaExample: {
          payload: TextQaAnswerType | Err | string[] | TextQaExampleType[];
          error: string | null;
        };
        VideoExample: {
          payload: VideoSearchExampleType | VideoSearchContentType | string[];
          error: string | null;
        };
        SearchVideoContent: {
          payload: VideoSearchContentType | VideoSearchExampleType | string[];
          error: string | null;
        };
        SignUp: {
          payload: SignUpReturnType | string[];
          error: string | null;
        };
        forgetPassword: { payload: Err | string[] | SignInType | forgetPasswordType | resetPasswordStatusType | verifiactioCodeStatusType; error: string | null };
        exampleSentence: {
          payload: ExampleSentenceType | ExampleAudioType | EnrollAudioType| SseType | AuthAudioType | string[];
          error: string | null;
        };
        exampleAudio: {
          payload: ExampleSentenceType | ExampleAudioType | EnrollAudioType| SseType | AuthAudioType | string[];
          error: string | null;
        };
        enrollAudio: {
          payload: ExampleSentenceType | ExampleAudioType | EnrollAudioType | SseType | AuthAudioType | string[];
          error: string | null;
        };
        sseOpen: {
          payload: ExampleSentenceType | ExampleAudioType | EnrollAudioType | SseType | AuthAudioType | string[];
          error: string | null;
        };
        resetPassword: { payload: Err | string[] | SignInType | forgetPasswordType | resetPasswordStatusType | verifiactioCodeStatusType; error: string | null }
        verifiactionCode: { payload: Err | string[] | SignInType | forgetPasswordType | resetPasswordStatusType | verifiactioCodeStatusType; error: string | null }
        RtsttAudioSend: { payload: Err | string[] | SendAudioPostStatusType | ExampleAudioLanguageStatusType | ExampleAudioStatusType; error: string | null }
        exampleVoiceModalRtstt: { payload: Err | string[] | SendAudioPostStatusType | ExampleAudioLanguageStatusType | ExampleAudioStatusType; error: string | null }
        exampleAudioRtstt: { payload: Err | string[] | SendAudioPostStatusType | ExampleAudioLanguageStatusType | ExampleAudioStatusType; error: string | null };
        authAudio: {
          payload: ExampleSentenceType | ExampleAudioType | EnrollAudioType | SseType | AuthAudioType | string[];
          error: string | null;
        };
        profileData: { payload: Err | string[] | viewProfileStatusType | profileUpdateStatusType | changePasswordStatusType | deleteAccountStatusType; error: string | null };
        editProfile: { payload: Err | string[] | viewProfileStatusType | profileUpdateStatusType | changePasswordStatusType | deleteAccountStatusType; error: string | null };
        changePassword: { payload: Err | string[] | viewProfileStatusType | profileUpdateStatusType | changePasswordStatusType | deleteAccountStatusType; error: string | null };
        deleteAccount: { payload: Err | string[] | viewProfileStatusType | profileUpdateStatusType | changePasswordStatusType | deleteAccountStatusType; error: string | null };
      }>
    | undefined,
  action: never,
) => reducers(state, action);

export default rootReducer;

export type RootReducer = ReturnType<typeof reducers>;
