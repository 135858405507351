import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { CHARLIMIT } from '../../Constants/Number';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { forgetPasswordApp, verificationCodeApp } from '../../Redux/actions/sessions';
import './SignInForm.scss';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { forgetPasswordType, verifiactioCodeStatusType } from '../../Type/Session';

export const OtpVerification: React.FC<{
  showOptVerifaction: boolean; email: string; otp: string; setEmail: React.Dispatch<React.SetStateAction<string>>; setOpt: React.Dispatch<React.SetStateAction<string>>; OtpClose: React.Dispatch<React.SetStateAction<boolean>>; resetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  showOptVerifaction, email, setEmail, OtpClose, resetOpen, otp, setOpt,
}): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = React.useState(false);
  const [resendOtpSucces, setResendOtpSuccess] = React.useState(false);

  /* function to handle otp submission */
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const otpPayload = {
      code: otp,
      email,
    };
    dispatch(verificationCodeApp(otpPayload));
    e.preventDefault();
  };
  const verifiactioCodeStatus: verifiactioCodeStatusType = useTypedSelector(
    (state) => state?.verifiactionCode?.payload,
  );
  const passwordSuccess: forgetPasswordType = useTypedSelector(
    (state) => state?.forgetPassword?.payload,
  );
  const resendCode = () => {
    setOpt('');
    dispatch(forgetPasswordApp({ email }));
    setResendOtpSuccess(true);
    resetTimer();
  };

  React.useEffect(() => {
    if (passwordSuccess && passwordSuccess?.status && resendOtpSucces) {
      setError(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSuccess]);

  React.useEffect(() => {
    if (verifiactioCodeStatus && verifiactioCodeStatus?.status) {
      OtpClose(false);
      resetOpen(true);
      setError(false);
    } else if (verifiactioCodeStatus && verifiactioCodeStatus?.status === false) setError(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifiactioCodeStatus]);

  const handleClose = () => {
    OtpClose(false);
    setEmail('');
    setOpt('');
    setError(false);
  };

  const [timer, setTimer] = React.useState(CHARLIMIT.SIXTEY);
  const timeOutCallback = React.useCallback(() => setTimer((currTimer) => currTimer - CHARLIMIT.ONE), []);

  React.useEffect(() => {
    if (showOptVerifaction && resendOtpSucces) timer > CHARLIMIT.ZERO && setTimeout(timeOutCallback, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, timeOutCallback, showOptVerifaction, resendOtpSucces]);

  const resetTimer = () => {
    if (!timer) {
      setTimer(CHARLIMIT.SIXTEY);
    }
  };
  React.useEffect(() => {
    if (!timer) {
      setResendOtpSuccess(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
  <Modal
    className="SignInModal EnterVerificationModal"
    show={showOptVerifaction}
    backdrop="static"
    onHide={() => handleClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{t('OTPVerification.Title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
          <span className="d-block">{t('OTPVerification.Desc')}</span>
          <span className="d-block userEmailId">{email}</span>
      </p>
      <Form onSubmit={onFormSubmit} className="customFormUI customOptInput">
      {!verifiactioCodeStatus?.status && error && <div className="errorMsgBox">
        <span>{t('Message.Incorrect_VerificationCode')}</span>
                                                  </div>}
        <Form.Group className="form-group" controlId="formBasicEmail">
          <OtpInput
            numInputs={6}
            isInputNum
            separator={<span style={{ width: '0px' }} />}
            hasErrored
            value={otp}
            shouldAutoFocus
            onChange={(value: string) => setOpt(value)}
          />
        </Form.Group>
        <div className="action-btn">
          <Button variant="primary" disabled={otp.length < CHARLIMIT.SIX} type="submit">{t('OTPVerification.Button_Submit')}</Button>
        </div>
        <div className="resend-wrap">
          <p>
            <span className="resent-txt">{t('OTPVerification.Code_Not_Received')}</span>
            <span className={`${!resendOtpSucces ? 'resendBtn' : 'resendBtn disabled-btn'}`} onClick={() => resendCode()}>{t('OTPVerification.Resend_Code')}</span>
            {timer > CHARLIMIT.ZERO && resendOtpSucces && <span className="otpTImer">{(timer === CHARLIMIT.SIXTEY ? '01:00' : (`00:${timer < CHARLIMIT.TEN ? `0${timer}` : timer}`))}</span>}
          </p>
        </div>

      </Form>
    </Modal.Body>
  </Modal>
  );
};
