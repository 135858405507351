import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../../../assets/images/logo.svg';

import './header.scss';
import { fnSendEmail } from '../../../Utils/SendEmail/sendEmail';
import { SignIn } from '../../../Views/SignIn/SignIn';
import { SignUp } from '../../../Views/SignUp/SignUp';
import { AppDispatch } from '../../../Hooks/useAppDispatch';
import { permissionsAction } from '../../../Redux/actions/permissions';
import { getLocal } from '../../../Utils/LocalStorage';
import { LOGIN_DATA } from '../../../Constants/Static';
import { signOut } from '../../../Redux/actions/sessions';
import { useTypedSelector } from '../../../Hooks/useTypeSelector';
import { SignInType } from '../../../Type/Session';
import { SIGNIN } from '../../../Constants/Api';
import { viewProfileStatusType } from '../../../Type/Profile';

const Header: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  window.history.replaceState({}, document.title);
  const { state } = useLocation() as {state:{path: string}};
  const details = getLocal(LOGIN_DATA);
  const loginDetail: SignInType = useTypedSelector((state) => state.signIn?.payload);
  const tabIndex = 0;
  const tabIndexNone = -1;

  const [radio, setradio] = React.useState('1');
  const [isOpen, setOpen] = React.useState(false);
  const [isSignUpOpen, setSignUpOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [expand, setExpand] = React.useState(false);

  const fnSignOut = () => {
    setExpand(false);
    dispatch(signOut());
    navigate('/');
  };

  const setLanguage = (languagCode: React.SetStateAction<string>) => {
    if (languagCode === '1') {
      i18next.changeLanguage('ko');
    } else if (languagCode === '2') {
      i18next.changeLanguage('en');
    }
    setradio(languagCode);
    hideDropDown();
  };

  React.useEffect(() => {
    if (i18next.language === 'en') {
      setradio('2');
    } else {
      setradio('1');
    }
    dispatch(permissionsAction());
  }, [dispatch]);
  React.useEffect(() => {
    if (state?.path) {
      setOpen(true);
    }
  }, [state?.path]);

  const profileData: viewProfileStatusType = useTypedSelector(
    (state) => state.profileData?.payload,
  );

  React.useEffect(() => {
    if (details?.name) {
      dispatch({
        type: SIGNIN,
        payload: details,
      });
      setName(details?.name);
    } else {
      setName('');
    }
  }, [details, dispatch, loginDetail.name, profileData]);

  const onSignUpClick = () => {
    setOpen(false);
    setSignUpOpen(true);
  };
  const signIn = () => {
    setExpand(false);
    setOpen(true);
  };
  const goToProfile = () => {
    navigate('/profile');
    setExpand(false);
  };
  const openSignup = () => {
    setExpand(false);
    setSignUpOpen(true);
  };

  const langHeaderClose = () => {
    setExpand(false);
  };
  const hideDropDown = () => {
    const element = document.getElementById('responsive-navbar-nav');
    element?.click();
  };

  return (
    <>
    <Navbar collapseOnSelect expand="lg" bg="light" className="navbarContainer" expanded={expand} onToggle={() => setExpand(!expand)}>
      <Container>
        <Navbar.Brand onClick={() => navigate('/')}>
          <Image src={Logo} alt="Icon" width="168" height="40" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" style={{ height: '100px' }}>
          <span className="overlay-menu" />
          <Nav className="accountInfoNav">
            <ul className="list-unstyled">
              <li className="list-item">
                <Button className="BizContactBtn" onClick={fnSendEmail}>{ t('Header_BizContact') }</Button>
              </li>
              <li className="list-item">
                <NavDropdown
                  title={name}
                  id="collasible-nav-dropdown"
                  className={name ? 'accountDropdown loginAccount' : 'accountDropdown'}
                  renderMenuOnMount
                >
                  {!name && <><NavDropdown.Item onClick={() => signIn()}>{ t('Header_SignIn') }</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => openSignup()}>{ t('Header_SignUp') }</NavDropdown.Item>
                            </>}
                  {name && <><NavDropdown.Item onClick={() => goToProfile()}>{ t('Header_Profile') }</NavDropdown.Item>
                  <NavDropdown.Item onClick={fnSignOut}>{ t('Header_SignOut') }</NavDropdown.Item>
                           </>}
                </NavDropdown>
              </li>
              <li className="list-item">
                <NavDropdown
                  title="Language"
                  id="collasible-nav-dropdown"
                  className="languageDropdown"
                  renderMenuOnMount
                >
                  <label tabIndex={tabIndex} onKeyDown={(event) => event.key === 'Enter' && setradio('1')} className={radio === '1' ? 'checkbox-container active' : 'checkbox-container'} htmlFor="radio-1" onClick={() => langHeaderClose()}>
                    <input
                      onChange={() => setLanguage('1')}
                      id="radio-1"
                      className="radio-custom"
                      name="radio-group"
                      type="radio"
                      checked={radio === '1'}
                      tabIndex={tabIndexNone}
                    />
                    <span className="checkbox-txt">{ t('Header_Korean') }</span>
                    <span className="checkmark" />
                  </label>
                  <label tabIndex={tabIndex} onKeyDown={(event) => event.key === 'Enter' && setradio('2')} className={radio === '2' ? 'checkbox-container active' : 'checkbox-container'} htmlFor="radio-2" onClick={() => langHeaderClose()}>
                    <input
                      onChange={() => setLanguage('2')}
                      id="radio-2"
                      className="radio-custom"
                      name="radio-group"
                      type="radio"
                      checked={radio === '2'}
                      tabIndex={tabIndexNone}
                    />
                    <span className="checkbox-txt">{ t('Header_English') }</span>
                    <span className="checkmark" />
                  </label>
                </NavDropdown>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <SignIn isOpen={isOpen} close={setOpen} onSignUpClick={onSignUpClick} />
    <SignUp isOpen={isSignUpOpen} close={setSignUpOpen} />
    </>
  );
};

export default Header;
