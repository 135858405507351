/* eslint-disable no-bitwise */
import React, { useEffect } from 'react';
import {
  Card,
  Image,
  DropdownButton,
  Button,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { partialType } from '../../Type/Rtstt';
import { addBodyClass } from '../../Utils/BodyClass';
import blueEyeIcon from '../../assets/images/blue-eye-icon.svg';
import icEyeHide from '../../assets/images/ic-eye-hide.svg';
import { CHARLIMIT } from '../../Constants/Number';

export const ChatUserList: React.FC<{
   transcript: partialType[], setHideUser: React.Dispatch<React.SetStateAction<string[]>>, hideUser: string[]; roomId: string; userIds: string;
  }> = ({
    transcript, setHideUser, hideUser, roomId, userIds,
  }): React.ReactElement => {
    const [userList, setUserList] = React.useState<string[] | []>([]);
    const [copy, setCopy] = React.useState(false);
    const { t } = useTranslation();
    const { search } = useLocation();
    const params = search?.split('=')[1];
    const chatRoomURl = params ? window.location.href : `${window.location.href}?invite=${roomId}`;

    useEffect(() => {
      const arrayUniqueByKey = transcript.map((item) => item.userId).filter((value, index, self) => (self.indexOf(value) === index));
      setUserList(arrayUniqueByKey);
    }, [transcript]);
    const arrayData: string[] = hideUser.length > 0 ? [...hideUser] : [];
    const handleHideShow = (check: boolean, id:string) => {
      if (check) {
        arrayData.push(id);
        setHideUser(arrayData);
      } else {
        const filterData: string[] = arrayData.filter((item) => item !== id);
        setHideUser(filterData);
      }
    };
    const onCopy = () => {
      setCopy(true);
    };
    const onToggle = (val: boolean) => {
      setCopy(false);
      addBodyClass(val);
    };
    return (
      <Card.Header>
        <span className="title-card">{t('Techcard_SpeechtoChat_DetailView_ChatRoom_Text')} <span className="roomId">({t('Techcard_SpeechtoChat_DetailView_RoomId_Text')}: {roomId})</span> </span>
        <span className="title-action-btn peopleBtnDropDown">
          <DropdownButton
            align="end"
            title={`${userList.length > CHARLIMIT.ZERO ? userList.length : CHARLIMIT.ONE} ${t('Techcard_SpeechtoChat_ChatUserList_Text')}`}
            id="dropdown-menu-align-end"
            className="DropdownWithOverLay"
            onToggle={(value) => { addBodyClass(value); }}
          >
            <div className="peopleContent">
            {userList.length > 0 && userList?.map((userId: string) => (
            <> {
         !hideUser.includes(userId) ? <div className="people-nameAction">
         <span className="peopleName">{userId !== userIds ? userId.slice(0, userId.length - CHARLIMIT.FIVE) : t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_UserListYou')}</span>
      { userId !== userIds && <span className="action" onClick={() => handleHideShow(true, userId)}><Image src={blueEyeIcon} alt="Icon" /></span> }
                                      </div>
           : <div className="people-nameAction peopleDisabled">
         <span className="peopleName">{userId.slice(0, userId.length - CHARLIMIT.FIVE)}</span>
         {userId !== userIds && <span className="action" onClick={() => handleHideShow(false, userId)}><Image src={icEyeHide} alt="Icon" /></span>}
             </div>

          }
            </>
            ))}
            { userList.length === CHARLIMIT.ZERO
              && <div className="people-nameAction">
              <span className="peopleName">{t('Techcard_SpeechtoChat_DetailView_ConfirmationModal_UserListYou')}</span>
                 </div>
            }
            </div>
          </DropdownButton>
          <div className="DropdownBackdrop" />
          <span className="people-btn">
            <DropdownButton
              align="end"
              title="ICON"
              id="dropdown-menu-align-end"
              className="DropdownWithOverLay inviteBtn"
              onToggle={(value) => { onToggle(value); }}
            >
              <div className="invitePeopleContainer">
                <strong className="title">{t('Techcard_SpeechtoChat_DetailView_InvitePeople')}</strong>
                <div className="inviteForm">
                  <span className="invite-label">{t('Techcard_SpeechtoChat_DetailView_Chatroomlink')}</span>
                  <span className="label-link">{`voice-chat?invite=${roomId}`}</span>
                </div>
                <CopyToClipboard text={chatRoomURl} onCopy={onCopy}>
                <Button className="CopyInviteBtn cursor">{copy ? t('Techcard_SpeechtoChat_DetailView_Copied') : t('Techcard_SpeechtoChat_DetailView_CopyInvite')}</Button>
                </CopyToClipboard>
              </div>
            </DropdownButton>
            <div className="DropdownBackdrop" />
          </span>
        </span>
      </Card.Header>
    );
  };
