/* eslint-disable  @typescript-eslint/no-explicit-any */
import { RegionParams } from 'wavesurfer.js/src/plugin/regions';
import { CHARLIMIT } from '../Constants/Number';

export const createFile = async (url:string, type:any, bolbOnly = false) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: type || 'audio/wav',
  };
  if (bolbOnly) return data;
  return new File([data], url, metadata);
};

export const getUrlFileType = (url: string) => {
  const u = new URL(url);
  const ext = u.pathname.split('.').pop();
  return ext === '/'
    ? undefined
    : ext?.toLowerCase();
};

export const secondsToHHMMSS = (sec: number | 0) => {
  try {
    if (!sec) return;
    const numberAvail: number = sec;
    const hours: number = Math.floor(numberAvail / 3600);
    const minutes: number = Math.floor((numberAvail - (hours * 3600)) / 60);
    const seconds: number = numberAvail - (hours * 3600) - (minutes * 60);
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  } catch (err) {
    // eslint-disable-next-line
    console.log('err', err);
    return sec;
  }
};

export const bytesToSize = (bytes: number) => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0 || !bytes) return 'n/a';
  const dataNum: number = Math.log(bytes) / Math.log(1024);
  const dataStr = String(Math.floor(dataNum));
  const i:number = parseInt(dataStr, 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
};

export const waitFotTimeBeforeRetry = (ms: number): Promise<any> => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export const floatFixedNToDigit = (x: number, digit: number) => Number.parseFloat(String(x)).toFixed(digit);

export const prepareDataForHighlight = (data: [[], [], []]) => {
  const result:RegionParams[] = [];
  let regionOptions: RegionParams = { drag: false };
  data[1].map((item, index) => {
    if (regionOptions.start) {
      if (!regionOptions.end) {
        regionOptions.end = (index * 64) / 1000;
        if (item > 0.05 && data[2][index] > 0.05) {
          regionOptions.id = 'both-nosie-and-voice';
          regionOptions.color = 'rgba(197, 194, 255, 0.4)';
          if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color === result[result.length - 1].color) {
            result[result.length - 1].end = regionOptions.end;
          } else if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color !== result[result.length - 1].color) {
            regionOptions.start -= 0.064;
            result.push(regionOptions);
          } else {
            result.push(regionOptions);
          }
          regionOptions = { drag: false };
        } else if (data[2][index] > 0.05) {
          regionOptions.id = 'nosie-only';
          regionOptions.color = 'rgba(171, 223, 254, 0.4)';
          if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color === result[result.length - 1].color) {
            result[result.length - 1].end = regionOptions.end;
          } else if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color !== result[result.length - 1].color) {
            regionOptions.start -= 0.064;
            result.push(regionOptions);
          } else {
            result.push(regionOptions);
          }
          regionOptions = { drag: false };
        } else if (item > 0.05) {
          regionOptions.id = 'voice-only';
          regionOptions.color = 'rgba(191, 245, 235, 0.4)';
          if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color === result[result.length - 1].color) {
            result[result.length - 1].end = regionOptions.end;
          } else if (result.length && (regionOptions.start - (result[result.length - 1].end || 0)) <= 0.065 && regionOptions.color !== result[result.length - 1].color) {
            regionOptions.start -= 0.064;
            result.push(regionOptions);
          } else {
            result.push(regionOptions);
          }
          regionOptions = { drag: false };
        }
      }
    } else {
      regionOptions.start = (index * 64) / 1000;
    }
    return item;
  });
  return result;
};

// const paraseTheCreateInput = (arr: RegionParams[]) => {
//   const result:RegionParams[] = [];
//   arr?.map((item, index) => {
//     if (index === 0) result.push(item);
//     if
//     return item;
//   });
// };

export const CraeteId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = CHARLIMIT.ZERO; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getAudioVideoDuration = (file:any) => {
  const url = URL.createObjectURL(file);
  return new Promise((resolve) => {
    const audio = document.createElement('audio');
    audio.muted = true;
    const source = document.createElement('source');
    source.src = url;
    audio.preload = 'metadata';
    audio.appendChild(source);
    // eslint-disable-next-line func-names
    audio.onloadedmetadata = function () {
      resolve(audio.duration);
    };
  });
};
export const getFileTypeAudioOrVideo = (file:any) => new Promise((resolve) => {
  const videoEl = document.createElement('video');
  videoEl.src = window.URL.createObjectURL(file);
  // When the video metadata has loaded, check
  videoEl.onloadedmetadata = () => {
    window.URL.revokeObjectURL(videoEl.src);
    const { videoWidth } = videoEl;
    resolve(videoWidth);
  };
});

async function encodeRaw(samples:any) {
  const buffer = new ArrayBuffer(samples.length * 2);
  const view = new DataView(buffer);
  const returndata = await floatTo16BitPcm(view, 0, samples);
  return returndata;
}

async function floatTo16BitPcm(output:any, offset:number, input:any) {
  let nextOffset = offset;
  for await (const item of input) {
    const s = Math.max(-1, Math.min(1, item));
    // 0x8000 = 32768, 0x7FFF = 32767
    // 16-bit int: [-32768, 32767]
    output.setInt16(nextOffset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    nextOffset += 2;
  }
  return output;
}

export async function mergeBuffers(buffers:any, length:number) {
  const result = new Float32Array(length);
  let offset = 0;
  for await (const buffer of buffers) {
    result.set(buffer, offset);
    offset += buffer.length;
  }
  return result;
}

export const exportHelper = async (recBuffers: any, numChannels:number) => {
  // const buffers = recBuffers;
  // const length = recBuffers[0].length;
  const data = [];
  let channel = 0;
  for await (const n of Array.from(Array(numChannels))) {
    // const samples = await mergeBuffers(buffers[channel], length);
    const samples = recBuffers[channel];
    const type = 'audio/x-raw';
    const dataview = await encodeRaw(samples);
    const audioBlob = new Blob([dataview], { type });
    data.push(audioBlob);
    channel += 1;
    console.log('n', n);
  }
  return data;
};
