import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { url } from '../../../Constants/Index';
import './footer.scss';

const Footer: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <section className="copyrightSection">
      <Container>
        <Row>
          <Col>
            <div className="copyrightContainer">
              <ul className="list-unstyled links-group-left">
                <li className="list-item">
                  <a href={url.COMPANY} target="_blank" className="link-btn" rel="noreferrer">{ t('Footer_Company') }</a>
                </li>
                <li className="list-item">
                  <a href={t('Service_Terms')} target="_blank" className="link-btn" rel="noreferrer">{ t('Footer_ServiceTerms') }</a>
                </li>
                <li className="list-item">
                  <a href={t('Privacy_Policy')} target="_blank" className="link-btn" rel="noreferrer">{ t('Footer_PrivacyPolicy') }</a>
                </li>
                <li className="list-item">
                  <a href={url.RECRUIT} target="_blank" className="link-btn" rel="noreferrer">{ t('Footer_Recruit') }</a>
                </li>
                <li className="list-item">
                  <a href={url.BLOG} target="_blank" className="link-btn" rel="noreferrer">{ t('Footer_Blog') }</a>
                </li>
              </ul>
              <ul className="list-unstyled links-group-right">
                <li className="list-item">
                  <p className="copyright-txt">{ t('Footer_Copyright') }</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
