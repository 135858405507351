import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmationModalType } from '../../Type/Modal';
import './ConfirmationModal.scss';

export const ConfirmationModal: React.FC<ConfirmationModalType> = ({
  show,
  close,
  confirm,
  title,
  text,
  noText,
  yesText,
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
  <Modal
    className="confirmModal"
    show={show}
    onHide={() => close(false)}
    animation={false}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{title ? title : t('ConfirmationModal.Title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{ text ? text : t('ConfirmationModal.Desc')}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => close(false)} className="btnNo">
        {noText ? noText : t('ConfirmationModal.Button_No')}
      </Button>
      <Button variant="primary" onClick={() => confirm(true)} className="btnYes">
        {yesText ? yesText : t('ConfirmationModal.Button_Yes')}
      </Button>
    </Modal.Footer>
  </Modal>
  );
};
