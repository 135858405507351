import { AUTO_SUMMARIZATION } from '../actions/actionType';
import { AutoSummarizationExampleType, AutoSummarizationResultType } from '../actionType/AutoSummarization';

type State = {
  payload: string[] | AutoSummarizationResultType;
  error: string | null;
}
const initialState = {
  payload: [],
  error: null,
};

export const autoSummarizationExampleReducer = (state: State = initialState, action: AutoSummarizationExampleType = {} as AutoSummarizationExampleType):State => {
  if (action?.type === AUTO_SUMMARIZATION.EXAMPLE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const autoSummarizationGenerate = (state: State = initialState, action: AutoSummarizationResultType = {} as AutoSummarizationResultType):State => {
  if (action?.type === AUTO_SUMMARIZATION.GENERATE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
