import { TtsResultReducerType, TtsExampleReducerType } from '../actionType/Tts';
import { TtsExample, TtsResult } from '../../Type/Tts';
import { TTS } from '../actions/actionType';

type State = {
    payload: string[] | TtsExample | TtsResult;
    error: string | null;
  }
const initialState = {
  payload: [],
  error: null,
};

export const ttsExampleReducer = (state: State = initialState, action: TtsExampleReducerType = {} as TtsExampleReducerType):State => {
  if (action?.type === TTS.EXAMPLE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const ttsGenerateReducer = (state: State = initialState, action: TtsResultReducerType = {} as TtsResultReducerType):State => {
  if (action?.type === TTS.GENERATE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
