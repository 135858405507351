import { VideoSearchExampleApi, searchVideoContentApi } from '../../Api';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { hideLoader, showLoader } from './loader';
import { VideoSearchExampleActionType, searchVideoContentActionType } from '../actionType/VideoSearch';
import { VIDEOSEARCH } from './actionType';

/* action to handle VideoSearchExample get api */
export const videoSearchExample = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await VideoSearchExampleApi() as VideoSearchExampleActionType;
    dispatch({
      type: VIDEOSEARCH.EXAMPLE,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: VIDEOSEARCH.EXAMPLE,
      payload: error,
    });
  }
};

/* action to handle searchVideoContent get api */
export const searchVideoContent = (searchVkeyword: string, pageLimit: number, pageNum: number, searckCallback: () => void) => async (dispatch: AppDispatch) => {
  dispatch(showLoader());
  try {
    const resp = await searchVideoContentApi(searchVkeyword, pageLimit, pageNum) as searchVideoContentActionType;
    dispatch({
      type: VIDEOSEARCH.SEARCH_RESULT,
      payload: resp,
    });
    dispatch(hideLoader());
    return searckCallback && searckCallback();
  } catch (error) {
    dispatch({
      type: VIDEOSEARCH.SEARCH_RESULT,
      payload: [],
    });
    dispatch(hideLoader());
    return searckCallback && searckCallback();
  }
};
