/* eslint-disable react/no-danger */
import React, { useCallback, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  Form,
  Image,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import VoicePlaceholder from '../../assets/images/placeholder-text-n-qa.svg';
import { addBodyClass } from '../../Utils/BodyClass';
import { AutoSummarizationInput } from '../../Type/AutoSumamrization';
import { NUMBER } from '../../Constants/Index';
import { TEXTQA } from '../../Redux/actions/actionType';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { textQaExample, textQaPost, clearQaAnswer } from '../../Redux/actions/textQa';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { TextQaAnswerType, TextQaExampleType, TextQaPostType } from '../../Type/TextQa';
import { Loader } from '../Loader/Loader';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';

export const TextQNA: React.FC<AutoSummarizationInput> = ({
  setConfrimationModal,
  confirm,
}): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const scrollRefs = useRef<null | HTMLDivElement>(null);
  const tabIndex = 0;
  const tabIndexNone = -1;
  const [question, setQuestion] = React.useState('');
  const [context, setcontext] = React.useState('');

  const qaExamples: TextQaExampleType[] = useTypedSelector(
    (state) => state.textQaExample?.payload,
  );
  const qaAnswer: TextQaAnswerType = useTypedSelector((state) => state.textQaAnswer?.payload);
  const isLoading = useTypedSelector((state) => state.loader);

  const textQaExamples = useCallback(() => {
    dispatch(textQaExample());
  }, [dispatch]);

  const fnSubmit = (_context: string, _question: string) => {
    scrollRefs?.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    const postSummary: TextQaPostType = {
      context: _context,
      question: _question,
    };
    dispatch(textQaPost(postSummary));
  };

  React.useEffect(() => {
    textQaExamples();
    dispatch({
      type: TEXTQA.EXAMPLE,
      payload: '',
    });
  }, [textQaExamples, dispatch]);

  React.useEffect(() => {
    dispatch(clearQaAnswer());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (confirm) {
      setConfrimationModal(false);
      setQuestion('');
      setcontext('');
      dispatch(clearQaAnswer());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, setConfrimationModal]);

  const isContext = () => {
    if (context === '') {
      return true;
    }
    return false;
  };

  return (
    <Row className="AutoSummarizationRow">
      <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6">
        <Card className="cardText">
          <Card.Header>
            <span className="title-card">{ t('Techcard_Text_QnA_DetailView_Input_Filed1_Label') }</span>
            <span className="title-action-btn">
              <DropdownButton
                align="end"
                title={t('Techcard_Text_QnA_DetailView_Dropdown_TryExample_Label')}
                id="dropdown-menu-align-end"
                className="DropdownWithOverLay"
                onToggle={(value) => {
                  addBodyClass(value);
                }}
              >
                <Dropdown.Header>{ t('Techcard_Text_QnA_DetailView_Dropdown_Example_Label') }</Dropdown.Header>
                <CustomScrollBar height={NUMBER.THREE_FIFTY_SEVEN}>
                <div className="dropdwon-scroll">
                  {qaExamples && qaExamples?.map((qaExample) => (
                    <Dropdown.Item
                      tabIndex={tabIndex}
                      onKeyDown={(event) => event.key === 'Enter' && setcontext(qaExample?.text)
                      }
                      htmlFor={qaExample?.text}
                      eventKey="2"
                      className={context === qaExample?.text ? 'active' : ''}
                      onClick={() => {
                        setcontext(qaExample?.text);
                      }}
                      key={qaExample?.text}
                    >
                      <span className="d-block" dangerouslySetInnerHTML={{ __html: qaExample?.text?.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                      <input
                        onChange={() => setcontext(qaExample?.text)}
                        id={qaExample?.text}
                        className="radio-custom"
                        name="radio-group"
                        type="radio"
                        checked={context === qaExample?.text}
                        tabIndex={tabIndexNone}
                      />
                    </Dropdown.Item>
                  ))}
                </div>
                </CustomScrollBar>
              </DropdownButton>
              <div className="DropdownBackdrop" />
            </span>
          </Card.Header>
          <Card.Body className="mb-30">
            <Form>
              <Form.Group controlId="formBasictext">
                <Form.Control
                  as="textarea"
                  placeholder={t('Techcard_Text_QnA_DetailView_Input_Field1_Placeholder')}
                  value={context}
                  onChange={(e) => setcontext(e?.target?.value)}
                  autoFocus
                />
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Header>
            <span className="title-card">{ t('Techcard_Text_QnA_DetailView_Input_Filed2_Label') }</span>
            <span className="title-action-btn">
              <DropdownButton
                align="end"
                title={t('Techcard_Text_QnA_DetailView_Dropdown_TryExample_Label')}
                id="dropdown-menu-align-end"
                className="DropdownWithOverLay"
                disabled={isContext()}
                onToggle={(value) => {
                  addBodyClass(value);
                }}
              >
                <Dropdown.Header>{ t('Techcard_Text_QnA_DetailView_Dropdown_Example_Label') }</Dropdown.Header>
                <CustomScrollBar height={NUMBER.TWO_FOURTY_SEVEN}>
                <div className="dropdwon-scroll">
                  {qaExamples && qaExamples?.map((qaExample) => context === qaExample?.text && qaExample?.question?.map((questions) => (
                      <Dropdown.Item
                        eventKey="1"
                        className={question === questions ? 'active' : ''}
                        onClick={() => {
                          setQuestion(questions);
                        }}
                        key={questions}
                    >
                      <span className="d-block">{questions}</span>
                      </Dropdown.Item>
                  )))}
                </div>
                </CustomScrollBar>
              </DropdownButton>
              <div className="DropdownBackdrop" />
            </span>
          </Card.Header>
          <Card.Body className="QuestionInput">
            <Form>
              <Form.Group controlId="formBasictext2">
                <Form.Control
                  type="text"
                  placeholder={t('Techcard_Text_QnA_DetailView_Input_Field2_Placeholder')}
                  value={question}
                  onChange={(e) => setQuestion(e?.target?.value)}
                  readOnly={isContext()}
                />
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer>
            <div className="action-right">
              <Button
                onClick={() => setConfrimationModal(true)}
                className={(context && question && !isLoading.status) || qaAnswer?.sentence ? 'clearBtn cursor ' : 'clearBtn cursor disabled-btn '}
              >
                { t('Techcard_Text_QnA_DetailView_Button_Text_Clear') }
              </Button>
              <Card.Link
                className={
                  context && question && !isLoading?.status
                    ? 'submitBtn cursor '
                    : 'submitBtn cursor disabled-btn '
                }
                onClick={() => {
                  context && question && fnSubmit(context, question);
                }}
              >
                { t('Techcard_Text_QnA_DetailView_Button_Text_Submit') }
              </Card.Link>
            </div>
          </Card.Footer>
        </Card>
      </Col>
      <Col
        className="mb-30 AutoSummarizationCard"
        xs="12"
        sm="6"
        lg="6"
        ref={scrollRefs}
      >
        <Card className="cardSummary">
          <Card.Header className="answer-block-with-question">
            <span className="title-card">{ t('Techcard_Text_QnA_DetailView_Output_Filed_Label') }</span>
            {qaAnswer?.sentence && !isLoading?.status && <Card.Text>{qaAnswer?.answer}</Card.Text>}
            {qaAnswer?.sentence && !isLoading?.status && <span className="title-card">{ t('Techcard_Text_QnA_DetailView_Output_Filed_Label2') }</span>}
          </Card.Header>
          <Card.Body>
            {qaAnswer?.sentence && !isLoading?.status
            && <Card.Text>
              {qaAnswer?.sentence}
               </Card.Text>}
            <Loader />
            {!qaAnswer?.sentence && !isLoading?.status
                  && <div className="summaryCardAEmptyBox">
                  <Image src={VoicePlaceholder} alt="Icon" />
                     </div> }
          </Card.Body>
        </Card>
      </Col>
      <Col xs="12">
        <p className="continuingTerms">
          <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
            By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
          </Trans>
        </p>
      </Col>
    </Row>
  );
};
