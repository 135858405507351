import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse,
} from 'axios';
import { getLocalItem } from '../Utils/LocalStorage';
import { NUMBER } from '../Constants/Index';
import { AUTH_TOKEN } from '../Constants/Static';

enum StatusCode {
  Unauthorized = NUMBER.FOUR_HUNDREND_ONE,
  Forbidden = NUMBER.FOUR_HUNDREND_THREE,
  TooManyRequests = NUMBER.FOUR_HUNDREND_TWENTY_NINE,
  InternalServerError = NUMBER.FIFVE_HUNDREND,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Credentials': true,
  'X-Requested-With': 'XMLHttpRequest',
};

const endPointUrl: string = process.env.REACT_APP_END_POINT as string;
// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = getLocalItem(AUTH_TOKEN);

    if (token != null) {
      const header = config.headers as AxiosRequestHeaders;
      header.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};
// Handle global app errors
// We can handle generic app errors depending on the status code
const handleError = (error: { status: string | number; data: {}}) => {
  const { status } = error;

  switch (status) {
    case StatusCode.InternalServerError: {
      // eslint-disable-next-line no-console
      console.log(error);
      break;
    }
    case StatusCode.Forbidden: {
      // Handle Forbidden
      break;
    }
    case StatusCode.Unauthorized: {
      // Handle Unauthorized
      break;
    }
    case StatusCode.TooManyRequests: {
      // Handle TooManyRequests
      break;
    }
    default: {
      break;
    }
  }

  return Promise.reject(error?.data);
};

class Http {
  private credential = false;
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const https = axios.create({
      baseURL: endPointUrl,
      headers,
      withCredentials: this.credential,
    });

    https.interceptors.request.use(injectToken, (error: Error) => Promise.reject(error));

    https.interceptors.response.use(
      (response) => {
        const status = response.status;
        const data = response.data;
        return { status, ...data };
      },
      (error) => {
        const { response } = error;
        return handleError(response);
      },
    );

    this.instance = https;
    return https;
  }

  request<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(endPointUrl + url, config);
  }

  post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(endPointUrl + url, data, config);
  }

  put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(endPointUrl + url, data, config);
  }

  patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.patch<T, R>(endPointUrl + url, data, config);
  }

  delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(endPointUrl + url, config);
  }

  wcrequest<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    this.credential = true;
    return this.http.request(config);
  }

  wcget<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    this.credential = true;
    return this.http.get<T, R>(endPointUrl + url, config);
  }

  wcpost<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    this.credential = true;
    return this.http.post<T, R>(endPointUrl + url, data, config);
  }

  wcput<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    this.credential = true;
    return this.http.put<T, R>(endPointUrl + url, data, config);
  }

  wcdelete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    this.credential = true;
    return this.http.delete<T, R>(endPointUrl + url, config);
  }
}

export const http = new Http();
