/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable no-bitwise */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import audioBufferToWav from 'audiobuffer-to-wav';
import { NUMBER } from '../Constants/Number';

export const AudioBufferSlice = async (buffer: AudioBuffer, chunksArr: any, begin: number, end: number, callback: { (slicedAudioBuffer: AudioBuffer | null): void; (arg1: any): void; }) => {
  let error = null;
  const duration = buffer.duration;
  const channels = 1;
  const rate = NUMBER.AUDIO_SAMPLE_RATE;

  if (typeof end === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback = end;
    end = duration;
  }

  begin /= 1000;
  end /= 1000;

  const startOffset = rate * begin;
  const endOffset = rate * end;
  const frameCount = endOffset - startOffset;
  let newArrayBuffer:AudioBuffer | undefined;
  const audioContext = new (window.AudioContext)();
  try {
    newArrayBuffer = audioContext.createBuffer(channels, endOffset - startOffset, rate);
    const anotherArray = new Float32Array(frameCount);
    const offset = 0;

    for (let channel = 0; channel < channels; channel++) {
      buffer.copyFromChannel(anotherArray, channel, startOffset);
      newArrayBuffer.copyToChannel(anotherArray, channel, offset);
    }
    const wav = audioBufferToWav(newArrayBuffer);
    const convertedData = new Uint8Array(wav);
    const chunksFile = new File([convertedData], 'abc.wav', { type: 'audio/wav' });
    chunksArr.push(chunksFile);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error = e;
  }
  return chunksArr;
};
