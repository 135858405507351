import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SIgnInForm } from '../../Components/SignIn/SignInForm';
import { ForgetPassword } from '../../Components/SignIn/ForgetPassword';
import './SignIn.scss';
import { OtpVerification } from '../../Components/SignIn/OptVerification';
import { ResetPasswordModal } from '../../Components/SignIn/ResetPasswordModal';
import { SuccessModal } from '../../Components/Modal/SuccessModal';
import reviewImage from '../../assets/images/Frame.svg';

export const SignIn: React.FC<{isOpen:boolean, setIsRoute?:React.Dispatch<React.SetStateAction<boolean>>; close:React.Dispatch<React.SetStateAction<boolean>>, onSignUpClick: () => void;}> = ({
  isOpen, close, setIsRoute, onSignUpClick,
}): React.ReactElement => {
  const [show, setShow] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [resetOpen, setResetOpen] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [successReview, setSuccesReview] = React.useState(false);
  const [otp, setOpt] = React.useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen !== show) setShow(isOpen);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const successButtonClick = () => {
    setShowSuccess(false);
    close(true);
    setShow(true);
    setEmail('');
    setShowForgetPassword(false);
  };

  const successButtonClicked = (isRedirect?: boolean) => {
    setResetOpen(false);
    if (isRedirect) {
      setSuccess(false);
      navigate('/');
    } else {
      setSuccess(false);
      close(true);
    }
  };
  const successButtonClickNew = () => {
    setSuccesReview(false);
  };

  const sussceeObject = {
    heading: t('ResetPassword_Success_Screen.Title'),
    description: t('ResetPassword_Success_Screen.Desc'),
    button_text: t('ResetPassword_Success_Screen.Button_Submit'),
    redirect_text: t('ResetPassword_Success_Screen.Button_Go_To_Home'),
  };
  const sussceeReviewObject = {
    heading: t('PendingAccountModal.Title'),
    description: t('PendingAccountModal.Desc'),
    button_text: t('PendingAccountModal.Button_Explore'),
    redirect_text: '',
  };

  const [email, setEmail] = React.useState('');
  const handleClose = () => {
    setIsRoute && setIsRoute(true);
    close(false);
  };
  return (
  <>
  <Modal
    className="SignInModal"
    show={show}
    backdrop="static"
    onHide={() => handleClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{ t('SignIn_Title') }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{ t('SignIn_Message') }</p>
      <SIgnInForm setShow={setShow} close={close} setShowForgetPassword={setShowForgetPassword} onSignUpClick={onSignUpClick} setSuccesReview={setSuccesReview} setIsRoute={setIsRoute} />
    </Modal.Body>
  </Modal>
  <ForgetPassword showForgetPassword={showForgetPassword} email={email} setEmail={setEmail} Forgetclose={setShowForgetPassword} Successclose={setShowSuccess} buttonAction={successButtonClick} />
   {email && (<OtpVerification showOptVerifaction={showSuccess} OtpClose={setShowSuccess} setOpt={setOpt} otp={otp} resetOpen={setResetOpen} email={email} setEmail={setEmail} />)}
  <ResetPasswordModal resetOpen={resetOpen} setResetOpen={setResetOpen} setSuccess={setSuccess} emailData={email} otp={otp} setEmail={setEmail} setOpt={setOpt} />
  <SuccessModal
    showSuccess={successReview ? successReview : isSuccess}
    Successclose={successReview ? setSuccesReview : setSuccess}
    messageContent={successReview ? sussceeReviewObject : sussceeObject}
    buttonAction={successReview ? successButtonClickNew : successButtonClicked}
    imagefile={successReview ? reviewImage : ''}
    />
  </>
  );
};
