import { VideoSearchExampleReducerType, searchVideoContentReducerType } from '../actionType/VideoSearch';
import { VIDEOSEARCH } from '../actions/actionType';
import { VideoSearchExampleType, VideoSearchContentType } from '../../Type/VideoSearch';

type State = {
    payload: string[] | VideoSearchExampleType | VideoSearchContentType;
    error: string | null;
  };
const initialState = {
  payload: [],
  error: null,
};

export const VideoExampleReducer = (state: State = initialState, action: VideoSearchExampleReducerType = {} as VideoSearchExampleReducerType):State => {
  if (action?.type === VIDEOSEARCH.EXAMPLE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const SearchVideoContentReducer = (state: State = initialState, action: searchVideoContentReducerType = {} as searchVideoContentReducerType):State => {
  if (action?.type === VIDEOSEARCH.SEARCH_RESULT) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
