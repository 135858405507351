import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import './videoContentSearch.scss';
import '../../assets/scss/loader.scss';
import { useTranslation } from 'react-i18next';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import { VideoContent } from '../../Components/VideoContentSearch/VideoContent';
import { Heading } from '../../Components/Heading/Heading';
import { HEADING_CONTENT_TYPE } from '../../Constants/Heading';
import { scrollUp } from '../../Utils/ScrollTo';

export const VideoContentSearch: React.FC = (): React.ReactElement => {
  /* useEffect to handle Scroll to top when landing on page */
  React.useEffect(() => {
    scrollUp();
  }, []);
  const { t } = useTranslation();
  return (
        <>
        <Container className="AutoSummarizationContainer VideoContentContainer">
          <BreadcrumbBar name={t('Breadcrumb.VideoContentSearchName')} url={pageUrl.home} />
          <Heading {...HEADING_CONTENT_TYPE.video_content_search} />
          <VideoContent />
          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.video_content_search} type="videoContentSearch" />
        </Container>
        <CompanyLogoBanner />
        </>
  );
};
