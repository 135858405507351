import React, { useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import lockIcon from '../../assets/images/grey-password-icon.svg';
import outlineDelteIcon from '../../assets/images/outline-delete-icon.svg';
import arrowNext from '../../assets/images/arrowNext.svg';
import { ChangePassword } from './ChangePassword';
import { DeleteAccount } from './DeleteAccount';
import { EditProfile } from './EditProfile';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { viewProfileApp } from '../../Redux/actions/Profile';
import { viewProfileStatusType } from '../../Type/Profile';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import alertCloseIcon from '../../assets/images/alert-close-icon.svg';
import iconBlueTick from '../../assets/images/icon-blue-tick.svg';
import { scrollUp } from '../../Utils/ScrollTo';
import { CHARLIMIT, NUMBER } from '../../Constants/Number';
import { PROFILE } from '../../Redux/actions/actionType';

export const ShowProfile: React.FC = (): React.ReactElement => {
  const [showChnagePassword, setChangeForgetPassword] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  const [editProfile, setEditProfile] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState('');
  const [toaster, setToaster] = React.useState(false);
  const [profileToaster, setProfileToaster] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    scrollUp();
  }, []);

  const profileData: viewProfileStatusType = useTypedSelector(
    (state) => state.profileData?.payload,
  );
  const changePassword: viewProfileStatusType = useTypedSelector(
    (state) => state.changePassword?.payload,
  );
  useEffect(() => {
    if (!profileData?.data) dispatch(viewProfileApp());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImageFromName = () => {
    const nameImage = profileData?.data?.name.split(' ')?.slice(CHARLIMIT.ZERO, CHARLIMIT.THREE)?.map((name) => name[0]).join('')
      ?.toUpperCase();
    setProfileImage(nameImage);
  };

  useEffect(() => {
    getImageFromName();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.data?.name]);

  useEffect(() => {
    if (changePassword?.status) {
      setToaster(true);
      dispatch({
        type: PROFILE.CHANGE_PASSWORD,
        payload: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassword?.status]);

  useEffect(() => {
    if (toaster) setTimeout(() => setToaster(false), NUMBER.TIMER);
  }, [toaster]);

  useEffect(() => {
    if (profileToaster) setTimeout(() => setProfileToaster(false), NUMBER.TIMER);
  }, [profileToaster]);

  const handleToaster = () => {
    setToaster(false);
    setProfileToaster(false);
  };

  return (
    <>
      <div className="profileInfoDetails">
        <div className="profileNameInfo">
          <div className="nameInitial">{profileImage}</div>
          <div className="userNameEmail">
            <span className="userName">{profileData?.data?.name}</span>
            <span className="userEmailId">{profileData?.data?.email}</span>
          </div>
          <div className="actionBtn">
            <Button className="EditProfileBtn cursor" onClick={() => setEditProfile(true)}>{t('Profile.EditProfile')}</Button>
          </div>
        </div>
        <div className="profileOtherInfo">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="otherInfoData CompanyName">
                <span className="InfoDataLabel">{t('Profile.CompanyName')}</span>
                <span className="InfoDataInput">{profileData?.data?.company}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="otherInfoData IndustryData">
                <span className="InfoDataLabel">{t('Profile.Industry')}</span>
                <span className="InfoDataInput">{profileData?.data?.meta?.industry}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="otherInfoData buildingData">
                <span className="InfoDataLabel">{t('Profile.What_Building')}</span>
                <span className="InfoDataInput">{profileData?.data?.meta?.purpose}</span>
              </div>
            </div>
          </div>
        </div>
          { (toaster || profileToaster) && <div className="toastMsg show">
            <div className="toastWrap">
              <span className="statusIcon">
                <Image src={iconBlueTick} alt="Icon" />
              </span>
              <span className="alertContent">
                <strong className="alert-title">{!profileToaster ? t('Profile.PasswordChanged') : t('Profile.Profile_Updated')}</strong>
                <span className="alert-txt">{!profileToaster ? t('Profile.Password_Successfully_Changed') : t('Profile.Profile_Updated_Text')}</span>
              </span>
              <span className="alertClose" onClick={() => handleToaster()}>
                <Image src={alertCloseIcon} alt="Icon" />
              </span>
            </div>
                                           </div>}
      </div>
      <div className="profileBox changePassword" onClick={() => setChangeForgetPassword(true)}>
        <span className="boxIcon">
          <Image src={lockIcon} alt="Icon" />
        </span>
        <span className="boxHeading">{t('Profile.ChangePassword')}</span>
        <span className="actionIcon">
          <Image src={arrowNext} alt="Icon" />
        </span>
      </div>
      <div className="profileBox deleteAccount" onClick={() => setDeleteAccount(true)}>
        <span className="boxIcon">
          <Image src={outlineDelteIcon} alt="Icon" />
        </span>
        <span className="boxHeading">{t('Profile.DeleteAccount')}</span>
        <span className="actionIcon">
          <Image src={arrowNext} alt="Icon" />
        </span>
      </div>
      <ChangePassword showChnagePassword={showChnagePassword} Forgetclose={setChangeForgetPassword} />
      <DeleteAccount deleteAccount={deleteAccount} Deleteclose={setDeleteAccount} />
      <EditProfile editProfile={editProfile} Editclose={setEditProfile} setToaster={setProfileToaster} />
    </>
  );
};
