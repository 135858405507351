import React, { useCallback, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Image,
  Dropdown,
  DropdownButton,
  Button,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { Heading } from '../Heading/Heading';
import { HEADING_CONTENT_TYPE } from '../../Constants/Heading';
import VoicePlaceholder from '../../assets/images/voice-placeholder.svg';
import IcPause from '../../assets/images/ic-pause.svg';
import IcSpeaker from '../../assets/images/ic-speaker.svg';
import IcPlay from '../../assets/images/Icon--Play.svg';
import { AudioBar } from '../Audio/AudioBar';
import { addBodyClass } from '../../Utils/BodyClass';
import { ttsExampleAction, ttsGenerateAction } from '../../Redux/actions/textToSpeech';
import { TtsExample, TtsInput, TtsResult } from '../../Type/Tts';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { Loader } from '../Loader/Loader';
import { ConfirmationChildType } from '../../Type/Modal';
import { CustomScrollBar } from '../CustomScrollBar/CustomeScrollBar';
import { TTS } from '../../Redux/actions/actionType';

export const TextToSpeechInput: React.FC<ConfirmationChildType> = ({ setConfrimationModal, confirm }): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const scrollRefs = useRef<null | HTMLDivElement>(null);
  const tabIndex = 0;
  const tabIndexNone = -1;
  const [speaker, setSpeaker] = React.useState('');
  const [exampleSummary, setExampleSummary] = React.useState('');
  const [cleared, setCleared] = React.useState(false);
  const [isPausePlay, setPausePlay] = React.useState<null | boolean>(null);
  const [isMute, setMute] = React.useState<null | boolean>(null);
  const ttsExamples: TtsExample = useTypedSelector((state) => state.ttsExample?.payload);
  const audioFile: TtsResult = useTypedSelector((state) => state.ttsResult?.payload);
  const isLoading = useTypedSelector((state) => state.loader);
  const fnEndAudio = () => {
    setPausePlay(null);
  };
  const fnTtsExample = useCallback(
    () => {
      dispatch(ttsExampleAction());
    },
    [dispatch],
  );
  const fnPostTts = (_text: string, _speaker: string) => {
    fnEndAudio();
    scrollRefs?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    const postSummary: TtsInput = {
      text: _text,
      speaker: _speaker,
    };
    setCleared(true);
    dispatch(ttsGenerateAction(postSummary));
    setMute(null);
  };
  useEffect(() => {
    fnTtsExample();
  }, [fnTtsExample, dispatch]);
  useEffect(() => {
    if (confirm) {
      setSpeaker('');
      setExampleSummary('');
      setConfrimationModal(false);
      setCleared(false);
      dispatch({
        type: TTS.GENERATE,
        payload: [],
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, setConfrimationModal]);
  const setSpeaker1 = (value: string) => {
    setSpeaker(value);
    showHide();
  };
  const showHide = () => {
    const element = document.getElementsByClassName('show dropdown-menu-end');
    element[0].classList.remove('show');
    addBodyClass(false);
  };
  const removeExtraSpace = (s: string) => s.replace(/^\s+/g, '');

  return (
        <><Heading korean {...HEADING_CONTENT_TYPE.text_to_speech} /><Row className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6">
                <Card className="cardText">
                    <Card.Header>
                        <span className="title-card">{t('Techcard_TexttoSpeech_DetailView_Input_Filed_Label')}</span>
                        <span className="title-action-btn">
                            <DropdownButton
                              align="end"
                              title={t('Techcard_TexttoSpeech_DetailView_Dropdown_TryExample_Label')}
                              id="dropdown-menu-align-end"
                              className="DropdownWithOverLay"
                              onToggle={(value) => { addBodyClass(value); }}
                            >
                                <Dropdown.Header>{t('Techcard_TexttoSpeech_DetailView_Dropdown_Example_Label')}</Dropdown.Header>
                                <CustomScrollBar>
                                <div className="dropdwon-scroll">
                                  { ttsExamples?.map((ttsExample) => <Dropdown.Item eventKey="1" className={exampleSummary === ttsExample.text ? 'active' : ''} onClick={() => { setExampleSummary(ttsExample.text); setSpeaker(ttsExample.speaker); }} key={ttsExample.text}>
                                            <span className="d-block">{ttsExample.text}</span>
                                                                     </Dropdown.Item>)}
                                </div>
                                </CustomScrollBar>
                            </DropdownButton>
                            <div className="DropdownBackdrop" />
                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasictext">
                                <Form.Control as="textarea" placeholder={t('Techcard_TexttoSpeech_DetailView_Input_Field_Placeholder')} value={exampleSummary} onChange={(e) => setExampleSummary(removeExtraSpace(e?.target?.value))} autoFocus />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <div className="action-left">
                            <span className="title-action-btn">
                                <DropdownButton
                                  align="end"
                                  title={speaker ? speaker : t('Techcard_TexttoSpeech_DetailView_Dropdown_Label_Speaker')}
                                  id="dropdown-menu-align-top"
                                  className="DropdownWithOverLay SpeakerBtn SpeakerBtnCustom customizeWidthHeight"
                                  onToggle={(value) => { addBodyClass(value); }}
                                >
                                    <div className="dropdwon-scroll speaker-dropdown">
                                   { ttsExamples?.map((ttsExample) => <label tabIndex={tabIndex} key={ttsExample.speaker} onClick={() => setSpeaker1(ttsExample?.speaker)} onKeyDown={(event) => event.key === 'Enter' && setSpeaker(ttsExample?.speaker)} className="checkbox-container" htmlFor={ttsExample?.speaker}>
                                            <input
                                              onChange={() => setSpeaker(ttsExample?.speaker)}
                                              id={ttsExample?.speaker}
                                              className="radio-custom"
                                              name="radio-group"
                                              type="radio"
                                              checked={speaker === ttsExample?.speaker}
                                              tabIndex={tabIndexNone} />
                                            <span className="checkbox-txt">{ttsExample?.speaker}</span>
                                            <span className="checkmark" />
                                                                      </label>)}
                                    </div>
                                </DropdownButton>
                                <div className="DropdownBackdrop" />
                            </span>
                        </div>
                        <div className="action-right">
                        <Button onClick={() => setConfrimationModal(true)} className={(exampleSummary && speaker && !isLoading?.status) || audioFile?.audio ? 'clearBtn cursor ' : 'clearBtn cursor disabled-btn '}>{t('Techcard_TexttoSpeech_DetailView_Button_Text_Clear')}</Button>
                            <Card.Link className={(exampleSummary && speaker && !isLoading?.status) ? 'submitBtn cursor ' : 'submitBtn cursor disabled-btn '} onClick={() => { exampleSummary && speaker && fnPostTts(exampleSummary, speaker); }}>{t('Techcard_TexttoSpeech_DetailView_Button_Text_Submit')}</Card.Link>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
            <Col className="mb-30 AutoSummarizationCard" xs="12" sm="6" lg="6" ref={scrollRefs}>
                <Card className="cardSummary">
                    <Card.Header>
                        <span className="title-card">{t('Techcard_TexttoSpeech_DetailView_Output_Filed_Label')}</span>
                    </Card.Header>
                    <Card.Body>
                  {!audioFile?.audio && cleared && !isLoading?.status
                  && <div className="summaryCardAEmptyBox">
                  <Image src={VoicePlaceholder} alt="Icon" width="65" height="65" />
                     </div> }
                    <Loader />
                    {audioFile?.audio && cleared && !isLoading?.status && <AudioBar
                      isPausePlay={isPausePlay}
                      isMute={isMute}
                      audioFile={audioFile?.audio}
                      end={fnEndAudio}
                      mute={setMute}
                        />}
                    </Card.Body>
                    {audioFile?.audio && cleared && !isLoading?.status && <div className="cardFooter voiceControls">
                        <span className="ic-pause" onClick={() => setPausePlay(!isPausePlay)}>
                            <Image src={isPausePlay ? IcPause : IcPlay} alt="Icon" />
                        </span>
                        <span className="ic-speaker" onClick={() => setMute(!isMute)}>
                            <Image src={IcSpeaker} alt="Icon" />
                        </span>
                                                                          </div>}
                </Card>
            </Col>
            <Col xs="12">
              <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
              </p>
            </Col>
                                                                     </Row>
        </>
  );
};
