import { SignInType } from '../Type/Session';

export const setLocal = (key: string, object: SignInType) => {
  localStorage.setItem(key, JSON.stringify(object));
};
export const getLocal = (key: string) => JSON.parse(`${typeof window !== 'undefined' && localStorage.getItem(key)}`);
export const clearLocal = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalItems = () => {
  localStorage.clear();
};

export const setToken = (key: string, value: string) => localStorage.setItem(key, value);

export const getLocalItem = (key: string) => localStorage.getItem(key);

export const cookieValue = (key: string) => document.cookie.split('; ').find((row) => row.startsWith(key))?.split('=')[1];
