import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { SignUpForm } from '../../Components/SignUp/SignUpForm';
import './SignUp.scss';
import { SuccessModal } from '../../Components/Modal/SuccessModal';

export const SignUp: React.FC<{isOpen:boolean, close:React.Dispatch<React.SetStateAction<boolean>>}> = ({ isOpen, close }): React.ReactElement => {
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const sussceeObject = {
    heading: t('Message.Profile_Submitted'),
    description: t('Message.Profile_Submitted_Details'),
    button_text: t('Message.Explore_Actionpower'),
    redirect_text: '',
  };

  const successButtonClick = () => {
    setShowSuccess(false);
  };

  return (
  <>
  <Modal
    className="SignInModal SignUpModal"
    show={show}
    backdrop="static"
    onHide={() => { close(false); }}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{ t('SignUp_Title') }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
          <span className="d-block">{ t('SignUp_Desc1') }</span>
          <span className="d-block">{ t('SignUp_Desc2') }</span>
      </p>
      <SignUpForm close={close} Successclose={setShowSuccess} />
    </Modal.Body>
  </Modal>
  <SuccessModal showSuccess={showSuccess} Successclose={setShowSuccess} messageContent={sussceeObject} buttonAction={successButtonClick} />
  </>
  );
};
