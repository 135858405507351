import React, { useState } from 'react';
import {
  Container,
  Col,
  Card,
} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../Components/Modal/ConfirmationModal';
import './voiceAuthentication.scss';
import '../../assets/scss/loader.scss';
import { CompanyLogoBanner } from '../../Components/ComapanyLogoBanner/CompanyLogoBanner';
import { OverviewBanner } from '../../Components/OverviewBanner/OverviewBanner';
import { OVERVIEW_BANNER_CONTENT_TYPE, pageUrl } from '../../Constants/Index';
import { BreadcrumbBar } from '../../Components/BreadcrumbBar/BreadcrumbBar';
import '../../assets/scss/smallLoader.scss';
import { RegisterVoice } from '../../Components/VoiceAuthentication/RegisterVoice';
import { scrollUp } from '../../Utils/ScrollTo';

export const VoiceAuthentication: React.FC = (): React.ReactElement => {
  const [ShowConfirmationModal, setConfrimationModal] = useState(false);
  const { t } = useTranslation();
  React.useEffect(() => {
    scrollUp();
  }, []);
  return (
        <>
        <Container className="AutoSummarizationContainer VoiceAuthenticationContainer">
          <BreadcrumbBar name={t('Breadcrumb.VoiceAuthenticationName')} url={pageUrl.home} />
          <h1>
            <span>{t('Techcard_VoiceAuthentication_Title')}</span>
            <span className="btnDemo">{t('Techcard_VoiceAuthentication_ButtonText')}</span>
          </h1>
          <p className="heading-txt">
            {t('Techcard_VoiceAuthentication_DetailView_Desc')}
            <br />
            {t('Techcard_VoiceAuthentication_DetailView_Desc1')}
          </p>
          <div className="AutoSummarizationRow">
            <Col className="mb-30 AutoSummarizationCard VoiceAuthenticationCard" xs="12" sm="12" lg="12">
              <Card className="VoiceAuthentication">
                <Card.Body>
                <RegisterVoice />
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12">
            <p className="continuingTerms">
                <Trans i18nKey="Techcard_DetailView_Middle_ServiceTerms" count={5}>
                  By continuing, you agree to our <a href={t('Service_Terms')} target="_blank" rel="noreferrer">Service Terms.</a>
                </Trans>
            </p>
            </Col>
          </div>

          <OverviewBanner overviewBannerProp={OVERVIEW_BANNER_CONTENT_TYPE.voice_authentication} type="voiceAuthentication" />
        </Container>
        <CompanyLogoBanner />

    <ConfirmationModal
      close={setConfrimationModal}
      show={ShowConfirmationModal}
      confirm={setConfrimationModal}
    />
        </>
  );
};
