import { TextQaExampleReducerType, TextQaPostReducerType } from '../actionType/TextQA';
import { TextQaAnswerType, TextQaExampleType } from '../../Type/TextQa.d';
import { Err } from '../../Type/Error';
import { TEXTQA } from '../actions/actionType';

type State = {
    payload: string[] | TextQaExampleType[] | TextQaAnswerType | Err;
    error: string | null;
  };
const initialState = {
  payload: [],
  error: null,
};
export const textQaExampleReducer = (state: State = initialState, action: TextQaExampleReducerType = {} as TextQaExampleReducerType):State => {
  if (action?.type === TEXTQA.EXAMPLE) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};

export const textQaPostReducer = (state: State = initialState, action: TextQaPostReducerType = {} as TextQaPostReducerType):State => {
  if (action?.type === TEXTQA.ANSWER) {
    return {
      payload: action?.payload,
      error: '',
    };
  }
  return { ...state };
};
