export const OVERVIEW_BANNER_CONTENT_TYPE = {
  auto_summary: [
    {
      title: 'Techcard_Autosummary_DetailView_Footer_Title1',
      discription: 'Techcard_Autosummary_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_Autosummary_DetailView_Footer_Title2',
      discription: 'Techcard_Autosummary_DetailView_Footer_Desc2',
    },
  ],
  text_to_speech: [
    {
      title: 'Techcard_TexttoSpeech_DetailView_Footer_Title1',
      discription: 'Techcard_TexttoSpeech_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_TexttoSpeech_DetailView_Footer_Title2',
      discription: 'Techcard_TexttoSpeech_DetailView_Footer_Desc2',
    },
  ],
  voice_authentication: [
    {
      title: 'Techcard_VoiceAuthentication_DetailView_Footer_Title1',
      discription: 'Techcard_VoiceAuthentication_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_VoiceAuthentication_DetailView_Footer_Title2',
      discription: 'Techcard_VoiceAuthentication_DetailView_Footer_Desc2',
    },
  ],
  sound_event_detection: [
    {
      title: 'Techcard_SoundEventDetection_DetailView_Footer_Title1',
      discription: 'Techcard_SoundEventDetection_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_SoundEventDetection_DetailView_Footer_Title2',
      discription: 'Techcard_SoundEventDetection_DetailView_Footer_Desc2',
    },
  ],
  text_QA: [
    {
      title: 'Techcard_Text_QnA_DetailView_Footer_Title1',
      discription: 'Techcard_Text_QnA_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_Text_QnA_DetailView_Footer_Title2',
      discription: 'Techcard_Text_QnA_DetailView_Footer_Desc2',
    },
  ],
  video_content_search: [
    {
      title: 'Techcard_VideoContentsSearch_DetailView_Footer_Title1',
      discription: 'Techcard_VideoContentsSearch_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_VideoContentsSearch_DetailView_Footer_Title2',
      discription: 'Techcard_VideoContentsSearch_DetailView_Footer_Desc2',
    },
  ],
  realtime_speech_to_text: [
    {
      title: 'Techcard_RealtimeSpeechtoText_DetailView_Title',
      discription: 'Techcard_RealtimeSpeechtoText_Footer_Desc1',
    },
    {
      title: 'Techcard_RealtimeSpeechtoText_Footer_Title2',
      discription: 'Techcard_RealtimeSpeechtoText_Footer_Desc2',
    },
  ],
  speech_to_chat: [
    {
      title: 'Techcard_SpeechtoChat_DetailView_Footer_Title1',
      discription: 'Techcard_SpeechtoChat_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_SpeechtoChat_DetailView_Footer_Title2',
      discription: 'Techcard_SpeechtoChat_DetailView_Footer_Desc2',
    },
  ],
  real_time_meeting: [
    {
      title: 'Techcard_RealtimeMeetingMinutes_DetailView_Footer_Title1',
      discription: 'Techcard_RealtimeMeetingMinutes_DetailView_Footer_Desc1',
    },
    {
      title: 'Techcard_RealtimeMeetingMinutes_DetailView_Footer_Title2',
      discription: 'Techcard_RealtimeMeetingMinutes_DetailView_Footer_Desc2',
    },
  ],
};
