import React from 'react';
import {
  Image,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
import icFailedRed from '../../assets/images/ic-failed-red.svg';
import icGreenCheck from '../../assets/images/ic-green-check.svg';
import fileIcon from '../../assets/images/file-icon.svg';
import { AuthAudioType } from '../../Type/VoiceAuthenticate';
import { CHARLIMIT, NUMBER } from '../../Constants/Number';

export const AuthResult: React.FC<{
  authResultArray: AuthAudioType[]; now: number;
}> = ({
  authResultArray, now,
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
      <div className="uploaded-file-container">
        {authResultArray && authResultArray?.map((authData) => (
          <div className={`uploaded-file-row ${authData?.state === CHARLIMIT.TWO && !authData?.verification ? 'statusFailed' : ''} ${authData?.verification ? 'statusPassed' : ''}`}>
            <div className="uploaded-file-content">
              <span className="fileIcon"><Image src={fileIcon} alt="Icon" /></span>
              <span className="fileName">{authData?.record}</span>
            </div>
            {authData?.state === CHARLIMIT.TWO
              && <div className="uploaded-file--status">
                  <div className="badge-status">
                    <span className="status-icon"><Image src={`${authData?.verification ? icGreenCheck : icFailedRed}`} alt="Icon" /></span>
                    <span className="status-txt">{`${authData?.verification ? t('Techcard_VoiceAuthentication_DetailView_Passed') : t('Techcard_VoiceAuthentication_DetailView_Failed')}`}</span>
                  </div>
                 </div>
            }
            {authData?.state === CHARLIMIT.ONE && <ProgressBar now={authData?.state === CHARLIMIT.TWO ? NUMBER.HUNDRED : now} label={`${authData?.state === CHARLIMIT.TWO ? '100' : now}%`} visuallyHidden />}
          </div>
        ))}
      </div>
  );
};
