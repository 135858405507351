import React from 'react';
import { Card, Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon1 from '../../assets/images/ic-auto-summarization.svg';
import Icon2 from '../../assets/images/ic-text-to-speech.svg';
import Icon3 from '../../assets/images/ic-voice-authentication.svg';
import Icon4 from '../../assets/images/ic-sound-event-detection.svg';
import Icon5 from '../../assets/images/ic-text-qna.svg';
import Icon6 from '../../assets/images/ic-video-contents-search.svg';
import Icon7 from '../../assets/images/ic-realtime-speech.svg';
import Icon8 from '../../assets/images/ic-voice-chat.svg';
import Icon9 from '../../assets/images/ic-realtime-meeting-Minutes.svg';
import RibbenIcon from '../../assets/images/ic-ribben.svg';
import { cookieValue, getLocal, getLocalItem } from '../../Utils/LocalStorage';
import { LOGIN_DATA, AUTH_TOKEN } from '../../Constants/Static';
import { TechCardType } from '../../Type/TechCard';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { PermissionType } from '../../Type/Permission';
import { SignInType } from '../../Type/Session';
import { CHARLIMIT } from '../../Constants/Number';
import { SuccessModal } from '../Modal/SuccessModal';
import reviewImage from '../../assets/images/info-blue-ic.svg';
import { SignIn } from '../../Views/SignIn/SignIn';
import { SignUp } from '../../Views/SignUp/SignUp';

export const TechCard: React.FC<TechCardType> = (techProp): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMember, setMember] = React.useState(false);
  const [isTechCardVisibile, setTechCardVisibility] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [isRounte, setIsRoute] = React.useState(false);
  const [isSignUpOpen, setSignUpOpen] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [urlString, setUrl] = React.useState('');
  const permissions: PermissionType[] = useTypedSelector((state) => state.permissions?.payload);
  const imageCall = (): string => {
    switch (techProp.index) {
      case 1: return Icon1;
      case 2: return Icon2;
      case 3: return Icon3;
      case 4: return Icon4;
      case 5: return Icon5;
      case 6: return Icon6;
      case 7: return Icon7;
      case 8: return Icon8;
      default: return Icon9;
    }
  };
  const goto = (url: string, ind: number) => {
    const isLoggedIn = getLocalItem(AUTH_TOKEN);
    const lang = cookieValue('i18next');
    if ((isMember && isLoggedIn) || !isMember) {
      if ((ind === CHARLIMIT.ONE || ind === CHARLIMIT.TWO || ind === CHARLIMIT.FIVE) && lang !== 'ko') {
        setUrl(url);
        setSuccess(true);
        setShow(false);
      } else {
        navigate(url);
      }
    } else {
      setUrl(url);
      setOpen(true);
      setIsRoute(false);
    }
  };
  const loginDetail: SignInType = useTypedSelector((state) => state.signIn?.payload);
  React.useEffect(() => {
    const userDetails = getLocal(LOGIN_DATA);
    const isLoggedIn = getLocalItem(AUTH_TOKEN);
    const filterValue: PermissionType = permissions.filter((resp) => resp.label === techProp?.label)[0];
    filterValue && (filterValue.permission > 0 ? (setMember(true)) : (setMember(false)));

    if (filterValue && filterValue?.publish === 1) {
      setTechCardVisibility(true);
    } else if (filterValue && filterValue?.publish === 0 && isLoggedIn && filterValue.permission >= userDetails?.permission) {
      setTechCardVisibility(true);
    } else {
      setTechCardVisibility(false);
    }
  }, [permissions, techProp, loginDetail]);

  const sussceeReviewObject = {
    heading: t('onlyCoreanTechCard.Title'),
    description: t('onlyCoreanTechCard.Desc'),
    button_text: t('onlyCoreanTechCard.Button_Explore'),
    redirect_text: '',
  };

  const successButtonClickNew = () => {
    setShow(true);
    setSuccess(false);
  };

  const onSignUpClick = () => {
    setOpen(false);
    setSignUpOpen(true);
    setIsRoute(true);
  };

  React.useEffect(() => {
    if (show) {
      navigate(urlString);
    } else if (!isOpen && urlString && !isRounte) {
      navigate(urlString);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, isOpen]);

  if (isTechCardVisibile) {
    return (
      <><Col className="mb-30" xs="12" sm="6" lg="4">
        <Card onClick={() => goto(techProp.url, techProp.index)}>
          <div className="ImgContainer">
            <Card.Img src={imageCall()} alt="Icon" width="39" height="34" />
          </div>
          <div className="ribben-box">
            {isMember && (
              <>
                <Image src={RibbenIcon} alt="Icon" />
                <div className="ribbenTxt">{t('Techcard_MembersOnly')}</div>
              </>
            )}
          </div>
          <Card.Body>
            <Card.Title>{t(techProp.title)}</Card.Title>
            <Card.Text>{t(techProp.discription)}</Card.Text>
          </Card.Body>
          <div className="action-btn">
            <Button variant="primary" className="demoBtn">{t(techProp.buttonText)}</Button>
          </div>
        </Card>
        </Col>
        <SuccessModal
          showSuccess={isSuccess}
          Successclose={setSuccess}
          messageContent={sussceeReviewObject}
          buttonAction={successButtonClickNew}
          imagefile={reviewImage} />
       <SignIn isOpen={isOpen} close={setOpen} onSignUpClick={onSignUpClick} setIsRoute={setIsRoute} />
        <SignUp isOpen={isSignUpOpen} close={setSignUpOpen} />
      </>
    );
  }

  return <div />;
};
