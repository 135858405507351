//This file contains api response code.
export const NUMBER = {
  FOUR_HUNDREND_ONE: 401,
  FOUR_HUNDREND_THREE: 403,
  FOUR_HUNDREND_TWENTY_NINE: 429,
  FIFVE_HUNDREND: 500,
  THREE_FIFTY_SEVEN: 357,
  TWO_FIFTY: 250,
  TWO_FOURTY_SEVEN: 247,
  THREE_SIX_ZERO_ZERO: 3600,
  VIDEO_SEARCH_LIMIT: 24,
  HUNDRED: 100,
  ONE_THOUSAND: 1000,
  FIVE_THOUSAND: 5000,
  ONE_THOUSAND_TWENTY_FOUR: 1024,
  DURATION_LIMIT: 180,
  TIMER: 2000,
  AUDIO_SAMPLE_RATE: 48000,
};

export const MEDIACONSTANTS = {
  MESSAGE_INTERVAL: 250,
  SAMPLE_RATE: 16000,
  BUFFER_LEN: 2 ** Math.ceil(Math.log2((16000 * 250) / 1000)),
};

export const CHARLIMIT = {
  POINT_TWO_FIVE: 0.25,
  NEGATIVE_ONE: -1,
  ONE: 1,
  ZERO: 0,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  SIXTEN: 16,
  THIRTY: 30,
  FIFTEEN: 15,
  TWENTY_ONE: 21,
  EIGHTEEN: 18,
  SIXTEYFOUR: 64,
  SIXTEY: 60,
  FIFTYNINE: 59,
  TWOFIFTY: 250,
  ONE_THIRTY_SEVEN: 137,
};
