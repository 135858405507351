/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';

export const RecordAudioBar: React.FC<{
    startRecoeding: boolean; waveform: any | never | unknown;
  }> = ({
    startRecoeding, waveform,
  }): React.ReactElement => {
    // const waveform = useRef<any | never | unknown>();
    useEffect(() => {
      if (startRecoeding && !waveform.current) {
        waveform.current = WaveSurfer.create({
          container: '#microphone',
          waveColor: '#25adfc',
          progressColor: 'white',
          interact: false,
          cursorWidth: 0,
          autoCenter: true,
          barWidth: 4,
          barHeight: 1,
          barRadius: 2,
          height: 80,
          barMinHeight: 5,
          barGap: 7,
          plugins: [
            MicrophonePlugin.create({ name: 'microphone' }),
          ],
        });
        const microphone = waveform.current.microphone;
        waveform.current.drawBuffer();
        microphone.start();
      } else if (!startRecoeding && waveform.current) {
        waveform.current.microphone.stop();
        waveform.current.microphone.destroy();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startRecoeding]);

    return (
    <div id="microphone" className="grey-box1" />
    );
  };
