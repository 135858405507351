import { PermissionApi } from '../../Api/index';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { PermissionsActionType } from '../actionType/Permissions';
import { PERMISSIONS } from './actionType';

export const permissionsAction = () => async (dispatch: AppDispatch) => {
  try {
    const resp = await PermissionApi() as PermissionsActionType;
    dispatch({
      type: PERMISSIONS,
      payload: resp?.data,
    });
  } catch (error) {
    dispatch({
      type: PERMISSIONS,
      payload: [],
    });
  }
};
