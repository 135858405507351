/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Image,
} from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useStopwatch } from 'react-timer-hook';
import { RecordAudioBar } from '../Audio/RecordAudioBar';
import icWhiteCheck from '../../assets/images/ic-white-check.svg';
import redoIcon from '../../assets/images/redo-icon.svg';
import { CHARLIMIT } from '../../Constants/Number';

export const AuthRecordTimer: React.FC<{
  startRecoeding: boolean; resetRecording: () => void; setAuthTimer: React.Dispatch<React.SetStateAction<boolean>>; waveform: any | never | unknown;
}> = ({
  startRecoeding, resetRecording, setAuthTimer, waveform,
}): React.ReactElement => {
  const {
    seconds,
    minutes,
    hours,
    start,
    reset,
  } = useStopwatch({ autoStart: true });
  const hourTime = hours < CHARLIMIT.TEN ? `0${hours}` : `${hours}`;
  const secondTime = seconds < CHARLIMIT.TEN ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < CHARLIMIT.TEN ? `0${minutes}` : `${minutes}`;

  const progressBarStart = () => {
    const progressBar = document.getElementsByClassName('progress-bar')[0] as HTMLElement | null | any;
    const intervalId = setInterval(() => {
      const computedStyle = getComputedStyle(progressBar);
      const width = parseFloat(computedStyle.getPropertyValue('--width')) || 0;
      progressBar.style.setProperty('--width', width + 0.1);
    }, CHARLIMIT.SEVEN);
    return (() => {
      clearInterval(intervalId);
    });
  };
  React.useEffect(() => {
    if (startRecoeding) {
      start();
      progressBarStart();
      setAuthTimer(false);
    } else {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRecoeding]);

  React.useEffect(() => {
    if (Number(secondTime) >= CHARLIMIT.SEVEN) {
      setAuthTimer(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondTime]);

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Record again
    </Tooltip>
  );

  return (
    <>
    <div className="recording-audio-row with-redoBtn">
        <div className={`recordVoiceBtn progress-bar ${(Number(secondTime) >= CHARLIMIT.SEVEN || Number(minuteTime) >= CHARLIMIT.ONE || Number(hourTime) >= CHARLIMIT.ONE) ? 'activeState' : ''}`} style={{ width: '--width: 10' }} data-label="">
          {(Number(secondTime) > CHARLIMIT.SIX || Number(minuteTime) >= CHARLIMIT.ONE || Number(hourTime) >= CHARLIMIT.ONE) && <span className="icon"><Image src={icWhiteCheck} alt="Icon" /></span>}
          <span className="icon-txt"><span className="record-timer">{`${hourTime}: ${minuteTime}: ${secondTime}`}</span></span>
          {(Number(secondTime) <= CHARLIMIT.SIX && Number(minuteTime) === CHARLIMIT.ZERO && Number(hourTime) === CHARLIMIT.ZERO) && <ProgressBar className="recordVoiceProgress" now={Number(secondTime) * CHARLIMIT.FIFTEEN} />}
        </div>
        <div className="redoWrap" onClick={() => resetRecording()}>
          <OverlayTrigger
            placement="right"
            delay={{ show: 200, hide: 100 }}
            overlay={renderTooltip}
    >
          <span className="redo--icon"><Image src={redoIcon} alt="Icon" /></span>
          </OverlayTrigger>
        </div>
    </div>
      <div><RecordAudioBar startRecoeding={startRecoeding} waveform={waveform} /></div>
    </>
  );
};
