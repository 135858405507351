import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from '../FormFields/Inputs';
import { profileUpdateStatusType, viewProfileStatusType } from '../../Type/Profile';
import { useTypedSelector } from '../../Hooks/useTypeSelector';
import { CHARLIMIT } from '../../Constants/Number';
import { formValidator } from '../../Utils/FormValidation';
import { AppDispatch } from '../../Hooks/useAppDispatch';
import { editProfileApp, viewProfileApp } from '../../Redux/actions/Profile';
import { getLocal, setLocal } from '../../Utils/LocalStorage';
import { LOGIN_DATA } from '../../Constants/Static';
import { PROFILE } from '../../Redux/actions/actionType';

export const EditProfile: React.FC<{editProfile: boolean; Editclose: React.Dispatch<React.SetStateAction<boolean>>; setToaster: React.Dispatch<React.SetStateAction<boolean>>}> = ({ editProfile, Editclose, setToaster }): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const profileData: viewProfileStatusType = useTypedSelector(
    (state) => state.profileData?.payload,
  );

  const [errors, setErrors] = React.useState(['name', 'company']);
  const [changeError, setChnageError] = React.useState(true);

  const [ProfileFields, setProfileFields] = React.useState({
    company: '', name: '', industry: '', purpose: '',
  });

  const userData = getLocal(LOGIN_DATA);

  const firstCpital = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const removeExtraSpace = (s: string) => s.replace(/^\s+/g, '');

  const handleChange = (name: string, value: string) => {
    setProfileFields({ ...ProfileFields, [name]: value });
    setChnageError(false);
  };

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>, name:string) => {
    const value = event.target.value;
    setProfileFields({ ...ProfileFields, [name]: value });
    setChnageError(false);
  };

  const validatedForm = (key: string, isValid: boolean) => {
    const updatedError = formValidator(key, isValid, errors);
    setErrors([...updatedError]);
  };

  useEffect(() => {
    setProfileFields({
      company: profileData.data?.company,
      name: profileData.data?.name,
      industry: profileData.data?.meta?.industry,
      purpose: profileData.data?.meta?.purpose,
    });
    setErrors([]);
  }, [profileData.data, editProfile]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const Payload = {
      name: ProfileFields.name,
      company: ProfileFields.company,
      meta_industry: ProfileFields.industry,
      meta_purpose: ProfileFields.purpose,
    };
    const profileData = { ...userData, name: ProfileFields.name };
    dispatch(editProfileApp(Payload));
    setLocal(LOGIN_DATA, profileData);
    e?.preventDefault();
  };

  const updateProfile: profileUpdateStatusType = useTypedSelector(
    (state) => state.editProfile?.payload,
  );

  React.useEffect(() => {
    if (updateProfile && updateProfile?.status) {
      Editclose(false);
      setChnageError(true);
      setToaster(true);
      dispatch({
        type: PROFILE.EDIT_PROFILE,
        payload: [],
      });
      dispatch(viewProfileApp());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfile.status]);

  const handleEditClose = () => {
    Editclose(false);
    setChnageError(true);
  };

  return (
  <Modal
    className="SignInModal EditProfileModal"
    show={editProfile}
    backdrop="static"
    onHide={() => handleEditClose()}
    animation={false}
    aria-labelledby="signInModalTitle"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="signInModalTitle">{t('EditProfile.Title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <span className="d-block" />
      </p>
      <Form className="customFormUI" onSubmit={(e) => { handleSubmit(e); }}>
      {updateProfile?.msg
       && <div className="errorMsgBox">
        <span>{updateProfile?.msg}</span>
          </div>
        }
        <Form.Group className="form-group disabled" controlId="formBasicEmail">
          <Input
            label={t('EditProfile.WorkEmailAddress')}
            span="*"
            type="email"
            required
            value={profileData.data?.email}
          />
        </Form.Group>

        <Form.Group className="form-group" controlId="formBasicEmail">
          <Input
            autoFocus
            label={t('EditProfile.FullName')}
            span="*"
            type="text"
            maxLimit="50"
            value={ProfileFields.name}
            onChange={(e: string) => handleChange('name', firstCpital(removeExtraSpace(e)))}
            className={updateProfile?.msg && 'error'}
            required
            tabIndex={CHARLIMIT.ONE}
            updateValidStatus={(isValid: boolean) => validatedForm('name', isValid)}
          />
        </Form.Group>

        <Form.Group className="form-group" controlId="formBasicEmail">
          <Input
            label={t('EditProfile.CompanyName')}
            span="*"
            type="text"
            value={ProfileFields.company}
            maxLimit="50"
            onChange={(e: string) => handleChange('company', firstCpital(removeExtraSpace(e)))}
            className={updateProfile?.msg && 'error'}
            required
            tabIndex={CHARLIMIT.TWO}
            updateValidStatus={(isValid: boolean) => validatedForm('company', isValid)}
          />
        </Form.Group>

        <Form.Group className="form-group withArrow" controlId="formBasicEmail">
        <FloatingLabel controlId="floatingSelect" label={t('EditProfile.Industry')}>
        <Form.Select onChange={(e) => selectChange(e, 'industry')} aria-label="Floating label select example" className={`form-control ${ProfileFields.industry ? 'value-selected' : ''}`} tabIndex={CHARLIMIT.THREE}>
          <option style={{ display: 'none' }}>{ProfileFields.industry === ' ' ? 'Select' : ProfileFields.industry}</option>
          <option value=" ">{t('Industry.Select')}</option>
          <option value="Energy">{t('Industry.Energy')}</option>
          <option value="Materials">{t('Industry.Materials')}</option>
          <option value="Industrials">{t('Industry.Industrials')}</option>
          <option value="Consumer Discretionary">{t('Industry.ConsumerDiscretionary')}</option>
          <option value="Health Care">{t('Industry.HealthCare')}</option>
          <option value="Financials">{t('Industry.Financials')}</option>
          <option value="Information Technology">{t('Industry.InformationTechnology')}</option>
          <option value="Communication Services">{t('Industry.CommunicationServices')}</option>
          <option value="Utilities">{t('Industry.Utilities')}</option>
          <option value="Real Estate">{t('Industry.Real Estate')}</option>
        </Form.Select>
        </FloatingLabel>
        </Form.Group>
        <Form.Group className="form-group withArrow" controlId="formBasicEmail">
      <FloatingLabel controlId="floatingSelect" label={t('EditProfile.What_Building')}>
        <Form.Select onChange={(e) => selectChange(e, 'purpose')} aria-label="Floating label select example" tabIndex={CHARLIMIT.FOUR} className={`form-control ${ProfileFields.purpose ? 'value-selected' : ''}`}>
          <option style={{ display: 'none' }}>{ProfileFields.purpose === ' ' ? 'Select' : ProfileFields.purpose}</option>
          <option value=" ">{t('WhatAreYouBuilding.Select')}</option>
          <option value="I am hacking on a personal project">{t('WhatAreYouBuilding.HackingPersonalProject')}</option>
          <option value="I am exploring different speech-to-text APIs">{t('WhatAreYouBuilding.exploringAPIs')}</option>
          <option value="I am building a feature at my company">{t('WhatAreYouBuilding.BuildingFeature')}</option>
        </Form.Select>
      </FloatingLabel>
        </Form.Group>

        <div className="action-btn">
          <Button variant="primary" disabled={errors.length > 0 || changeError} type="submit" tabIndex={CHARLIMIT.FIVE}>{t('EditProfile.Button_Submit')}</Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal>
  );
};
